import { useQuery } from '@apollo/client';
import { Button } from 'antd';
import { findIndex } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from '../../apollo';
import { STAGE_STATUS } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import ApprovalPopUp from './ApprovalPopUp';
import ApproveModal from './ApproveModal';
import ApproverStageItemDetails from './ApproverStageItemDetails';
import EqcSummary from './EqcSummary';
import { GET_STAGE_HISTORY } from './graphql/Queries';
import StageSummary from './StageSummary';

const ApproverLink = () => {
  const { id, token } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const [commentData, setCommentData] = useState({
    eqcStage: [],
    eqcStageItems: []
  });
  const { data, loading, refetch } = useQuery(GET_STAGE_HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      id: id,
      token: token
    }
  });

  useEffect(() => {
    if (
      data &&
      data?.getEqcStageHistoryWithoutAuth?.status !==
        STAGE_STATUS.APPROVAL_PENDING
    ) {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (loading) {
    return <LoaderComponent />;
  }
  return (
    <>
      {showModal && (
        <ApprovalPopUp
          showModal={showModal}
          setShowModal={setShowModal}
          data={data}
        />
      )}

      {showApproveModal && (
        <ApproveModal
          showModal={showApproveModal}
          setShowModal={setShowApproveModal}
          data={data}
          title={title}
          status={status}
          commentData={commentData}
        />
      )}
      <div className="approval-link">
        <div className="mb-24">
          <EqcSummary data={data} />
        </div>
        <div className="mb-24">
          <StageSummary
            data={data}
            refetch={refetch}
            setCommentData={setCommentData}
            commentData={commentData}
          />
        </div>
        <div>
          <ApproverStageItemDetails
            data={data}
            refetch={refetch}
            setCommentData={setCommentData}
            commentData={commentData}
          />
        </div>
        {data &&
          data?.getEqcStageHistoryWithoutAuth?.status ===
            STAGE_STATUS.APPROVAL_PENDING && (
            <div className="d-flex justify-center">
              <Button
                shape="round"
                onClick={() => {
                  if (
                    findIndex(
                      commentData?.eqcStageItems,
                      (item) =>
                        item.generalRemark && item.generalRemark !== null
                    ) === -1 &&
                    !commentData?.eqcStage?.generalComment
                  ) {
                    return toast({
                      message:
                        'If you want Inspector to REDO, please add at least one comment.',
                      type: 'error'
                    });
                  }
                  setShowApproveModal(true);
                  setTitle('Redo');
                  setStatus(STAGE_STATUS.REDO);
                }}
              >
                Redo
              </Button>
              <Button
                shape="round"
                type="primary"
                onClick={() => {
                  setShowApproveModal(true);
                  setTitle('Approve');
                  setStatus(STAGE_STATUS.APPROVED);
                }}
              >
                Approve
              </Button>
            </div>
          )}
      </div>
    </>
  );
};

export default ApproverLink;
