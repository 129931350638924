import { useLazyQuery } from '@apollo/client';
import { Button } from 'antd';
import clsx from 'clsx';
import * as eva from 'eva-icons';
import { isNaN, nth } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../AppContext';
import { Export, ForwardArrow } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  ROUTES
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import Error404 from '../../../../Error404';
import AddEditEqcModal from '../../AddEditEqcModal';
import { GET_EQC_TYPE } from '../../graphql/Queries';
import EqcDetailContent from './EqcDetailContent';

const EqcDetails = () => {
  const { getToken, getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { eqcTypeId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [eqcData, setEqcData] = useState();
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [
    webEqcTypeList,
    { data: eqcTypeData, refetch }
  ] = useLazyQuery(GET_EQC_TYPE, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (!isNaN(Number(eqcTypeId))) {
      webEqcTypeList({
        variables: {
          filter: {
            eqcTypeId
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = nth(eqcTypeData?.eqcTypeList?.data, 0);
  const renderDownloadButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/report/eqc-types/${eqcTypeId}/export?access_token=Bearer ${token}`;
    return (
      <Button
        shape="round"
        icon={<Export height="26px" />}
        className="export-button d-flex b-0"
        target="_blank"
        href={url}
        onClick={() =>
          Event(GA_EVENT.EXPORT_GLOBAL_EQC_TYPE, {
            label: GA_LABEL.EXPORT_GLOBAL_EQC_TYPE,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            eqc_type_id: eqcTypeId,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName
          })
        }
      >
        {isDesktopViewport && 'Export'}
      </Button>
    );
  };
  if (isNaN(Number(eqcTypeId))) {
    return <Error404 />;
  }
  return (
    <div>
      {showModal && (
        <AddEditEqcModal
          showModal={showModal}
          setShowModal={setShowModal}
          setEqcData={setEqcData}
          eqcData={eqcData}
          isUpdate={!!eqcData}
          refetchUserData={refetch}
        />
      )}
      <div className="details-header d-flex justify-between page-header">
        <h2 className="d-flex align-center">
          <span
            onClick={() => {
              history.push(ROUTES.EQC_TYPES, {
                navFilter,
                navPagination
              });
            }}
          >
            <i data-eva="pantone-outline" className="pointer" />
          </span>
          <ForwardArrow />
          {fetchData?.name}
        </h2>
        <div className="d-flex">
          {renderDownloadButton()}
          <Button
            shape="round"
            type="primary"
            icon={<i data-eva="edit-outline" />}
            className="edit-button-eqc d-flex"
            onClick={() => {
              setEqcData(fetchData);
              setShowModal(true);
            }}
          >
            {isDesktopViewport && 'Edit'}
          </Button>
        </div>
      </div>
      <div className={clsx(isDesktopViewport && 'mt-15')}>
        <EqcDetailContent eqcTypeData={fetchData} />
      </div>
    </div>
  );
};

export default EqcDetails;
