import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_STAGE_HISTORY = gql`
  query getEqcStageHistoryWithoutAuth($id: ID!, $token: String!) {
    getEqcStageHistoryWithoutAuth(id: $id, token: $token) {
      id
      eqcStageId
      name
      status
      eqcStageStartedAt
      eqcStageCompletedAt
      eqc {
        id
        eqcName
        uom
        status
        projectEqcTypeName
        eqcStages {
          eqcStageSynceStartedAt
          eqcStageSynceCompletedAt
        }
      }
      eqcApprovalLogs {
        revision
      }
      action {
        name
      }
      actionAt
      drawingTitleImg
      drawingImg
      witnessesImg
      witnessesId
      latitude
      reviewedBy
      updatedAt
      longitude
      previousHistoryData {
        itemHistory
      }
      itemHistory
    }
  }
`;

export const GET_URLS = gql`
  query getSignedPutUrls($data: [SignedUrlDataInput]) {
    getSignedPutUrls(data: $data) {
      signedUrls
      keys
    }
  }
`;
