import { useLazyQuery } from '@apollo/client';
import { Button, Dropdown, Menu, Space, Tooltip } from 'antd';
import clsx from 'clsx';
import { capitalize, forEach, map, nth, slice } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  AddButton,
  DuplicateIcon,
  EditButton,
  KebabMenu
} from '../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  PROJECT_EQC_TYPE_STATUS,
  QCSTATUS_COLOR,
  ROUTES,
  TAB_KEYS,
  UOMS
} from '../../../../../../common/constants';
import CanPerform from '../../../../../../components/CanPerform';
import CommonCard from '../../../../../../components/CommonCard';
import CommonTable from '../../../../../../components/CommonTable';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../../../../components/SearchComponent';
import { GET_PROJECT_EQC_TYPES_LIST } from '../../../../graphql/Queries';
import AddEqcTypeModal from './AddEqcTypeModal';
import DuplicateEqcType from './DuplicateEqcType';
import EditEqcTypeModal from './EditEqcTypeModal';

const EqcTypes = () => {
  const { projectId } = useParams();
  const initialProjectEqcTypeFilter = {
    skip: 0,
    limit: 10,
    projectId: Number(projectId),
    sortBy: { field: 'createdAt', order: 'DESC' }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const eqcTypeFilter = history?.location?.state?.eqcTypeFilter;
  const eqcTypePagination = history?.location?.state?.eqcTypePagination;
  const [paginationProp, setPaginationProp] = useState(
    eqcTypePagination || initialPaginationValue
  );
  const [projectEqcTypeFilter, setProjectEqcTypeFilter] = useState(
    eqcTypeFilter || initialProjectEqcTypeFilter
  );
  const [showModal, setShowModal] = useState(false);
  const [eqcTypeData, setEqcTypeData] = useState();
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState('');
  const [mutationId, setMutationId] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [eqcTypeListData, setEqcTypeListData] = useState();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [fetchEqcType, { loading }] = useLazyQuery(GET_PROJECT_EQC_TYPES_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.projectEqcTypeList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.projectEqcTypeList?.total
      };
      if (scrollFlag) {
        const datalist = [...eqcTypeListData, ...data];
        setEqcTypeListData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setEqcTypeListData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() {}
  });

  useEffect(() => {
    fetchEqcType({ variables: { filter: projectEqcTypeFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchEqcTypeDetails = () => {
    setPaginationProp(initialPaginationValue);
    setProjectEqcTypeFilter(initialProjectEqcTypeFilter);
    setSortedInfo({});
    fetchEqcType({
      variables: { filter: { ...initialProjectEqcTypeFilter } }
    });
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectEqcTypeFilter({
        ...projectEqcTypeFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchEqcType({
        variables: {
          filter: {
            ...projectEqcTypeFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setProjectEqcTypeFilter({
        ...projectEqcTypeFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });

      fetchEqcType({
        variables: {
          filter: {
            ...projectEqcTypeFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' }
          }
        }
      });
    }
  };

  const handleEditEqcType = () => {
    setShowModal(true);
  };
  const handleAddEqcType = () => {
    setShowAddModal(true);
  };
  const getColor = (record) => {
    if (record?.status === PROJECT_EQC_TYPE_STATUS?.ARCHIVED) {
      return QCSTATUS_COLOR.FAIL;
    }
    if (record?.status === PROJECT_EQC_TYPE_STATUS?.PUBLISHED) {
      return QCSTATUS_COLOR.PASS;
    }
    if (record?.status === PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED) {
      return QCSTATUS_COLOR.SKIP;
    }
    return undefined;
  };
  const getButtonEditable = (record) => {
    if (record?.status === PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED) {
      return false;
    }
    return true;
  };
  const getProjectUsers = (record) => {
    const fetchName = [];
    if (record?.projectUserEqcTypes?.length !== 0) {
      forEach(record?.projectUserEqcTypes, function (o) {
        fetchName.push(o?.projectUser?.user?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }

    return '-';
  };
  const getProjectAgencies = (record) => {
    const fetchName = [];
    if (record?.projectAgencyEqcTypes?.length !== 0) {
      forEach(record?.projectAgencyEqcTypes, function (o) {
        fetchName.push(o?.projectAgency?.agency?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }
    return '-';
  };
  const getStatus = (value, record) => (
    <span
      key={record?.id}
      className="mr-2"
      style={{ paddingLeft: '9px', color: getColor(record) }}
    >
      {capitalize(
        value === PROJECT_EQC_TYPE_STATUS?.ARCHIVED
          ? PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED
          : value
      )}
    </span>
  );
  const getActionButtons = (record) => {
    const handleEdit = (e) => {
      e?.stopPropagation?.();
      setEqcTypeData(record);
      handleEditEqcType();
    };
    const handleDuplicate = (e) => {
      e?.stopPropagation?.();
      setMutationId(record?.id);
      setDuplicateModal(true);
    };
    return (
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC_TYPES}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        <Space size={0}>
          {isDesktopViewport ? (
            <>
              <Tooltip title="Edit">
                <Button
                  shape="round"
                  disabled={getButtonEditable(record)}
                  icon={<EditButton />}
                  onClick={handleEdit}
                />
              </Tooltip>
              <Tooltip title="Duplicate">
                <Button
                  shape="round"
                  icon={<DuplicateIcon />}
                  onClick={handleDuplicate}
                />
              </Tooltip>
            </>
          ) : (
            <Dropdown
              overlay={
                <Menu>
                  {!getButtonEditable(record) && (
                    <>
                      <Menu.Item key="Edit" onClick={handleEdit}>
                        Edit
                      </Menu.Item>
                      <Menu.Divider />
                    </>
                  )}
                  <Menu.Item key="Duplicate" onClick={handleDuplicate}>
                    Duplicate
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <KebabMenu />
            </Dropdown>
          )}
        </Space>
      </CanPerform>
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{projectEqcTypeFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'NAME',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (text) => {
        return text || '-';
      }
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      key: 'uom',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'uom' && sortedInfo?.order,
      render: (text) => {
        return UOMS[text] || '-';
      }
    },
    {
      title: 'REFERENCE NUMBER',
      key: 'iso',
      dataIndex: 'iso',
      render: (text) => {
        return text || '-';
      }
    },
    {
      title: 'USER',
      key: 'user',
      dataIndex: 'projectUserEqcTypes',
      render: (text, record) => getProjectUsers(record)
    },
    {
      title: 'AGENCIES',
      key: 'agency',
      dataIndex: 'projectAgencyEqcTypes',
      render: (text, record) => getProjectAgencies(record)
    },
    {
      title: 'STATUS',
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      render: (text, record) => getStatus(text, record)
    },
    {
      key: 'actions',
      align: 'right',
      render: (record) => getActionButtons(record)
    }
  ];

  const onSearchChange = async (value) => {
    setPaginationProp(initialPaginationValue);
    setProjectEqcTypeFilter({
      ...projectEqcTypeFilter,
      search: value
    });
    fetchEqcType({
      variables: { filter: { ...projectEqcTypeFilter, skip: 0, search: value } }
    });
  };
  const handleRefetch = () => {
    fetchEqcType({
      variables: {
        filter: {
          ...projectEqcTypeFilter,
          skip: eqcTypeListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      history.push(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.EQC_TYPES}/${record?.id}`,
        {
          navFilter,
          navPagination,
          eqcTypeFilter: projectEqcTypeFilter,
          eqcTypePagination: paginationProp
        }
      );
    }
  };
  return (
    <>
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC_TYPES}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        <EditEqcTypeModal
          showModal={showModal}
          setShowModal={setShowModal}
          eqcTypeData={eqcTypeData}
          setEqcTypeData={setEqcTypeData}
          refetchEqcTypeData={() =>
            fetchEqcType({
              variables: { filter: projectEqcTypeFilter }
            })
          }
        />
        <DuplicateEqcType
          showModal={duplicateModal}
          setShowModal={setDuplicateModal}
          mutationId={mutationId}
          setMutationId={setMutationId}
          refetchEqcTypeData={refetchEqcTypeDetails}
        />
        {showAddModal && (
          <AddEqcTypeModal
            showModal={showAddModal}
            setShowModal={setShowAddModal}
            refetchEqcTypeData={refetchEqcTypeDetails}
          />
        )}
      </CanPerform>
      <div className="agency-details">
        <div
          className={`agency-details-header mb-20 ${
            isDesktopViewport ? 'd-flex justify-between align-center ' : ''
          }`}
        >
          <h2>EQC Types</h2>
          <div className="d-flex align-center">
            <SearchComponent
              className={`search-component mr-10 ${
                !isDesktopViewport ? 'width-percent-100' : ''
              }`}
              getData={onSearchChange}
              defaultValue={eqcTypeFilter?.search}
            />
            <CanPerform
              action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC_TYPES}
              type={ALLOWED_ACTION_TYPE.BOTH}
            >
              <Button
                shape="round"
                icon={
                  <AddButton
                    width="25px"
                    className={clsx(isDesktopViewport && 'mr-5')}
                  />
                }
                type="primary"
                className="d-flex align-center"
                onClick={() => {
                  handleAddEqcType();
                }}
              >
                {isDesktopViewport && 'Add'}
              </Button>
            </CanPerform>
          </div>
        </div>
        {isDesktopViewport ? (
          <div className="table pointer">
            <CommonTable
              columns={columns}
              data={eqcTypeListData || []}
              loading={loading}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey={(obj) => obj?.id}
              onRow={(record) => {
                return {
                  onClick: () => handleRowClick(record)
                };
              }}
            />
          </div>
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={eqcTypeListData?.length}
            skeletonRows={columns?.length - 3}
          >
            {map(eqcTypeListData, (eqcType, index) => {
              return (
                <CommonCard
                  key={eqcType?.id}
                  onClick={() => handleRowClick(eqcType)}
                >
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">
                      <div className="d-flex align-center">{index + 1}.</div>
                    </div>
                    <div>
                      <div className="card-header fw-medium">
                        <span className="text-break">{eqcType?.name}</span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">UOM:</span>
                          <span className="text-break">
                            {UOMS[eqcType?.uom]}
                          </span>
                        </div>
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">
                            Reference Number:
                          </span>
                          <span className="text-break">{eqcType?.iso}</span>
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">User:</span>
                          {getProjectUsers(eqcType)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Agencies:</span>
                          {getProjectAgencies(eqcType)}
                        </div>
                        <div>
                          <span className="fw-medium mr-5">Status:</span>
                          {getStatus(eqcType?.status, eqcType)}
                        </div>
                      </div>
                    </div>
                    <span
                      className="d-flex position-absolute user-action-btn"
                      onClick={(e) => e?.stopPropagation?.()}
                    >
                      {getActionButtons(eqcType)}
                    </span>
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default EqcTypes;
