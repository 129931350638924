import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import { findIndex, forEach, get, nth, sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DragIcon,
  EditButton,
  EyeIcon
} from '../../../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  PROJECT_EQC_TYPE_STATUS
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import CanPerform from '../../../../../../../../components/CanPerform';
import CommonTable from '../../../../../../../../components/CommonTable';
import Portal from '../../../../../../../../components/Portal';
import {
  DELETE_PROJECT_STAGE,
  REORDER_PROJECT_STAGE
} from '../../../../../../graphql/Mutation';
import { GET_PROJECT_STAGE_LIST } from '../../../../../../graphql/Queries';
import DeleteModalStage from '../DeleteModalStage';
import AddEditStageModal from './AddEditStageModal';

const StageListTable = ({
  setStageId,
  stageId,
  setModalVisible,
  eqcTypeData
}) => {
  const { eqcTypeId, projectId } = useParams();
  const DragHandle = sortableHandle(() => (
    <DragIcon
      style={{ cursor: 'grabbing', color: '#999' }}
      onClick={(e) => e.preventDefault()}
    />
  ));
  const initialStageFilter = {
    skip: 0,
    limit: 10,
    projectEqcTypeId: Number(eqcTypeId),
    sortBy: { field: 'order', order: 'ASC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showModal, setShowModal] = useState(false);
  const [deleteStageModal, setDeleteStageModal] = useState(false);
  const [stageData, setStageData] = useState();
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [mutationId, setMutationId] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [stageFilter, setStageFilter] = useState(initialStageFilter);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [fetchStageData, { loading }] = useLazyQuery(GET_PROJECT_STAGE_LIST, {
    variables: { filter: stageFilter },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (isDesktopViewport) {
        const obj = nth(res?.projectEqcTypeStageList?.data, 0);
        setStageId(obj?.id);
      }
      const pagination = {
        ...paginationProp,
        total: res?.projectEqcTypeStageList?.total
      };
      setPaginationProp(pagination);
      setDataSource(sortBy(res?.projectEqcTypeStageList?.data, ['order']));
    },
    onError() {}
  });
  const handleRefetchAfterDelete = () => {
    const newSkip =
      dataSource?.length === 1
        ? Math.max(0, stageFilter?.skip - paginationProp?.pageSize)
        : stageFilter?.skip;
    setStageFilter({
      ...stageFilter,
      skip: newSkip
    });
    fetchStageData({
      variables: {
        filter: {
          ...stageFilter,
          skip: newSkip
        }
      }
    });
  };
  const [deleteStage] = useMutation(DELETE_PROJECT_STAGE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_PROJECT_EQC_TYPE_STAGE, {
        label: GA_LABEL.DELETE_PROJECT_EQC_TYPE_STAGE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_eqc_type_id: eqcTypeId,
        project_stage_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setDeleteStageModal(false);
      handleRefetchAfterDelete();
    }
  });

  useEffect(() => {
    fetchStageData({ variables: { filter: stageFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchStageDetails = () => {
    setStageFilter(initialStageFilter);
    setPaginationProp(initialPaginationValue);
    fetchStageData({ variables: { filter: { ...initialStageFilter } } });
  };

  const [reorderStage, { loading: reorderLoading }] = useMutation(
    REORDER_PROJECT_STAGE,
    {
      onCompleted() {
        Event(GA_EVENT.REORDER_PROJECT_EQC_TYPE_STAGE, {
          label: GA_LABEL.REORDER_PROJECT_EQC_TYPE_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_eqc_type_id: eqcTypeId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        fetchStageData();
      },
      onError() {}
    }
  );

  const handleAddEditUser = (record) => {
    if (record) {
      setStageData(record);
    } else {
      setStageData();
    }
    setShowModal(true);
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setStageFilter({ ...stageFilter, skip: skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchStageData({
      variables: {
        filter: {
          ...stageFilter,
          skip,
          limit: pagination.pageSize
        }
      }
    });
  };

  const generateTip = (record) => {
    const approvers = get(record, 'projectEqcTypeStageApprovals' || []);
    if (approvers?.length) {
      const names = [];
      forEach(approvers, (approver) => {
        const { internalApprover, externalApprover } = approver;
        names.push(internalApprover?.user?.name || externalApprover?.name);
      });
      return names.join(', ');
    }
    return `N/A`;
  };

  const handleEdit = (e, record) => {
    e?.stopPropagation?.();
    handleAddEditUser(record);
  };
  const handleDelete = (e, record) => {
    e?.stopPropagation?.();
    setTitle('Stage');
    setName(record?.name);
    setMutationId(record?.id);
    setDeleteStageModal(true);
  };
  const columns = [
    {
      width: '5%',
      className: 'drag-visible',
      render: () =>
        eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED &&
        eqcTypeData?.project?.isActive && (
          <Tooltip title="Change order">
            <div>
              <DragHandle />
            </div>
          </Tooltip>
        )
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      className: 'drag-visible',
      key: 'name',
      width: '70%',
      render: (text, record) => (
        <div className="d-flex">
          <div className="mr-5">{text}</div>
          {record?.needApproval && (
            <Tooltip
              placement="bottom"
              title={generateTip(record)}
              trigger={[!isDesktopViewport ? 'click' : 'hover']}
            >
              <EyeIcon
                onClick={(e) => {
                  if (!isDesktopViewport) e.stopPropagation();
                }}
              />
            </Tooltip>
          )}
        </div>
      )
    },
    {
      key: 'action',
      render: (record) => {
        return (
          <CanPerform
            action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC_TYPES}
            type={ALLOWED_ACTION_TYPE.BOTH}
          >
            <div className="d-flex">
              <Tooltip title="Edit">
                <Button
                  shape="round"
                  icon={<EditButton />}
                  className="edit-button pointer b-0"
                  disabled={
                    eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.ARCHIVED ||
                    !eqcTypeData?.project?.isActive
                  }
                  onClick={(e) => handleEdit(e, record)}
                />
              </Tooltip>
              {(!isDesktopViewport
                ? eqcTypeData?.status ===
                    PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED &&
                  eqcTypeData?.project?.isActive
                : true) && (
                <Tooltip title="Delete">
                  <Button
                    className="delete-button pointer b-0"
                    shape="round"
                    icon={<DeleteButton />}
                    disabled={
                      eqcTypeData?.status ===
                        PROJECT_EQC_TYPE_STATUS?.PUBLISHED ||
                      !eqcTypeData?.project?.isActive
                    }
                    onClick={(e) => handleDelete(e, record)}
                  />
                </Tooltip>
              )}
            </div>
          </CanPerform>
        );
      }
    }
  ];

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      reorderStage({
        variables: {
          data: {
            stageIds: [dataSource[oldIndex]?.id, dataSource[newIndex]?.id],
            projectEqcTypeId: Number(eqcTypeId)
          }
        }
      });
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      axis="y"
      lockAxis="y"
      lockOffset={['0%', '100%']}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = findIndex(
      dataSource,
      (item) => item?.id === restProps['data-row-key']
    );
    if (index !== -1)
      return (
        <SortableItem index={index} {...restProps} className={className} />
      );
    return null;
  };
  return (
    <>
      <CanPerform
        action={ALLOWED_ACTION_KEYS.ADD_PROJECT_STAGE}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        {showModal && (
          <AddEditStageModal
            showModal={showModal}
            setShowModal={setShowModal}
            stageData={stageData}
            isUpdate={!!stageData}
            setStageData={setStageData}
            refetchStageDataWithInitialValues={refetchStageDetails}
            eqcTypeId={eqcTypeId}
            eqcTypeData={eqcTypeData}
            isDisabled={
              eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.PUBLISHED ||
              !eqcTypeData?.project?.isActive
            }
          />
        )}
        <DeleteModalStage
          showModal={deleteStageModal}
          setShowModal={setDeleteStageModal}
          title={title}
          name={name}
          deleteStage={deleteStage}
          mutationId={mutationId}
          setMutationId={setMutationId}
        />
      </CanPerform>
      {(!isDesktopViewport
        ? eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED &&
          eqcTypeData?.project?.isActive
        : true) && (
        <CanPerform
          action={ALLOWED_ACTION_KEYS.ADD_PROJECT_STAGE}
          type={ALLOWED_ACTION_TYPE.BOTH}
        >
          <Portal portalId="eqc-details-btn">
            <Portal portalId="add-btn">
              <Button
                shape="round"
                type="primary"
                id="add-btn"
                icon={<AddButton />}
                disabled={
                  eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.PUBLISHED ||
                  eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.ARCHIVED ||
                  !eqcTypeData?.project?.isActive
                }
                className="add-button b-0"
                onClick={() => {
                  handleAddEditUser();
                }}
              >
                {isDesktopViewport && 'Add'}
              </Button>
            </Portal>
          </Portal>
        </CanPerform>
      )}
      <div className="pointer">
        <CommonTable
          columns={columns}
          data={dataSource || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey="id"
          loading={loading || reorderLoading}
          rowClassName={(record) => {
            if (record?.id === stageId) return 'row-dark';
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (record?.id) {
                  setStageId(record?.id);
                  setModalVisible(true);
                }
              }
            };
          }}
          components={{
            body: dataSource?.length > 0 && {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
        />
      </div>
    </>
  );
};

export default StageListTable;
