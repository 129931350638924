import { Button, Form, Modal } from 'antd';
import { filter, includes, map } from 'lodash';
import React from 'react';
import { formValidatorRules } from '../../../common/utils';
import CommonSelect from '../../../components/CommonSelect';

const { required } = formValidatorRules;
const { Option } = CommonSelect;

const AddProjectEqcTypeModal = (props) => {
  const {
    showModal,
    setShowModal,
    data,
    handleModalSubmit,
    selectedEqcTypes,
    onPopupScroll,
    onSearch,
    onClear
  } = props;
  const [form] = Form.useForm();
  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };
  const submitForm = (values) => {
    handleModalSubmit(values);
    form.resetFields();
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      footer={null}
      closable={false}
    >
      <h2 className="mb-15">Add EQC Type</h2>
      <Form form={form} layout="vertical" onFinish={submitForm}>
        <Form.Item name="type" label="EQC Type" rules={[required]}>
          <CommonSelect
            className="mr-3"
            placeholder="Select EQC Type"
            allowClear
            mode="multiple"
            onPopupScroll={onPopupScroll}
            onSearch={onSearch}
            onClear={onClear}
            onBlur={onClear}
            onChange={onClear}
          >
            {map(
              filter(data, ({ id }) => !includes(selectedEqcTypes, id)),
              ({ name, id }) => (
                <Option
                  key={`eqc-type-${id}`}
                  value={JSON.stringify({ name, id })}
                >
                  {name}
                </Option>
              )
            )}
          </CommonSelect>
        </Form.Item>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            Add
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddProjectEqcTypeModal;
