import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const INSTRUCTION_LOGS = gql`
  query instructionActivityLogList($filter: InstructionActivityLogFilter) {
    instructionActivityLogList(filter: $filter) {
      total
      data {
        id
        instructionId
        projectName
        projectId
        instruction {
          name
          type
        }
        dueDate
        creator
        agencyName
        createdAt
        status
        responder
      }
    }
  }
`;

export const ACTIVITY_LOGS = gql`
  query eqcActivityLogList($filter: EqcActivityLogFilter) {
    eqcActivityLogList(filter: $filter) {
      total
      data {
        id
        projectId
        projectName
        eqcType
        eqcStageName
        eqcId
        stageId
        eqcStagesId
        eqcName
        eqcStatus
        eqcStageStatus
        creatorName
        externalApproverName
        internalApproverName
        eqcStageApprovals {
          id
          internalApproverId
          externalApproverId
          internalApproverName
          externalApproverName
        }
        dueDate
        createdAt
      }
    }
  }
`;

export const EQC_LIST = gql`
  query eqcTypeDropdownList($filter: EqcTypeFilter) {
    eqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
