import { useMutation } from '@apollo/client';
import { Button, Form, Modal } from 'antd';
import { omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import {
  ACCESS_TYPE,
  GA_EVENT,
  GA_LABEL,
  PROJECT_EQC_TYPE_STATUS
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../common/utils';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import HasAccess from '../../../../../../components/HasAccess';
import {
  CREATE_PROJECT_AGENCY,
  UPDATE_PROJECT_AGENCY
} from '../../../../graphql/Mutation';
import {
  GET_AGENCY_CONTACTS_DROPDOWN_LIST,
  GET_PROJECT_AGENCY_DROPDOWN_LIST,
  GET_PROJECT_AGENCY_EQC_TYPES_LIST
} from '../../../../graphql/Queries';

const AddAgencyModal = (props) => {
  const {
    showModal,
    setShowModal,
    isUpdate,
    agencyData,
    setAgencyData,
    refetchAgencyData
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { required } = formValidatorRules;
  const [agencyId, setAgencyId] = useState(agencyData?.agencyId);
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isSelectAllContacts, setIsSelectAllContacts] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const handleCancel = () => {
    setShowModal(false);
    setAgencyData();
    form.resetFields();
  };
  useEffect(() => {
    if (hasInspectionAccess) {
      form.setFieldsValue({
        projectEqcTypeIds: agencyData?.projectAgencyEqcs?.map(
          (eqcValue) => eqcValue?.id
        )
      });
    }

    if (agencyData) {
      form.setFieldsValue({
        agencyId: agencyData?.agency?.name,
        contactIds: agencyData?.projectAgencyContacts?.map(
          (contactValue) => contactValue?.contact?.id
        )
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [createProjectAgency, { loading: updateLoading }] = useMutation(
    CREATE_PROJECT_AGENCY,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_PROJECT_AGENCY, {
          label: GA_LABEL.ADD_PROJECT_AGENCY,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setAgencyData();
        form.resetFields();
        setShowModal(false);
        refetchAgencyData();
      }
    }
  );

  const [updateProjectAgency, { loading: createLoading }] = useMutation(
    UPDATE_PROJECT_AGENCY,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_PROJECT_AGENCY, {
          label: GA_LABEL.EDIT_PROJECT_AGENCY,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_agency_id: agencyData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setAgencyData();
        form.resetFields();
        setShowModal(false);
        refetchAgencyData();
      }
    }
  );

  const onAgencySubmitFinish = async (formValues) => {
    const newFormValues = {
      contactIds: isSelectAllContacts ? [] : formValues?.contactIds,
      projectEqcTypeIds: isSelectAll ? [] : formValues?.projectEqcTypeIds,
      selectedAllContacts: isSelectAllContacts,
      selectedAllEqcTypes: isSelectAll
    };
    let variables = isUpdate
      ? {
          data: {
            ...newFormValues
          },
          id: agencyData?.id
        }
      : {
          ...formValues,
          ...newFormValues
        };
    if (!hasInspectionAccess) {
      if (isUpdate) {
        variables.data = omit(variables?.data, ['projectEqcTypeIds']);
      } else {
        variables = omit(variables, ['projectEqcTypeIds']);
      }
    }
    try {
      if (isUpdate) {
        await updateProjectAgency({
          variables: { ...variables }
        });

        return;
      }
      createProjectAgency({
        variables: {
          data: {
            ...variables,
            projectId
          }
        }
      });
    } catch (error) {
      return error;
    }
  };
  const handleDeselect = () => {
    setIsSelectAll(false);
    form.setFieldsValue({ projectEqcTypeIds: [] });
  };
  useEffect(() => {
    if (isSelectAll) {
      form.setFieldsValue({ projectEqcTypeIds: ['all'] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAll]);
  const handleContactsDeselect = () => {
    setIsSelectAllContacts(false);
    form.setFieldsValue({ contactIds: [] });
  };
  useEffect(() => {
    if (isSelectAllContacts) {
      form.setFieldsValue({ contactIds: ['all'] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAllContacts]);
  return (
    <Modal
      maskClosable={false}
      centered
      form={form}
      visible={showModal}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <h2 className="mb-15">{isUpdate ? 'Edit Agency' : 'Add Agency'}</h2>
      <Form layout="vertical" form={form} onFinish={onAgencySubmitFinish}>
        <Form.Item name="agencyId" label="Agency" rules={[required]}>
          <CommonDropdown
            placeholder="Select Agency"
            onChange={(e) => {
              setCurrentPage(0);
              setAgencyId(e);
              setIsSelectAllContacts(false);
              form.setFieldsValue({ contactIds: [] });
            }}
            disabled={!!isUpdate}
            showSearch
            optionFilterProp="children"
            allowClear
            query={GET_PROJECT_AGENCY_DROPDOWN_LIST}
            variables={{
              filter: { projectId: projectId }
            }}
            fetchPolicy="network-only"
            responsePath="agencyDropdownList.data"
            valuePath="id"
            labelPath="name"
            optionKey="user"
          />
        </Form.Item>
        <Form.Item
          name="contactIds"
          rules={[required]}
          label={
            <div className="d-flex justify-between width-percent-100">
              <div>Contact</div>
              {isSelectAllContacts && (
                <div
                  className="text-primary pointer"
                  role="button"
                  tabIndex="0"
                  onClick={handleContactsDeselect}
                >
                  Deselect All
                </div>
              )}
            </div>
          }
          normalize={(value) => {
            if (value.includes('all')) {
              setIsSelectAllContacts(true);
              return ['all'];
            }
            return value;
          }}
        >
          <CommonDropdown
            allowClear
            showSearch
            mode="multiple"
            disabled={isSelectAllContacts}
            placeholder="Select Contact"
            optionFilterProp="children"
            query={GET_AGENCY_CONTACTS_DROPDOWN_LIST}
            variables={{ filter: { projectId, agencyId: agencyId } }}
            fetchPolicy="network-only"
            responsePath="projectAgencyContactDropdownList.data"
            valuePath="id"
            labelPath="name"
            optionKey="contact-users"
            currentPage={currentPage}
            useEffectDeps={[agencyId]}
            conditionToCheckBeforeQuery={!!agencyId}
            projectId={agencyId}
            isSelectedAll={isSelectAllContacts}
            hasSelectAll
          />
        </Form.Item>
        {hasInspectionAccess && (
          <Form.Item
            label={
              <div className="d-flex justify-between width-percent-100">
                <div>EQC Type</div>
                {isSelectAll && (
                  <div
                    className="text-primary pointer"
                    role="button"
                    tabIndex="0"
                    onClick={handleDeselect}
                  >
                    Deselect All
                  </div>
                )}
              </div>
            }
            name="projectEqcTypeIds"
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectAll(true);
                return ['all'];
              }
              return value;
            }}
          >
            <CommonDropdown
              placeholder="Select EQC Type"
              mode="multiple"
              disabled={isSelectAll}
              showSearch
              optionFilterProp="children"
              allowClear
              query={GET_PROJECT_AGENCY_EQC_TYPES_LIST}
              variables={{
                filter: {
                  projectId: Number(projectId),
                  status: PROJECT_EQC_TYPE_STATUS?.PUBLISHED,
                  projectAgencyId: agencyData?.id
                }
              }}
              fetchPolicy="network-only"
              responsePath="projectAgencyEqcTypeDropdownList.data"
              valuePath="id"
              labelPath="name"
              isSelectedAll={isSelectAll}
              optionKey="eqc-type"
              hasSelectAll
            />
          </Form.Item>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={updateLoading || createLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddAgencyModal;
