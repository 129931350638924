import { useLazyQuery } from '@apollo/client';
import { Col, Row, Tag } from 'antd';
import { keys, map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  DEFAULTDATEFORMAT,
  DEFAULT_PAGE_SIZE,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL,
  ROUTES,
  TAB_KEYS
} from '../../../common/constants';
import { titleCase } from '../../../common/utils';
import CollapsibleFilterWrapper from '../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../components/CommonCard';
import CommonDropdown from '../../../components/CommonDropdown';
import CommonSelect from '../../../components/CommonSelect';
import CommonTable from '../../../components/CommonTable';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../components/SearchComponent';
import { GET_PROJECT_DROPDOWN_LIST } from '../../projects/graphql/Queries';
import { INSTRUCTION_LOGS } from '../graphql/Queries';

const { Option } = CommonSelect;

const Instruction = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const history = useHistory();
  const initialInstructionLogFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [instructionLogFilter, setInstructionLogFilter] = useState(
    initialInstructionLogFilter
  );

  const [fetchInstructionLogs, { loading }] = useLazyQuery(INSTRUCTION_LOGS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.instructionActivityLogList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.instructionActivityLogList?.total
      };
      setPaginationProp(pagination);
      setHasMore(!!data?.length);
      if (scrollFlag) {
        const datalist = [...activityData, ...data];
        setActivityData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setActivityData(datalist);
      }
    },
    onError() {}
  });

  const onStatusChange = (status) => {
    setInstructionLogFilter({
      ...instructionLogFilter,
      status
    });
    fetchInstructionLogs({
      variables: {
        filter: {
          ...instructionLogFilter,
          status
        }
      }
    });
  };

  const onProjectChange = (projectId) => {
    setInstructionLogFilter({
      ...instructionLogFilter,
      projectId
    });
    fetchInstructionLogs({
      variables: {
        filter: {
          ...instructionLogFilter,
          projectId
        }
      }
    });
  };
  const instructionLogsData = async (value) => {
    fetchInstructionLogs({
      variables: {
        filter: {
          ...instructionLogFilter,
          skip: 0,
          limit: DEFAULT_PAGE_SIZE,
          search: value
        }
      }
    });
    setInstructionLogFilter({
      ...instructionLogFilter,
      skip: 0,
      limit: DEFAULT_PAGE_SIZE,
      search: value
    });
  };
  useEffect(() => {
    fetchInstructionLogs({
      variables: { filter: initialInstructionLogFilter }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setInstructionLogFilter({
        ...instructionLogFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.field,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchInstructionLogs({
        variables: {
          filter: {
            ...instructionLogFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.field,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setInstructionLogFilter({
        ...instructionLogFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });
      fetchInstructionLogs({
        variables: {
          filter: {
            ...instructionLogFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' }
          }
        }
      });
    }
  };

  const getStatus = (record) => {
    return (
      <Tag className={INS_STATUS_CLASSNAME[record?.status]}>
        {INS_STATUS_LABEL[record?.status]}
      </Tag>
    );
  };

  const handleRefetch = () => {
    fetchInstructionLogs({
      variables: {
        filter: {
          ...instructionLogFilter,
          skip: activityData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  const getUpdatedBy = (record) => {
    if (record?.status === INS_STATUS_KEYS.RESPONDED) {
      return '-';
    }
    return record?.creator;
  };
  const getRespondedBy = (record) => {
    return record?.status === INS_STATUS_KEYS.RESPONDED
      ? record?.responder || '-'
      : '-';
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{instructionLogFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'PROJECT',
      dataIndex: 'projectName',
      key: 'projectName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'projectName' && sortedInfo?.order
    },
    {
      title: 'ISSUE NAME',
      dataIndex: '',
      key: 'instructionName',
      render: (text, record) => (
        <NavLink
          className="text-primary text-underline pointer"
          to={`${ROUTES.PROJECTS}/${record?.projectId}/${TAB_KEYS.INSTRUCTION}/${record?.instructionId}`}
        >
          {record?.instruction?.name}
        </NavLink>
      )
    },
    {
      title: 'TYPE',
      dataIndex: '',
      key: 'instructionType',
      render: (record) => {
        return record?.instruction?.type;
      }
    },
    {
      title: 'DUE DATE',
      dataIndex: 'dueDate',
      key: 'dueDate',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'dueDate' && sortedInfo?.order,
      render: (dueDate, record) => {
        return record?.dueDate
          ? moment(`${record?.dueDate}`).format(DEFAULTDATEFORMAT)
          : '-';
      }
    },
    {
      title: 'UPDATED BY',
      key: 'modifier',
      render: (record) => getUpdatedBy(record)
    },
    {
      title: 'RESPONDED BY',
      key: 'responder',
      render: (record) => getRespondedBy(record)
    },
    {
      title: 'AGENCY',
      dataIndex: 'agencyName',
      key: 'agencyName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'agencyName' && sortedInfo?.order
    },
    {
      title: 'DATE',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (dueDate, record) => {
        return record?.createdAt
          ? moment(`${record?.createdAt}`).format(DEFAULTDATEFORMAT)
          : '-';
      }
    },
    {
      title: 'STATUS',
      key: 'status',
      render: (status, record) => getStatus(record)
    }
  ];
  const handleRowClick = (record) => {
    if (record?.projectId && record?.instructionId) {
      history.push(
        `${ROUTES.PROJECTS}/${record?.projectId}/${TAB_KEYS.INSTRUCTION}/${record?.instructionId}`
      );
    }
  };
  return (
    <div>
      <div className="d-flex justify-between width-percent-100">
        {isDesktopViewport && <h1>Issue Logs</h1>}
        <div className={isDesktopViewport ? '' : 'width-percent-100'}>
          <CollapsibleFilterWrapper
            searchProps={{
              className: 'search-component',
              getData: instructionLogsData
            }}
            className="mb-15"
          >
            <Row
              justify={isDesktopViewport ? 'end' : 'start'}
              className={`d-flex filter-search `}
              wrap
              gutter={isDesktopViewport ? [10, 10] : [0, 10]}
            >
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  allowClear
                  placeholder="Project"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  onChange={onProjectChange}
                  query={GET_PROJECT_DROPDOWN_LIST}
                  fetchPolicy="network-only"
                  responsePath="projectDropdownList.data"
                  valuePath="id"
                  labelPath="name"
                  optionKey="project"
                  showSearch
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonSelect
                  allowClear
                  name="status"
                  placeholder="Status"
                  className={`instruction-selector ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  onChange={onStatusChange}
                >
                  {keys(INS_STATUS_KEYS).map((status) => (
                    <Option key={status} value={status}>
                      {titleCase(status)}
                    </Option>
                  ))}
                </CommonSelect>
              </Col>
              {isDesktopViewport && (
                <Col>
                  <SearchComponent
                    id="search-container-id"
                    className="search-component width-200"
                    getData={instructionLogsData}
                  />
                </Col>
              )}
            </Row>
          </CollapsibleFilterWrapper>
        </div>
      </div>

      {isDesktopViewport ? (
        <div className="table">
          <CommonTable
            loadingData={loading}
            columns={columns}
            data={activityData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowClassName="pointer"
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record)
              };
            }}
          />
        </div>
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          wrapperClassName="instruction-scroll-wrapper"
          dataLength={activityData?.length}
          skeletonRows={columns?.length - 2}
        >
          {map(activityData, (activity, index) => {
            return (
              <CommonCard key={activity?.id} onClick={handleRowClick}>
                <div className="common-card d-flex">
                  <div className="mr-5 fw-medium">{index + 1}.</div>
                  <div>
                    <div className="card-header fw-medium">
                      <span className="text-break">
                        {activity?.projectName}
                      </span>
                    </div>
                    <div className="card-content text-secondary">
                      <br />
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Issue Name:</span>
                        <NavLink
                          className="text-primary text-underline pointer text-break"
                          to={`${ROUTES.PROJECTS}/${activity?.projectId}/${TAB_KEYS.INSTRUCTION}/${activity?.instructionId}`}
                        >
                          {activity?.instruction?.name}
                        </NavLink>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Type:</span>
                        <span className="text-break">
                          {activity?.instruction?.type}
                        </span>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Due Date:</span>{' '}
                        <span>
                          {activity?.dueDate
                            ? moment(`${activity?.dueDate}`).format(
                                DEFAULTDATEFORMAT
                              )
                            : '-'}
                        </span>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Updated By:</span>
                        {getUpdatedBy(activity)}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Responded By:</span>
                        {getRespondedBy(activity)}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Agency:</span>
                        <span className="text-break">
                          {activity?.agencyName || '-'}
                        </span>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Date:</span>
                        {activity?.createdAt
                          ? moment(`${activity?.createdAt}`).format(
                              DEFAULTDATEFORMAT
                            )
                          : '-'}
                      </div>
                      <div>
                        <span className="fw-medium mr-5">Status:</span>
                        {getStatus(activity)}
                      </div>
                    </div>
                  </div>
                </div>
              </CommonCard>
            );
          })}
        </InfiniteScrollHandler>
      )}
    </div>
  );
};

export default Instruction;
