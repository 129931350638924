import { message } from 'antd';
import { camelCase, startCase } from 'lodash';
import moment from 'moment';
import { DEFAULTDATEFORMAT, REGEX } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) => {
  // eslint-disable-next-line no-undef
  return document.getElementById(portalId);
};

export const isPortalIdExists = (portalId) => {
  return !!injectUsingPortal(portalId);
};

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) => {
  // eslint-disable-next-line no-undef
  return document.getElementById(portalId);
};

export const isDocumentIdExist = (portalId) => {
  return !!getElementFromDocumentId(portalId);
};
// Check for document Id's exists end

export const formatDate = (
  datetime,
  format = `${DEFAULTDATEFORMAT} hh:mm A`
) => {
  if (datetime && moment && format) {
    return moment(datetime).format(format);
  }

  return datetime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required'
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Should be a valid Number');
      }
      return Promise.resolve();
    }
  }),
  password: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!REGEX.PASSWORD.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(
          'Password should contain min 8 letter, with at least a symbol, upper and lower case letters and a number!'
        );
      }
      return Promise.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice).toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formItemProps = { normalize: (value) => value.trim() };

export async function fileUpload(signedUrl, image, onUploadProgress) {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', signedUrl);
      xhr.setRequestHeader('Content-Type', image.type);
      xhr.addEventListener('error', function (error) {
        if (error) {
          reject(error);
        }
      });
      xhr.addEventListener('load', function () {
        if (this.readyState === 4) {
          resolve(xhr.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math.ceil((e.loaded / e.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr.send(image);
    } catch (error) {
      message.error(error.message);
    }
  });
}

export const titleCase = (str = '') => startCase(camelCase(str));

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function timeTaken(startDate, EndDate) {
  // eslint-disable-next-line no-nested-ternary
  return moment(EndDate).diff(startDate, 'minutes', true) >= 60
    ? moment(EndDate).diff(startDate, 'minutes', true) % 60 === 0
      ? `${Math.abs(moment(EndDate).diff(startDate, 'hours', true)).toFixed(
          2
        )} h`
      : `${
          Math.abs(moment(EndDate).diff(startDate, 'hours', true))
            .toFixed(2)
            .split('.')[0]
        } h ${
          Math.abs(moment(EndDate).diff(startDate, 'hours', true))
            .toFixed(2)
            .split('.')[1]
        }mins`
    : `${Math.abs(moment(EndDate).diff(startDate, 'minutes', true)).toFixed(
        0
      )} mins`;
}
