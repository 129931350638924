import { Card, Col, Collapse, Image, Row, Space } from 'antd';
import { map, nth, tail } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { AudioIcon, DownArrow, UpArrow } from '../../../../../../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHBRACKET,
  GA_EVENT,
  GA_LABEL,
  INS_STATUS_KEYS
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import AudioPlayerModal from '../../../../../../../components/AudioPlayerModal';
import EllipsisText from '../../../../../../../components/EllipsisText';
import PreviousDetailsCard from './PreviousDetailsCard';

const { Panel } = Collapse;

const RecommendationDetails = ({ data }) => {
  const [isOpen, setIsOpen] = useState();
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [audios, setAudios] = useState([]);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const latestDetails = nth(data, 0);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');

  const previousData = map(tail(data), (item) => {
    return {
      modifier: item?.modifier,
      createdAt: item?.createdAt,
      remark: item?.afterJson?.recommendation,
      photos: item?.afterJson?.recommendationPhoto,
      audios: item?.afterJson?.recommendationAudio
    };
  });
  return (
    <div className="instruction-tab-details mt-20">
      <Card className="instruction-tab-details-recommendation ">
        <div className="d-flex align-center justify-between mb-15">
          <div className="d-flex">
            <h2>Recommendation Details</h2>
          </div>
        </div>
        <div className="mobile-card">
          <div className="d-flex align-center justify-between mb-15">
            <div className="d-flex">
              <h5>{`${data?.length}. ${latestDetails?.modifier?.name || ''} - ${
                latestDetails?.createdAt
                  ? moment(latestDetails?.createdAt).format(DATETIMEWITHBRACKET)
                  : ''
              }`}</h5>
            </div>
          </div>
          <Row
            gutter={isDesktopViewport ? [75, 40] : [40, 30]}
            className="fw-medium mb-20"
          >
            <Col span={isDesktopViewport ? 10 : 24}>
              <div className="text-secondary mb-12">Remarks</div>
              <EllipsisText
                text={latestDetails?.afterJson?.recommendation || 'N/A'}
              />
            </Col>
            <Col span={isDesktopViewport ? 7 : 24}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">Photos</div>
                <div>
                  {latestDetails?.afterJson?.recommendationPhoto?.length > 0 ? (
                    <Image.PreviewGroup
                      preview={{
                        visible:
                          latestDetails?.afterJson?.id === previewImageKey &&
                          isVisible,
                        onVisibleChange: (visible) => {
                          setIsVisible(visible);
                          if (visible) {
                            Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                              label: GA_LABEL.OPENED_IMAGE_VIEWER,
                              // eslint-disable-next-line no-undef
                              pathname: window?.location?.href
                            });
                          }
                        }
                      }}
                    >
                      <Space className="image-preview-wrapper">
                        {React.Children.map(
                          latestDetails?.afterJson?.recommendationPhoto,
                          (photo) => (
                            <Image
                              src={photo}
                              alt="logo"
                              height="64px"
                              width="64px"
                              onClick={() =>
                                setPreviewImageKey(latestDetails?.afterJson?.id)
                              }
                            />
                          )
                        )}
                        {latestDetails?.afterJson?.recommendationPhoto?.length >
                          1 && (
                          <div
                            className="extra-count pointer"
                            onClick={() => {
                              setIsVisible(true);
                              setPreviewImageKey(latestDetails?.afterJson?.id);
                            }}
                          >
                            {`+${
                              latestDetails?.afterJson?.recommendationPhoto
                                ?.length - 1
                            }`}
                          </div>
                        )}
                      </Space>
                    </Image.PreviewGroup>
                  ) : (
                    'N/A'
                  )}
                </div>
              </Space>
            </Col>
            <Col span={isDesktopViewport ? 7 : 24}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">Audio</div>
                <div>
                  {latestDetails?.afterJson?.recommendationAudio?.length > 0 ? (
                    <div
                      className="audio-player-wrapper"
                      onClick={() => {
                        setShowAudioModal(true);
                        setAudios(
                          latestDetails?.afterJson?.recommendationAudio
                        );
                        Event(GA_EVENT.DOWNLOAD_AUDIO, {
                          label: GA_LABEL.DOWNLOAD_AUDIO,
                          // eslint-disable-next-line no-undef
                          pathname: window?.location?.href
                        });
                      }}
                    >
                      <AudioIcon />
                      {latestDetails?.afterJson?.recommendationAudio?.length -
                        1 >
                        0 && (
                        <div className="extra-count">{`+${
                          latestDetails?.afterJson?.recommendationAudio
                            ?.length - 1
                        }`}</div>
                      )}
                    </div>
                  ) : (
                    'N/A'
                  )}
                </div>
              </Space>
            </Col>
          </Row>
        </div>
        {latestDetails?.afterJson?.status !== INS_STATUS_KEYS.NOTICE && (
          <Collapse
            expandIconPosition="right"
            bordered={false}
            className="recommendation-collapse"
            expandIcon={() => {}}
            onChange={(activeKey) => setIsOpen(!!activeKey?.length)}
          >
            <Panel
              header={
                <div className="d-flex panel-header">
                  <div className="d-flex align-center">
                    Previous Recommendation Details
                  </div>
                  <div className="ml-10 d-flex align-center">
                    {isOpen ? (
                      <UpArrow width="12px" height="18px" />
                    ) : (
                      <DownArrow height="18px" width="12px" />
                    )}
                  </div>
                </div>
              }
            >
              <PreviousDetailsCard
                data={previousData}
                setAudios={setAudios}
                showAudioModal={showAudioModal}
                setShowAudioModal={setShowAudioModal}
              />
            </Panel>
          </Collapse>
        )}
      </Card>
      {audios?.length > 0 && (
        <AudioPlayerModal
          audios={audios}
          showModal={showAudioModal}
          setShowModal={setShowAudioModal}
        />
      )}
    </div>
  );
};

export default RecommendationDetails;
