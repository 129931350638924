import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Row } from 'antd';
import { debounce, filter, findIndex, includes, keys, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import { MinusCircle } from '../../../assets/svg';
import {
  ADD_PROJECT_STEPS_KEYS,
  BREAKPOINTS,
  DROPDOWN_LIMIT,
  GA_EVENT,
  GA_LABEL,
  PROJECT_ROLES_LABEL,
  PROJECT_USER_ROLES,
  ROUTES,
  SCROLL_CONST,
  USER_TAB_ROLES
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { formValidatorRules, titleCase } from '../../../common/utils';
import CommonSelect from '../../../components/CommonSelect';
import LoaderComponent from '../../../components/LoaderComponent';
import { ONBOARD_PROJECT_USERS } from '../graphql/Mutation';
import { GET_ALL_USERS, GET_ONBOARDED_PROJECT_USERS } from '../graphql/Queries';

const { required } = formValidatorRules;
const { Option } = CommonSelect;
let searchDebounce = null;

const AddProjectUsers = ({ projectId }) => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [existingIds, setExistingIds] = useState([]);
  const [disabledUsers, setDisabledUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [remainingUsers, setRemainingUsers] = useState([]);
  const history = useHistory();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');

  const [fetchAllUsers, { loading: allUsersLoading }] = useLazyQuery(
    GET_ALL_USERS,
    {
      fetchPolicy: 'network-only',
      variables: {
        filter: { skip: 0, limit: DROPDOWN_LIMIT, projectId }
      },
      onCompleted: (res) => {
        const data = res?.projectUserDropdownList?.data;
        if (currentPage === 0) {
          setRemainingUsers(data);
          setAllUsers(data);
        } else {
          setRemainingUsers((oldData) => [...oldData, ...data]);
          setAllUsers((oldData) => [...oldData, ...data]);
        }
        setCurrentPage((page) => page + 1);
        setHasMore(data?.length >= DROPDOWN_LIMIT);
      }
    }
  );
  const { data: existingUsers, loading: projectUsersLoading } = useQuery(
    GET_ONBOARDED_PROJECT_USERS,
    {
      fetchPolicy: 'network-only',
      variables: { filter: { projectId } }
    }
  );
  const [onboardProjectUsers, { loading: mutationLoading }] = useMutation(
    ONBOARD_PROJECT_USERS,
    {
      onError() {},
      onCompleted: () => {
        Event(GA_EVENT.ONBOARD_PROJECT_USER, {
          label: GA_LABEL.ONBOARD_PROJECT_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        history.push(
          `${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.AGENCIES}`
        );
      }
    }
  );
  useEffect(() => {
    fetchAllUsers({
      variables: {
        filter: {
          skip: currentPage * DROPDOWN_LIMIT,
          limit: DROPDOWN_LIMIT,

          projectId
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formSubmit = ({ projectUsers }) => {
    const data = map(projectUsers, ({ projectUserId, roles }) => {
      return {
        roles:
          roles === PROJECT_ROLES_LABEL.EXTERNAL_AUDITOR
            ? USER_TAB_ROLES.EXTERNAL_AUDITOR
            : roles,
        userId: Number(projectUserId),
        projectId,
        webAccess: true,
        permissions: [],
        projectEqcTypeIds: []
      };
    });
    onboardProjectUsers({
      variables: { data }
    });
  };

  useEffect(() => {
    if (existingUsers?.onboardProjectUserList?.data?.length) {
      const tempArr = [];
      const disabledRole = [];
      const data = map(
        existingUsers?.onboardProjectUserList?.data,
        ({ user: { id, roles: userRole }, roles }) => {
          tempArr.push(id);
          if (
            userRole.includes(USER_TAB_ROLES.SYSTEM_ADMIN) ||
            roles === USER_TAB_ROLES.EXTERNAL_AUDITOR
          ) {
            disabledRole.push(true);
          } else {
            disabledRole.push(false);
          }
          return { projectUserId: id, roles };
        }
      );
      form.setFieldsValue({
        projectUsers: data
      });
      setExistingIds(tempArr);
      setDisabledUsers(disabledRole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingUsers]);

  useEffect(() => {
    if (existingIds?.length && allUsers?.length) {
      const remainingUser = filter(
        allUsers,
        (user) => !includes(existingIds, user.id)
      );
      setRemainingUsers(remainingUser);
    }
  }, [existingIds, allUsers]);

  const getAddedUser = (index) => {
    const dataIndex = findIndex(
      existingUsers?.onboardProjectUserList?.data,
      (user) => user?.user?.id === existingIds[index]
    );
    if (dataIndex === -1) {
      return null;
    }
    return (
      <Option key={`user-${existingIds[index]}`} value={existingIds[index]}>
        {existingUsers?.onboardProjectUserList?.data?.[dataIndex]?.user?.name}
      </Option>
    );
  };
  const getAddedRole = (index) => {
    const dataIndex = findIndex(
      existingUsers?.onboardProjectUserList?.data,
      (user) => user?.user?.id === existingIds[index]
    );
    if (
      dataIndex === -1 ||
      includes(
        PROJECT_USER_ROLES,
        existingUsers?.onboardProjectUserList?.data?.[dataIndex]?.roles
      )
    ) {
      return null;
    }
    return (
      PROJECT_USER_ROLES[
        existingUsers?.onboardProjectUserList?.data?.[dataIndex]?.roles
      ] !== PROJECT_USER_ROLES?.PROJECT_ADMIN && (
        <Option
          key={`other-role-${existingIds[index]}`}
          value={
            existingUsers?.onboardProjectUserList?.data?.[dataIndex]?.roles
          }
        >
          {titleCase(
            existingUsers?.onboardProjectUserList?.data?.[dataIndex]?.roles
          )}
        </Option>
      )
    );
  };
  const searchQuery = (search) => {
    setSearchText(search);
    setCurrentPage(0);
    fetchAllUsers({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          skip: 0,
          limit: DROPDOWN_LIMIT,
          search,
          projectId
        }
      }
    });
  };
  const updateUserList = (value, index) => {
    const newIds = [...existingIds];
    const users = [...disabledUsers];
    newIds[index] = value;
    setExistingIds(newIds);
    const userIndex = findIndex(allUsers, (user) => user?.id === value);
    if (userIndex !== -1) {
      const isSystemAdmin = allUsers[userIndex]?.roles?.includes(
        USER_TAB_ROLES.SYSTEM_ADMIN
      );
      const isExternalAuditor = allUsers[userIndex]?.roles?.includes(
        USER_TAB_ROLES.EXTERNAL_AUDITOR
      );
      if (isSystemAdmin) {
        const formData = [...form.getFieldValue('projectUsers')];
        formData[index].roles = USER_TAB_ROLES.PROJECT_ADMIN;
        form.setFieldsValue({ projectUsers: formData });
        users[index] = true;
      } else if (isExternalAuditor) {
        const formData = [...form.getFieldValue('projectUsers')];
        formData[index].roles = PROJECT_ROLES_LABEL.EXTERNAL_AUDITOR;
        form.setFieldsValue({ projectUsers: formData });
        users[index] = true;
      } else {
        const formData = [...form.getFieldValue('projectUsers')];
        formData[index].roles = '';
        form.setFieldsValue({ projectUsers: formData });
        users[index] = false;
      }
    } else if (value === '') {
      users.splice(index, 1);
    }
    setDisabledUsers(users);
    if (searchText) {
      searchQuery();
    }
  };
  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && hasMore && !allUsersLoading) {
      fetchAllUsers({
        variables: {
          filter: {
            skip: currentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            search: searchText,
            projectId
          }
        }
      });
    }
  };

  const handleSearch = (value) => {
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(value);
  };
  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };
  if (projectUsersLoading) return <LoaderComponent setHeight={0} />;
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={formSubmit}
        initialValues={{ projectUsers: [{ user: undefined, role: undefined }] }}
      >
        <Row
          gutter={isDesktopViewport ? 30 : 8}
          align="start"
          className="mb-10"
        >
          <Col span={11}>
            <div>
              <span className="required-mark">*</span>
              <span>User</span>
            </div>
          </Col>
          <Col span={11}>
            <span className="required-mark">*</span>
            <span>Role</span>
          </Col>
        </Row>
        <Form.List name="projectUsers">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Row
                    gutter={isDesktopViewport ? 30 : 8}
                    key={field.key}
                    align="start"
                    wrap={false}
                  >
                    <Col span={11}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'projectUserId']}
                        fieldKey={[field.fieldKey, 'projectUserId']}
                        rules={[required]}
                      >
                        <CommonSelect
                          showSearch
                          optionFilterProp="children"
                          allowClear
                          onChange={(v) => updateUserList(v, index)}
                          onPopupScroll={handleScroll}
                          onSearch={handleSearch}
                          onClear={handleClear}
                          dropdownMatchSelectWidth={false}
                        >
                          {map(remainingUsers, ({ name, id }) => (
                            <Option key={`user-${id}`} value={id}>
                              {name}
                            </Option>
                          ))}
                          {getAddedUser(index)}
                        </CommonSelect>
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'roles']}
                        fieldKey={[field.fieldKey, 'roles']}
                        rules={[required]}
                      >
                        <CommonSelect
                          allowClear
                          disabled={!!disabledUsers[index]}
                        >
                          {map(keys(PROJECT_USER_ROLES), (role, roleIndex) => (
                            <Option key={`role-${roleIndex}`} value={role}>
                              {titleCase(PROJECT_USER_ROLES[role])}
                            </Option>
                          ))}
                          {getAddedRole(index)}
                        </CommonSelect>
                      </Form.Item>
                    </Col>
                    {fields?.length > 1 && (
                      <Col>
                        <Form.Item>
                          <MinusCircle
                            onClick={() => {
                              remove(field.name);
                              updateUserList('', index);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                ))}
                <Button
                  className="p-0"
                  type="link"
                  onClick={() => {
                    add();
                  }}
                >
                  <div className="gx-text-underline">+ Add User</div>
                </Button>
              </div>
            );
          }}
        </Form.List>
        <Row justify="end">
          <Col className="form-buttons">
            <Button
              shape="round"
              onClick={() => {
                Event(GA_EVENT.CLICK_PREVIOUS_BUTTON, {
                  label: `While onboard project users`,
                  // eslint-disable-next-line no-undef
                  pathname: window?.location?.href,
                  project_id: projectId,
                  user_id: currentUser?.id,
                  user_name: currentUser?.name,
                  tenant_id: currentUser?.tenantUser?.tenant?.id,
                  tenant_name: currentUser?.tenantUser?.tenant?.organizationName
                });
                history.push(
                  `${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.DETAILS}`
                );
              }}
              className="cancel-button"
            >
              Previous
            </Button>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              loading={mutationLoading}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddProjectUsers;
