import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import { map, omit } from 'lodash';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../../AppContext';
import {
  GA_EVENT,
  GA_LABEL,
  PROJECT_EQC_TYPE_STATUS
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import { PUBLISH_EQC_TYPE } from '../../../../../graphql/Mutation';

const UnpublishEqcTypeModal = (props) => {
  const {
    showModal,
    setShowModal,
    eqcTypeData,
    setEqcTypeData,
    refetchEqcTypeData
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { projectId } = useParams();
  const handleCancel = () => {
    setShowModal(false);
    setEqcTypeData();
  };
  const [publishProjectEqcType] = useMutation(PUBLISH_EQC_TYPE);

  const handleSubmit = async () => {
    const { projectAgencyEqcTypes, projectUserEqcTypes } = eqcTypeData;
    const newValues = omit(eqcTypeData, [
      'project',
      '__typename',
      'id',
      'projectUserEqcTypes',
      'projectAgencyEqcTypes',
      'creator',
      'projectEqcTypeStages',
      'isAudited'
    ]);
    try {
      const response = await publishProjectEqcType({
        variables: {
          id: eqcTypeData?.id,
          data: {
            ...newValues,
            projectAgencyIds: map(
              projectAgencyEqcTypes,
              (agency) => agency?.id
            ),
            projectUserIds: map(projectUserEqcTypes, (user) => user?.id),
            projectId: Number(projectId),
            status: PROJECT_EQC_TYPE_STATUS?.ARCHIVED
          }
        }
      });
      if (response?.data?.publishProjectEqcType) {
        Event(GA_EVENT.UNPUBLISH_PROJECT_EQC_TYPE, {
          label: GA_LABEL.UNPUBLISH_PROJECT_EQC_TYPE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_eqc_type_id: eqcTypeData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setEqcTypeData();
        setShowModal(false);
        refetchEqcTypeData();
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        visible={showModal}
        onCancel={handleCancel}
        closable={false}
        footer={null}
      >
        <h2 className="mb-15">Unpublish EQC Type</h2>
        <div className="mb-15 ">
          Are you sure you want to UNPUBLISH ? Unpublished eqctype cannot be
          published again.
        </div>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleSubmit}
          >
            Unpublish
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UnpublishEqcTypeModal;
