import { Button, Card, Col, Image, Row, Space } from 'antd';
import { get, nth, takeRight } from 'lodash';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  STAGE_STATUS
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import EllipsisText from '../../components/EllipsisText';
import PreviousCommentModal from './PreviousCommentModal';

const CommentSection = ({ data }) => {
  const [index, setIndex] = useState();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const stageHistory = data?.getEqcStageHistoryWithoutAuth;
  const previousHistoryData = stageHistory?.previousHistoryData || [];
  const [showModal, setShowModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');
  const historyData = nth(previousHistoryData, 0);
  const generalCommentHistoryData = nth(
    (historyData && historyData.itemHistory) || [],
    0
  );
  const generalCommentData = takeRight(
    previousHistoryData,
    previousHistoryData ? previousHistoryData.length - 1 : 0
  );
  const hasPreviousComments = !!generalCommentData.filter((revision) => {
    const temp = nth(revision.itemHistory, 0);
    return !!temp.generalComment;
  }).length;
  const generalComment = get(stageHistory, 'itemHistory[0].generalComment');
  const generalCommentImages = get(
    stageHistory,
    'itemHistory[0].generalCommentImages'
  );
  const lastApproverName = get(generalCommentHistoryData, 'approverName');
  const generalApproverName = get(stageHistory, 'itemHistory[0].approverName');
  const createdAt = get(stageHistory, 'itemHistory[0].createdAt');
  return (
    <>
      {showModal && (
        <PreviousCommentModal
          showModal={showModal}
          setShowModal={setShowModal}
          data={generalCommentData}
          index={index}
        />
      )}
      <div>
        <div>
          <Row gutter={[16, 16]}>
            {data &&
              stageHistory?.status !== STAGE_STATUS.APPROVAL_PENDING &&
              (generalComment || !!generalCommentImages?.length > 0) && (
                <Col span={isDesktopViewport ? 12 : 24}>
                  <Card className="comment-card">
                    <div>
                      {generalComment && (
                        <div className="mb-24">
                          <div className="d-flex align-center justify-between mb-5">
                            <h4>General Comment </h4>
                          </div>
                          <div className="mb-1">
                            <EllipsisText text={generalComment || `-`} />
                          </div>
                        </div>
                      )}
                      {generalCommentImages?.length > 0 && (
                        <Row className="flex-vertical mb-24">
                          <h4>General Comment Images</h4>
                          <div className="mt-5">
                            <div className="d-flex">
                              <Image.PreviewGroup
                                preview={{
                                  visible:
                                    previewImageKey ===
                                      'generalCommentImages' && isVisible,
                                  onVisibleChange: (visible) => {
                                    setIsVisible(visible);
                                    if (visible) {
                                      Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                                        label: GA_LABEL.OPENED_IMAGE_VIEWER,
                                        // eslint-disable-next-line no-undef
                                        pathname: window?.location?.href
                                      });
                                    }
                                  }
                                }}
                              >
                                <Space className="image-preview-wrapper">
                                  {React.Children.map(
                                    generalCommentImages,
                                    (photo) => (
                                      <Image
                                        src={photo}
                                        alt="logo"
                                        height="64px"
                                        width="64px"
                                        onClick={() =>
                                          setPreviewImageKey(
                                            'generalCommentImages'
                                          )
                                        }
                                      />
                                    )
                                  )}
                                  {generalCommentImages?.length - 1 > 0 && (
                                    <div
                                      className="pointer extra-count"
                                      onClick={() => {
                                        setIsVisible(true);
                                        setPreviewImageKey(
                                          'generalCommentImages'
                                        );
                                      }}
                                    >{`+ ${
                                      generalCommentImages?.length - 1
                                    }`}</div>
                                  )}
                                </Space>
                              </Image.PreviewGroup>
                            </div>
                          </div>
                        </Row>
                      )}
                      <div>
                        <h4>Reviewed By</h4>
                        <div className="mt-5 d-flex align-center justify-between">
                          <div>{generalApproverName || '-'}</div>
                          <div>{createdAt}</div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              )}
            {generalCommentHistoryData &&
            (generalCommentHistoryData?.generalComment ||
              generalCommentHistoryData?.generalCommentImages?.length > 0) ? (
              <Col
                span={
                  stageHistory?.status !== STAGE_STATUS.APPROVAL_PENDING &&
                  isDesktopViewport
                    ? 12
                    : 24
                }
              >
                <Card className="comment-card">
                  <div className="mb-24">
                    <div className="mb-5">
                      <h4>Last Comment </h4>
                    </div>
                    <EllipsisText
                      text={generalCommentHistoryData?.generalComment || '-'}
                    />
                  </div>
                  {get(generalCommentHistoryData, 'generalCommentImages', [])
                    ?.length > 0 && (
                    <div className="mb-24">
                      <h4>Last Comment Images</h4>
                      <div className="mt-5">
                        <Image.PreviewGroup
                          preview={{
                            visible:
                              generalCommentHistoryData?.order ===
                                previewImageKey && isVisible,
                            onVisibleChange: (visible) => {
                              setIsVisible(visible);
                              if (visible) {
                                Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                                  label: GA_LABEL.OPENED_IMAGE_VIEWER,
                                  // eslint-disable-next-line no-undef
                                  pathname: window?.location?.href
                                });
                              }
                            }
                          }}
                        >
                          <Space className="image-preview-wrapper">
                            {React.Children.map(
                              generalCommentHistoryData?.generalCommentImages,
                              (photo) => (
                                <Image
                                  src={photo}
                                  alt="logo"
                                  height="64px"
                                  width="64px"
                                  onClick={() =>
                                    setPreviewImageKey(
                                      generalCommentHistoryData?.order
                                    )
                                  }
                                />
                              )
                            )}
                            {generalCommentHistoryData?.generalCommentImages
                              ?.length -
                              1 >
                              0 && (
                              <div
                                className="pointer extra-count"
                                onClick={() => {
                                  setIsVisible(true);
                                  setPreviewImageKey(
                                    generalCommentHistoryData?.order
                                  );
                                }}
                              >{`+ ${
                                generalCommentHistoryData?.generalCommentImages
                                  ?.length - 1
                              }`}</div>
                            )}
                          </Space>
                        </Image.PreviewGroup>
                      </div>
                    </div>
                  )}
                  <Row className="flex-vertical">
                    <div
                      className={
                        isDesktopViewport
                          ? 'd-flex justify-between align-end'
                          : 'd-flex flex-vertical'
                      }
                    >
                      <div className="width-percent-100">
                        <h4>Reviewed By</h4>
                        <div className="d-flex align-center justify-between mt-5">
                          <div>{lastApproverName || '-'}</div>
                          <div>{generalCommentHistoryData?.createdAt}</div>
                        </div>
                      </div>
                    </div>
                    {hasPreviousComments && (
                      <div>
                        <Button
                          shape="round"
                          className={
                            !isDesktopViewport
                              ? 'previous-comment width-percent-100 justify-center'
                              : 'previous-comment'
                          }
                          onClick={(e, value, indexValue) => {
                            setShowModal(true);
                            setIndex(indexValue);
                          }}
                        >
                          View All Previous Comments
                        </Button>
                      </div>
                    )}
                  </Row>
                </Card>
              </Col>
            ) : (
              hasPreviousComments && (
                <div>
                  <Button
                    shape="round"
                    className={
                      !isDesktopViewport
                        ? 'previous-comment width-percent-100 justify-center'
                        : 'previous-comment'
                    }
                    onClick={(e, value, indexValue) => {
                      setShowModal(true);
                      setIndex(indexValue);
                    }}
                  >
                    View All Previous Comments
                  </Button>
                </div>
              )
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default CommentSection;
