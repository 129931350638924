export const SYSTEM_ROLES = {
  USER: 'USER',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
  CHECKLIST_MAKER: 'CHECKLIST_MAKER',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  PROJECT_ADMIN: 'PROJECT_ADMIN',
  AUDITOR: 'AUDITOR',
  INSPECTOR: 'INSPECTOR'
};
const {
  SYSTEM_ADMIN,
  PROJECT_ADMIN,
  CHECKLIST_MAKER,
  AUDITOR,
  USER,
  EXTERNAL_AUDITOR,
  INSPECTOR
} = SYSTEM_ROLES;

export const ALLOWED_ACTIONS = {
  ADD_PROJECT: [SYSTEM_ADMIN],
  EDIT_PROJECT: [SYSTEM_ADMIN],
  ADD_PROJECT_USER: [SYSTEM_ADMIN, PROJECT_ADMIN],
  VIEW_USERS_PAGE: [SYSTEM_ADMIN],
  VIEW_AGENCY_PAGE: [SYSTEM_ADMIN],
  VIEW_EQC_TYPES_PAGE: [SYSTEM_ADMIN, CHECKLIST_MAKER],
  VIEW_LOGS_PAGE: [
    SYSTEM_ADMIN,
    PROJECT_ADMIN,
    AUDITOR,
    USER,
    EXTERNAL_AUDITOR
  ],
  VIEW_REPORTS_PAGE: [SYSTEM_ADMIN],
  VIEW_PROJECTS_PAGE: [
    INSPECTOR,
    AUDITOR,
    PROJECT_ADMIN,
    SYSTEM_ADMIN,
    USER,
    EXTERNAL_AUDITOR
  ],
  EDIT_PROJECT_EQC: [PROJECT_ADMIN],
  EDIT_INSTRUCTION: [PROJECT_ADMIN],
  EDIT_PROJECT_EQC_TYPES: [SYSTEM_ADMIN, PROJECT_ADMIN],
  ADD_PROJECT_STAGE_ITEM: [SYSTEM_ADMIN, PROJECT_ADMIN],
  ADD_PROJECT_STAGE: [SYSTEM_ADMIN, PROJECT_ADMIN],
  EDIT_PROJECT_AGENCIES: [SYSTEM_ADMIN, PROJECT_ADMIN],
  ADD_AUDITOR_COMMENTS: [AUDITOR, EXTERNAL_AUDITOR],
  VIEW_DASHBOARD_PAGE: [
    INSPECTOR,
    AUDITOR,
    PROJECT_ADMIN,
    SYSTEM_ADMIN,
    USER,
    EXTERNAL_AUDITOR
  ],
  DELETE_EQC: [SYSTEM_ADMIN],
  PUBLISH_PROJECT_EQC_TYPE: [PROJECT_ADMIN, SYSTEM_ADMIN],
  VIEW_TENANT_SELECTION_DROPDOWN: [EXTERNAL_AUDITOR],
  EDIT_ORG_LOGO: [SYSTEM_ADMIN],
  MARK_QC_PASS: [PROJECT_ADMIN, INSPECTOR],
  GENERATE_REPORT: [SYSTEM_ADMIN]
};
export const ALLOWED_ACTION_KEYS = {
  ADD_PROJECT: 'ADD_PROJECT',
  EDIT_PROJECT: 'EDIT_PROJECT',
  ADD_PROJECT_USER: 'ADD_PROJECT_USER',
  VIEW_USERS_PAGE: 'VIEW_USERS_PAGE',
  VIEW_AGENCY_PAGE: 'VIEW_AGENCY_PAGE',
  VIEW_EQC_TYPES_PAGE: 'VIEW_EQC_TYPES_PAGE',
  VIEW_LOGS_PAGE: 'VIEW_LOGS_PAGE',
  VIEW_REPORTS_PAGE: 'VIEW_REPORTS_PAGE',
  VIEW_PROJECTS_PAGE: 'VIEW_PROJECTS_PAGE',
  EDIT_PROJECT_EQC: 'EDIT_PROJECT_EQC',
  EDIT_INSTRUCTION: 'EDIT_INSTRUCTION',
  EDIT_PROJECT_EQC_TYPES: 'EDIT_PROJECT_EQC_TYPES',
  ADD_PROJECT_STAGE_ITEM: 'ADD_PROJECT_STAGE_ITEM',
  ADD_PROJECT_STAGE: 'ADD_PROJECT_STAGE',
  EDIT_PROJECT_AGENCIES: 'EDIT_PROJECT_AGENCIES',
  ADD_AUDITOR_COMMENTS: 'ADD_AUDITOR_COMMENTS',
  VIEW_DASHBOARD_PAGE: 'VIEW_DASHBOARD_PAGE',
  DELETE_EQC: 'DELETE_EQC',
  PUBLISH_PROJECT_EQC_TYPE: 'PUBLISH_PROJECT_EQC_TYPE',
  VIEW_TENANT_SELECTION_DROPDOWN: 'VIEW_TENANT_SELECTION_DROPDOWN',
  EDIT_ORG_LOGO: 'EDIT_ORG_LOGO',
  MARK_QC_PASS: 'MARK_QC_PASS',
  GENERATE_REPORT: 'GENERATE_REPORT'
};
/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  FORGOT: '/forgot-password',
  CHANGE: '/change-password',
  RESET: '/reset-password',
  SET_PASSWORD: '/set-password',
  VERIFY: '/verify',
  USERS_MANAGEMENT: '/users',
  AUTHENTICATION: '/authentication',
  PROJECTS: '/projects',
  ADD_PROJECTS: '/projects/add',
  EQC_TYPES: '/eqctypes',
  LOGS: '/logs',
  AGENCIES: '/agencies',
  REPORTS: '/reports',
  HELP: '/help',
  BUG_REPORT: '/bug-report',
  PROFILE: '/profile',
  APPROVER_LINK: '/stage/:id/:token',
  APPROVER_SUCCESS: '/stage/success',
  REPORT_IMAGE_VIEWER: '/viewer/:url'
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  USERS_MANAGEMENT: 'Users',
  PROJECTS: 'Projects',
  EQC_TYPES: 'Eqc Type',
  LOGS: 'Logs',
  AGENCIES: 'Agencies',
  REPORTS: 'Reports',
  USERS: 'Users'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER_KEY = 'USER';
export const TENANT_ID = 'TENANT_ID';
export const DEFAULT_PAGE_SIZE = 10;
export const SCROLL_PERCENT = 0.85;
export const SCROLL_CONST = 5;
export const MAX_FILES_COUNT = 5;
export const FILE_SIZE_20MB = 20971520;
export const FILE_SIZE_10MB = 10485760;
export const DROPDOWN_LIMIT = 25;
export const TIMEOUT_TIME_MS = 3000;
export const POLLING_INTERVAL = 15000;
export const PING_RESOURCE = `/logo192.png`;
export const NUMBER_OF_ROWS_IN_TABLE_SKELETON = 4;
export const NUMBER_OF_SKELETON_CARDS = 3;

export const LAT_LNG = { lat: 21.1702401, lng: 72.8310607 };

export const PERMISSIONS = {
  FINGERPRINT: 'FINGERPRINT',
  LOCATION: 'LOCATION'
};
export const ADD_PROJECT_STEPS = {
  details: 'Project Details',
  users: 'Add Users',
  agencies: 'Add Agencies',
  eqctypes: 'Add EQC Types'
};
export const ADD_PROJECT_STEPS_KEYS = {
  DETAILS: 'details',
  USERS: 'users',
  AGENCIES: 'agencies',
  EQC_TYPES: 'eqctypes'
};
export const AGENCY_TYPE = {
  CONSULTANT: 'CONSULTANT',
  CONTRACTOR: 'CONTRACTOR',
  CLIENT: 'CLIENT',
  DEVELOPER: 'DEVELOPER',
  VENDOR: 'VENDOR',
  OTHERS: 'OTHERS'
};

export const AGENCY_TYPE_KEYS = {
  CONSULTANT: 'CONSULTANT',
  CONTRACTOR: 'CONTRACTOR',
  CLIENT: 'CLIENT',
  DEVELOPER: 'DEVELOPER',
  VENDOR: 'VENDOR',
  OTHERS: 'OTHERS'
};
export const ACCESS_TYPE = {
  INSPECTION: 'INSPECTION',
  INSTRUCTION: 'INSTRUCTION'
};
export const ALLOWED_ACTION_TYPE = {
  SYSTEM: 'SYSTEM',
  PROJECT: 'PROJECT',
  BOTH: 'BOTH'
};
export const USER_ROLES = {
  USER: 'USER',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
  CHECKLIST_MAKER: 'CHECKLIST_MAKER',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN'
};

export const USER_ROLES_KEYS = {
  USER: 'User',
  EXTERNAL_AUDITOR: 'External auditor',
  CHECKLIST_MAKER: 'Checklist maker',
  SYSTEM_ADMIN: 'System admin'
};
export const ADD_USER_ROLES = {
  USER: 'USER',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
  CHECKLIST_MAKER: 'CHECKLIST_MAKER'
};

export const STATUS = {
  RAISED: 'RAISED',
  CLOSED: 'CLOSED'
};

export const STATUS_KEYS = {
  RAISED: 'RAISED',
  CLOSED: 'CLOSED',
  RESPONDED: 'RESPONDED'
};

export const STAGE_STATUS = {
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  APPROVED: 'APPROVED',
  FAIL: 'FAIL',
  PASS: 'PASS',
  REDO: 'REDO'
};

export const STAGE_STATUS_KEYS = {
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  APPROVED: 'APPROVED',
  FAIL: 'FAIL',
  PASS: 'PASS',
  REDO: 'REDO'
};

export const EQC_STATUS = {
  PASSED: 'PASSED',
  IN_PROGRESS: 'IN PROGRESS',
  TERMINATE: 'TERMINATE',
  APPROVED: 'APPROVED'
};
export const EQC_STATUS_KEYS = {
  PASSED: 'PASSED',
  IN_PROGRESS: 'IN_PROGRESS',
  TERMINATE: 'TERMINATE'
};

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 750,
  desktop: 1000,
  hd: 1200
};

export const TYPES = {
  'Y/N': 'Y/N',
  OPTION: 'OPTION',
  TEXT: 'TEXT',
  NUMERIC: 'NUMERIC'
};

export const TYPES_LABELS = {
  'Y/N': 'Yes/No',
  OPTION: 'Options',
  TEXT: 'Text',
  NUMERIC: 'Numeric'
};

export const PROJECT_USER_ROLES = {
  PROJECT_ADMIN: 'PROJECT ADMIN',
  INSPECTOR: 'INSPECTOR',
  AUDITOR: 'AUDITOR'
};
export const PROJECT_ROLES = {
  PROJECT_ADMIN: 'PROJECT ADMIN',
  INSPECTOR: 'INSPECTOR',
  AUDITOR: 'AUDITOR',
  EXTERNAL_AUDITOR: 'EXTERNAL AUDITOR',
  EQC_MANAGER: 'EQC MANAGER'
};
export const PROJECT_ROLES_KEYS = {
  PROJECT_ADMIN: 'PROJECT_ADMIN',
  INSPECTOR: 'INSPECTOR',
  AUDITOR: 'AUDITOR',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR',
  EQC_MANAGER: 'EQC_MANAGER'
};
export const PROJECT_ROLES_LABEL = {
  PROJECT_ADMIN: 'Project admin',
  INSPECTOR: 'Inspector',
  AUDITOR: 'Auditor',
  EXTERNAL_AUDITOR: 'External Auditor',
  EQC_MANAGER: 'Eqc manager'
};
export const USER_TAB_ROLES = {
  PROJECT_ADMIN: 'PROJECT_ADMIN',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  USER: 'USER',
  INSPECTOR: 'INSPECTOR',
  AUDITOR: 'AUDITOR',
  EXTERNAL_AUDITOR: 'EXTERNAL_AUDITOR'
};

export const TAB_KEYS = {
  EQC: 'eqc',
  INSTRUCTION: 'issue',
  EQC_TYPES: 'eqctypes',
  USERS: 'users',
  AGENCIES: 'agencies'
};

export const LOGS_TABS_KEYS = {
  ACTIVITY: 'activitylogs',
  INSTRUCTION: 'issuelogs'
};

export const CSV_SAMPLE_FILES = {
  AGENCY_CSV:
    'https://digiqc-dev.s3.ap-south-1.amazonaws.com/csv/upload-agency-csv-sample.xlsx',
  STAGE_CSV:
    'https://digiqc-dev.s3.ap-south-1.amazonaws.com/csv/stage-csv-sample.xlsx',
  USER_CSV:
    'https://digiqc-dev.s3.ap-south-1.amazonaws.com/csv/user-csv-sample.xlsx'
};

export const CROPPER_WIDTH = 133;
export const CROPPER_HEIGHT = 40;

/* Date and time */

export const DEFAULTDATEFORMAT = 'DD/MM/YYYY';
export const DEFAULTDATETIMEFORMAT = 'DD/MM/YYYY h:mm a';
export const DATETIMEWITHDIVIDE = 'DD/MM/YYYY | h:mm a';
export const DATETIMEWITHBRACKET = 'DD/MM/YYYY (h:mm a)';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};

export const QCSTATUS_COLOR = {
  PASS: 'green',
  FAIL: 'red',
  SKIP: '#ffc107',
  REDO: 'red',
  APPROVAL_PENDING: '#ffc107',
  APPROVED: 'green'
};
export const QCSTATUS = {
  PASS: 'Pass',
  FAIL: 'Fail',
  SKIP: 'Skip',
  NOT_RECORDED: 'Not_Recorded'
};

export const UOMS = {
  'CU.M': 'Cubic Meter',
  'CU.FT': 'Cubic Feet',
  'SQ.M': 'Square Meter',
  'SQ.FT': 'Square Feet',
  RMT: 'Running Meter',
  RFT: 'Running Feet',
  KG: 'Kilogram',
  NOS: 'Number',
  JOB: 'Job',
  LUMPSUM: 'Lump Sum',
  TONNE: 'Tonne',
  BRASS: 'Brass',
  YARD: 'Yard',
  SQYARD: 'Square Yard',
  MM: 'Milimeter',
  'SQ.MM': 'Square Milimeter',
  QUINTAL: 'Quintal',
  BAG: 'Bag',
  LITER: 'Liter',
  MILILITER: 'Mililiter',
  DFT: 'Dry Film Thickness',
  INCH: 'Inch',
  'SQ.INCH': 'Square Inch',
  FOOT: 'Foot',
  ACRE: 'Acre',
  HECTARE: 'Hectare',
  MINUTES: 'Minutes',
  SECOND: 'Seconds',
  HOURS: 'Hours',
  MANHOURS: 'Man Hours',
  MANDAYS: 'Man Days',
  'DEGREE CELSIUS': 'Degree Celcius'
};
export const EQC_STAGE_STATUS_KEYS = {
  PASS: 'PASS',
  REDO: 'REDO',
  NOT_DONE: 'NOT_DONE',
  APPROVED: 'APPROVED',
  FAIL: 'FAIL',
  APPROVAL_PENDING: 'APPROVAL_PENDING'
};
export const STAGE_STATUS_TEXT_CLASS = {
  PASS: 'text-success',
  REDO: 'text-danger',
  NOT_DONE: 'text-warning',
  APPROVED: 'text-success',
  FAIL: 'text-danger',
  APPROVAL_PENDING: 'text-warning'
};
export const STAGE_STATUS_LABEL = {
  PASS: 'Pass',
  FAIL: 'Fail',
  APPROVAL_PENDING: 'Approval pending',
  APPROVED: 'Approved',
  REDO: 'Redo'
};

export const EQC_STATUS_CLASSNAME = {
  NOT_STARTED: 'inactive-tag',
  IN_PROGRESS: 'yellow-tag',
  PASSED: 'active-tag',
  TERMINATE: 'red-tag'
};
export const STAGE_STATUS_CLASSNAME = {
  PASS: 'active-tag',
  FAIL: 'red-tag',
  APPROVAL_PENDING: 'yellow-tag',
  APPROVED: 'active-tag',
  REDO: 'inactive-tag',
  NOT_RECORDED: 'inactive-tag'
};
export const EQC_STATUS_LABEL = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  PASSED: 'Passed',
  TERMINATE: 'Terminate'
};
export const PIE_CHART_COLORS = [
  'rgba(242, 95, 92, 1)',
  'rgba(80, 81, 79, 1)',
  'rgba(255, 224, 102, 1)',
  'rgba(36, 123, 160, 1)',
  'rgba(112, 193, 179, 1)',
  'rgba(11, 19, 43, 1)',
  'rgba(28, 37, 65, 1)',
  'rgba(58, 80, 107, 1)',
  'rgba(91, 192, 190, 1)',
  'rgba(111, 255, 233, 1)'
];

export const DASHBOARD_API_ROUTES = {
  AGENCY_WISE_ANALYSIS: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/agency-wise-analysis`,
  PROJECT_WISE_ANALYSIS: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/project-wise-analysis`,
  USER_WISE_ANALYSIS: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/user-wise-analysis`,
  AGENCY_WISE_ANALYSIS_GRAPH: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/agency-wise-analysis-graph`,
  PROJECT_WISE_ANALYSIS_GRAPH: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/project-wise-analysis-graph`,
  USER_WISE_ANALYSIS_GRAPH: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/user-wise-analysis-graph`,
  PROJECT_LIST: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/project-list-wise-summary`,
  USER_LIST: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/user-list-wise-summary`,
  AGENCY_LIST: `${process.env.REACT_APP_REST_SERVER_URL}/api/v1/dashboard/agency-list-wise-summary`
};

export const BUG_REPORT_FORM_LINK =
  'https://forms.clickup.com/f/jjdr-9475/PWWRFOUEDDB46VVIZ0';

export const HELP_LINK = 'https://digiqc.tawk.help/';

export const SUBSCRIPTION_STATUS = {
  OVER_DUE: 'OVER_DUE'
};
export const WARNINGS = {
  APPROVER_CHANGED:
    'All the pending approval will be sent as a link to new approver.',
  APPROVER_REMOVED:
    "If you unselect Approval Required then any approval pending EQC link won't be sent to anybody and can remain pending.",
  PROJECT_ADMIN_AUTO_ASSIGNED:
    'All project admins will be auto assigned to this EQC type',
  REPORTS_FOR_PROJECT_ADMIN:
    'You will be able to download reports for the project in which you are project admin.',
  REPORTS_FOR_SYSTEM_ADMIN:
    'You will be able to download reports for the project in which you are project admin or you should be System admin to download all project reports.'
};

export const REPORT_NOTES = [
  'EQC QC report link can be generated for maximum 1 month duration.',
  'Max of 5 links can be generated at a time.',
  'Link will be generated from 08:00 pm onward and will be mail to your registered email id once link is generated.',
  'If generated link has more than 50 reports then multiple link in the batch of 50 EQC will be sent in the single mail.'
];

export const GA_EVENT = {
  ADD_AUDITOR_COMMENT: 'add_auditor_comment',
  ADD_GLOBAL_AGENCY: 'add_global_agency',
  ADD_GLOBAL_AGENCY_CONTACT: 'add_global_agency_contact',
  ADD_GLOBAL_EQC_TYPE: 'add_global_eqc_type',
  ADD_GLOBAL_EQC_TYPE_STAGE: 'add_global_eqc_type_stage',
  ADD_GLOBAL_EQC_TYPE_STAGE_ITEM: 'add_global_eqc_type_stage_item',
  ADD_GLOBAL_USER: 'add_global_user',
  ADD_NEW_PROJECT: 'add_new_project',
  ADD_PROJECT_AGENCY: 'add_project_agency',
  ADD_PROJECT_EQC_TYPE: 'add_project_eqc_type',
  ADD_PROJECT_EQC_TYPE_STAGE: 'add_project_eqc_type_stage',
  ADD_PROJECT_EQC_TYPE_STAGE_ITEM: 'add_project_eqc_type_stage_item',
  ADD_PROJECT_USER: 'add_project_user',
  APPROVE_EQC: 'approve_eqc',
  BUG_REPORT: 'bug_report',
  CHANGE_GLOBAL_USER_STATUS: 'change_global_user_status',
  CLICK_PREVIOUS_BUTTON: 'click_previous_button',
  DELETE_GLOBAL_AGENCY: 'delete_global_agency',
  DELETE_GLOBAL_AGENCY_CONTACT: 'delete_global_agency_contact',
  DELETE_GLOBAL_EQC_TYPE: 'delete_global_eqc_type',
  DELETE_GLOBAL_EQC_TYPE_STAGE: 'delete_global_eqc_type_stage',
  DELETE_GLOBAL_EQC_TYPE_STAGE_ITEM: 'delete_global_eqc_type_stage_item',
  DELETE_PROJECT_AGENCY: 'delete_project_agency',
  DELETE_PROJECT_EQC_TYPE_STAGE: 'delete_project_eqc_type_stage',
  DELETE_PROJECT_EQC_TYPE_STAGE_ITEM: 'delete_project_eqc_type_stage_item',
  DELETE_PROJECT_USER: 'delete_project_user',
  DOWNLOAD_AUDIO: 'download_audio',
  DOWNLOAD_INDIVIDUAL_REPORT: 'download_individual_report',
  DOWNLOAD_INSTRUCTION_FINAL_REPORT: 'download_instruction_final_report',
  DOWNLOAD_INSTRUCTION_REC_REPORT: 'download_instruction_rec_report',
  DOWNLOAD_PROJECT_EQC_DETAILED_REPORT: 'download_project_eqc_detailed_report',
  DOWNLOAD_PROJECT_EQC_FINAL_REPORT: 'download_project_eqc_final_report',
  DOWNLOAD_PROJECT_EQC_REPORT: 'download_project_eqc_report',
  DOWNLOAD_PROJECT_REPORT: 'download_project_report',
  DUPLICATE_GLOBAL_EQC_TYPE: 'duplicate_global_eqc_type',
  DUPLICATE_PROJECT_EQC_TYPE: 'duplicate_project_eqc_type',
  EDIT_GLOBAL_AGENCY: 'edit_global_agency',
  EDIT_GLOBAL_AGENCY_CONTACT: 'edit_global_agency_contact',
  EDIT_GLOBAL_EQC_TYPE: 'edit_global_eqc_type',
  EDIT_GLOBAL_EQC_TYPE_STAGE: 'edit_global_eqc_type_stage',
  EDIT_GLOBAL_EQC_TYPE_STAGE_ITEM: 'edit_global_eqc_type_stage_item',
  EDIT_GLOBAL_USER: 'edit_global_user',
  EDIT_NEW_PROJECT: 'edit_new_project',
  EDIT_PROJECT: 'edit_project',
  EDIT_PROJECT_AGENCY: 'edit_project_agency',
  EDIT_PROJECT_EQC_TYPE: 'edit_project_eqc_type',
  EDIT_PROJECT_EQC_TYPE_STAGE: 'edit_project_eqc_type_stage',
  EDIT_PROJECT_EQC_TYPE_STAGE_ITEM: 'edit_project_eqc_type_stage_item',
  EDIT_PROJECT_USER: 'edit_project_user',
  EDIT_TENANT_LOGO: 'edit_tenant_logo',
  EDIT_USER_LOGO: 'edit_user_logo',
  EDIT_USER_PASSWORD: 'edit_user_password',
  EDIT_USER_PROFILE: 'edit_user_profile',
  EXPORT_AGENCIES: 'export_agencies',
  EXPORT_GLOBAL_EQC_TYPE: 'export_global_eqc_type',
  EXPORT_GLOBAL_USER: 'export_global_user',
  EXPORT_PROJECT_EQC_TYPE: 'export_project_eqc_type',
  FORGOT_PASSWORD: 'forgot_password',
  GENERATE_PROJECT_REPORT: 'generate_project_report',
  HELP_FORM: 'help_form',
  IMPORTED_GLOBAL_AGENCY: 'imported_global_agency',
  IMPORTED_GLOBAL_EQC_TYPE_STAGE: 'imported_global_eqc_type_stage',
  IMPORTED_GLOBAL_USER: 'imported_global_user',
  ONBOARD_PROJECT_AGENCIES: 'onboard_project_agencies',
  ONBOARD_PROJECT_EQC_TYPE: 'onboard_project_eqc_type',
  ONBOARD_PROJECT_USER: 'onboard_project_user',
  OPENED_IMAGE_VIEWER: 'opened_image_viewer',
  PUBLISH_PROJECT_EQC_TYPE: 'publish_project_eqc_type',
  RENAME_PROJECT_EQC: 'rename_project_eqc',
  RENAME_PROJECT_INSTRUCTION: 'rename_project_instruction',
  REORDER_GLOBAL_EQC_TYPE_STAGE: 'reorder_global_eqc_type_stage',
  REORDER_GLOBAL_EQC_TYPE_STAGE_ITEM: 'reorder_global_eqc_type_stage_item',
  REORDER_PROJECT_EQC_TYPE_STAGE: 'reorder_project_eqc_type_stage',
  REORDER_PROJECT_EQC_TYPE_STAGE_ITEM: 'reorder_project_eqc_type_stage_item',
  RESET_PASSWORD: 'reset_password',
  REVISIT_APPROVER_COMMENT: 'revisit_approver_comment',
  SET_PASSWORD: 'set_password',
  UNPUBLISH_PROJECT_EQC_TYPE: 'unpublish_project_eqc_type',
  VIEW_AUDITOR_COMMENT_MODAL: 'view_auditor_comment_modal',
  VIEW_GENERAL_COMMENT_MODAL: 'view_general_comment_modal',
  VIEW_GENERAL_REMARK_MODAL: 'view_general_remark_modal',
  VIEW_LOCATION: 'view_location',
  VIEW_PROJECT_EQC_RENAME_MODAL: 'view_project_eqc_rename_modal',
  VIEW_PROJECT_INSTRUCTION_RENAME_MODAL: 'view_project_instruction_rename_modal'
};

export const GA_LABEL = {
  ADD_AUDITOR_COMMENT: 'Add Auditor Comment',
  ADD_GLOBAL_AGENCY: 'Created New Global Agency',
  ADD_GLOBAL_AGENCY_CONTACT: 'Add Global Agency Contact',
  ADD_GLOBAL_EQC_TYPE: 'Created New Eqc Type',
  ADD_GLOBAL_EQC_TYPE_STAGE: 'Add Global Eqc Type Stage',
  ADD_GLOBAL_EQC_TYPE_STAGE_ITEM: 'Added Global Eqc Type Stage Item',
  ADD_GLOBAL_USER: 'Created New Global User',
  ADD_NEW_PROJECT: 'Created New project',
  ADD_PROJECT_AGENCY: 'Add project agency',
  ADD_PROJECT_EQC_TYPE: 'Add Project Eqc Type',
  ADD_PROJECT_EQC_TYPE_STAGE: 'Add Project Eqc Type Stage',
  ADD_PROJECT_EQC_TYPE_STAGE_ITEM: 'Add Project Eqc Type Stage Item',
  ADD_PROJECT_USER: 'Add Project User',
  APPROVE_EQC: 'Approved / Redo EQC',
  BUG_REPORT: 'Opened Bug Report Form',
  CHANGE_GLOBAL_USER_STATUS: 'Change Global Users Status',
  DELETE_GLOBAL_AGENCY: 'Deleted Global Agency',
  DELETE_GLOBAL_AGENCY_CONTACT: 'Delete Global Agency Contact',
  DELETE_GLOBAL_EQC_TYPE: 'Deleted Global Eqc Type',
  DELETE_GLOBAL_EQC_TYPE_STAGE: 'Delete Global Eqc Type Stage',
  DELETE_GLOBAL_EQC_TYPE_STAGE_ITEM: 'Delete Global Eqc Type Stage Item',
  DELETE_PROJECT_AGENCY: 'Delete Project Agency',
  DELETE_PROJECT_EQC_TYPE_STAGE: 'Delete Project Eqc Type Stage',
  DELETE_PROJECT_EQC_TYPE_STAGE_ITEM: 'Delete Project Eqc Type Stage Item',
  DELETE_PROJECT_USER: 'Delete Project User',
  DOWNLOAD_AUDIO: 'Download Audio',
  DOWNLOAD_INDIVIDUAL_REPORT: 'Download Individual Report',
  DOWNLOAD_INSTRUCTION_FINAL_REPORT: 'Download Instruction Final Report',
  DOWNLOAD_INSTRUCTION_REC_REPORT: 'Download Instruction Recommendation Report',
  DOWNLOAD_PROJECT_EQC_DETAILED_REPORT: 'Download EQC Detailed Report',
  DOWNLOAD_PROJECT_EQC_FINAL_REPORT: 'Download EQC Final Report',
  DOWNLOAD_PROJECT_EQC_REPORT: 'Download project eqc report',
  DOWNLOAD_PROJECT_REPORT: 'Download Individual Report',
  DUPLICATE_GLOBAL_EQC_TYPE: 'Duplicate Global Eqc Type',
  DUPLICATE_PROJECT_EQC_TYPE: 'Duplicate Project Eqc Type',
  EDIT_GLOBAL_AGENCY: 'Edited Global Agency',
  EDIT_GLOBAL_AGENCY_CONTACT: 'Edit Global Agency Contact',
  EDIT_GLOBAL_EQC_TYPE: 'Edited Global Eqc Type',
  EDIT_GLOBAL_EQC_TYPE_STAGE: 'Edit Global Eqc Type Stage',
  EDIT_GLOBAL_EQC_TYPE_STAGE_ITEM: 'Edit Global Eqc Type Stage Item',
  EDIT_GLOBAL_USER: 'Edit Global User',
  EDIT_NEW_PROJECT: 'Edit details of new project',
  EDIT_PROJECT: 'Edit project',
  EDIT_PROJECT_AGENCY: 'Edit project agency',
  EDIT_PROJECT_EQC_TYPE: 'Edit Project Eqc Type',
  EDIT_PROJECT_EQC_TYPE_STAGE: 'Edit Project Eqc Type Stage',
  EDIT_PROJECT_EQC_TYPE_STAGE_ITEM: 'Edit Project Eqc Type Stage Item',
  EDIT_PROJECT_USER: 'Edit Project User',
  EDIT_TENANT_LOGO: 'Updated Tenant Logo',
  EDIT_USER_LOGO: 'Updated User Profile Logo',
  EDIT_USER_PASSWORD: 'Edited User Password',
  EDIT_USER_PROFILE: 'Edit User Profile',
  EXPORT_AGENCIES: 'Export Global Agency',
  EXPORT_GLOBAL_EQC_TYPE: 'Exported Global Eqc Type',
  EXPORT_GLOBAL_USER: 'Exported Global Users Data',
  EXPORT_PROJECT_EQC_TYPE: 'Export Project Eqc Type',
  FORGOT_PASSWORD: 'Clicked Forgot Password',
  GENERATE_PROJECT_REPORT: 'Requested To Generate Project Report',
  HELP_FORM: 'Opened Help Form',
  IMPORTED_GLOBAL_AGENCY: 'Imported Agency Data',
  IMPORTED_GLOBAL_EQC_TYPE_STAGE: 'Imported Eqc Type Data',
  IMPORTED_GLOBAL_USER: 'Imported User Data',
  ONBOARD_PROJECT_AGENCIES: 'Onboard agencies in new project',
  ONBOARD_PROJECT_EQC_TYPE: 'Onboard project eqc types',
  ONBOARD_PROJECT_USER: 'Onboard project users',
  OPENED_IMAGE_VIEWER: 'Opened Image Viewer',
  PUBLISH_PROJECT_EQC_TYPE: 'Publish Project Eqc Type',
  RENAME_PROJECT_EQC: 'Rename Project EQC',
  RENAME_PROJECT_INSTRUCTION: 'Rename Project Issues',
  REORDER_GLOBAL_EQC_TYPE_STAGE: 'Reorder Global Eqc Type Stage',
  REORDER_GLOBAL_EQC_TYPE_STAGE_ITEM: 'Reorder Global Eqc Type Stage Item',
  REORDER_PROJECT_EQC_TYPE_STAGE: 'Reorder Project Eqc Type Stage',
  REORDER_PROJECT_EQC_TYPE_STAGE_ITEM: 'Reorder Project Eqc Type Stage Item',
  RESET_PASSWORD: 'Reset Password',
  REVISIT_APPROVER_COMMENT: 'Clicked Revisit Comments Button',
  SET_PASSWORD: 'Set Password',
  UNPUBLISH_PROJECT_EQC_TYPE: 'Unpublish Project Eqc Type',
  VIEW_AUDITOR_COMMENT_MODAL: 'View Auditor Comments Modal',
  VIEW_GENERAL_COMMENT_MODAL: 'View General Comment Modal',
  VIEW_GENERAL_REMARK_MODAL: 'View General Remark Modal',
  VIEW_LOCATION: 'Clicked on view location link',
  VIEW_PROJECT_EQC_RENAME_MODAL: 'View Project Eqc Rename Modal',
  VIEW_PROJECT_INSTRUCTION_RENAME_MODAL: 'View Project Instruction Rename Modal'
};

export const INS_STATUS_KEYS = {
  NOTICE: 'NOTICE',
  RAISED: 'RAISED',
  RESPONDED: 'RESPONDED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED'
};

export const INS_STATUS_CLASSNAME = {
  RAISED: 'yellow-tag',
  NOTICE: 'active-tag',
  RESPONDED: 'inactive-tag',
  REJECTED: 'red-tag',
  CLOSED: 'active-tag'
};

export const INS_STATUS_LABEL = {
  RAISED: 'Raised',
  NOTICE: 'Notice',
  RESPONDED: 'Responded',
  REJECTED: 'Rejected',
  CLOSED: 'Closed'
};

export const PROJECT_EQC_TYPE_STATUS = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  ARCHIVED: 'ARCHIVED'
};
