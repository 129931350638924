import { useMutation } from '@apollo/client';
import { Button, Form, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../common/utils';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import { ADD_PROJECT_EQC_TYPE } from '../../../../graphql/Mutation';
import { GET_PROJECT_EQC_TYPE_DROPDOWN_LIST } from '../../../../graphql/Queries';

const AddEqcTypeModal = (props) => {
  const { showModal, setShowModal, refetchEqcTypeData } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { required } = formValidatorRules;
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };
  const [addProjectEqcType] = useMutation(ADD_PROJECT_EQC_TYPE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.ADD_PROJECT_EQC_TYPE, {
        label: GA_LABEL.ADD_PROJECT_EQC_TYPE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      form.resetFields();
      setShowModal(false);
      refetchEqcTypeData();
    }
  });
  const onFormSubmit = async (formValues) => {
    try {
      await addProjectEqcType({
        variables: {
          data: {
            ...formValues,
            selectedAll: isSelectedAll,
            projectId: Number(projectId)
          }
        }
      });
    } catch (error) {
      return error;
    }
  };
  const handleDeselect = () => {
    setIsSelectedAll(false);
    form.setFieldsValue({ eqcTypeId: [] });
  };
  return (
    <>
      <Modal
        maskClosable={false}
        centered
        form={form}
        visible={showModal}
        onCancel={handleCancel}
        closable={false}
        footer={null}
      >
        <h2 className="mb-15">Add Eqc Type</h2>
        <Form layout="vertical" form={form} onFinish={onFormSubmit}>
          <Form.Item
            name="eqcTypeId"
            label={
              <div className="d-flex justify-between width-percent-100">
                <div>EQC Type</div>
                {isSelectedAll && (
                  <div
                    className="text-primary pointer"
                    role="button"
                    tabIndex="0"
                    onClick={handleDeselect}
                  >
                    Deselect All
                  </div>
                )}
              </div>
            }
            rules={[required]}
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectedAll(true);
                return ['all'];
              }
              return value;
            }}
          >
            <CommonDropdown
              mode="multiple"
              showSearch
              optionFilterProp="children"
              disabled={isSelectedAll}
              isSelectedAll={isSelectedAll}
              allowClear
              query={GET_PROJECT_EQC_TYPE_DROPDOWN_LIST}
              responsePath="projectEqcTypeDropdownList.data"
              valuePath="id"
              labelPath="name"
              optionKey="eqc-type"
              fetchPolicy="network-only"
              variables={{ filter: { projectId } }}
              hasSelectAll
            />
          </Form.Item>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEqcTypeModal;
