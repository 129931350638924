import { Card, Descriptions, Tag } from 'antd';
import clsx from 'clsx';
import { nth } from 'lodash';
import React from 'react';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  EQC_STATUS,
  EQC_STATUS_KEYS
} from '../../common/constants';
import { titleCase } from '../../common/utils';

const EqcSummary = ({ data }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const eqcStatus = (status) => {
    if (status === EQC_STATUS_KEYS.PASSED) {
      return <Tag className="active-tag">{titleCase(EQC_STATUS.PASSED)}</Tag>;
    }
    if (status === EQC_STATUS_KEYS.IN_PROGRESS) {
      return (
        <Tag className="yellow-tag">{titleCase(EQC_STATUS.IN_PROGRESS)}</Tag>
      );
    }
    return <Tag color="red">{titleCase(EQC_STATUS.TERMINATE)}</Tag>;
  };

  return (
    <Card className="eqc-summary">
      <div className="d-flex align-center justify-between mb-10">
        <h2>Eqc Summary</h2>
      </div>
      <div>
        <Descriptions column={isDesktopViewport ? 6 : 2} layout="vertical">
          <Descriptions.Item
            label="Name"
            className={clsx(!isDesktopViewport && 'common-item')}
          >
            {data?.getEqcStageHistoryWithoutAuth?.eqc?.eqcName}
          </Descriptions.Item>
          <Descriptions.Item label="Eqc Type" span={2}>
            {data?.getEqcStageHistoryWithoutAuth?.eqc?.projectEqcTypeName}
          </Descriptions.Item>
          <Descriptions.Item
            label="Uom"
            className={clsx(!isDesktopViewport && 'common-item')}
          >
            {data?.getEqcStageHistoryWithoutAuth?.eqc?.uom}
          </Descriptions.Item>
          <Descriptions.Item label="Eqc Status">
            {eqcStatus(data?.getEqcStageHistoryWithoutAuth?.eqc?.status)}
          </Descriptions.Item>
          <Descriptions.Item label="Revision">
            {
              nth(data?.getEqcStageHistoryWithoutAuth?.eqcApprovalLogs, 0)
                ?.revision
            }
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Card>
  );
};

export default EqcSummary;
