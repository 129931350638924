import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { findIndex, map, sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DragIcon,
  EditButton,
  RightIcon,
  WrongIcon
} from '../../../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  PROJECT_EQC_TYPE_STATUS
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import CanPerform from '../../../../../../../../components/CanPerform';
import CommonCard from '../../../../../../../../components/CommonCard';
import CommonTable from '../../../../../../../../components/CommonTable';
import InfiniteScrollHandler from '../../../../../../../../components/InfiniteScrollHandler';
import Portal from '../../../../../../../../components/Portal';
import {
  DELETE_PROJECT_STAGE_ITEM,
  REORDER_PROJECT_STAGE_ITEM
} from '../../../../../../graphql/Mutation';
import { GET_PROJECT_STAGE_ITEM_LIST } from '../../../../../../graphql/Queries';
import DeleteModalStage from '../DeleteModalStage';
import AddEditModal from './AddEditModal';

const StageItemTable = ({ stageId, eqcTypeData }) => {
  const initialStageItemFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'order', order: 'ASC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };

  const DragHandle = sortableHandle(() => (
    <DragIcon className="drag-icon" onClick={(e) => e.preventDefault()} />
  ));
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showModal, setShowModal] = useState(false);
  const [stageItemData, setStageItemData] = useState();
  const [deleteStageItemModal, setDeleteStageItemModal] = useState(false);
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [mutationId, setMutationId] = useState('');
  const [stageItemFilter, setStageItemFilter] = useState({
    ...initialStageItemFilter,
    projectEqcTypeStageId: parseInt(stageId, 10)
  });
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [stageItemListData, setStageItemListData] = useState();
  const { projectId } = useParams();
  const [fetchStageItemData, { loading }] = useLazyQuery(
    GET_PROJECT_STAGE_ITEM_LIST,
    {
      variables: { filter: stageItemFilter },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.projectEqcTypeStageItemList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.projectEqcTypeStageItemList?.total
        };
        if (scrollFlag) {
          const datalist = [...stageItemListData, ...data];
          setStageItemListData(sortBy(datalist, ['order']));
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setStageItemListData(sortBy(datalist, ['order']));
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );
  const handleRefetchAfterDelete = () => {
    const newSkip =
      stageItemListData?.length === 1
        ? Math.max(0, stageItemFilter?.skip - paginationProp?.pageSize)
        : stageItemFilter?.skip;
    setStageItemFilter({
      ...stageItemFilter,
      skip: newSkip
    });
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip: newSkip
        }
      }
    });
  };
  const [deleteStageItem] = useMutation(DELETE_PROJECT_STAGE_ITEM, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_PROJECT_EQC_TYPE_STAGE_ITEM, {
        label: GA_LABEL.DELETE_PROJECT_EQC_TYPE_STAGE_ITEM,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_stage_id: stageId,
        stage_item_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setDeleteStageItemModal(false);
      handleRefetchAfterDelete();
    }
  });

  const [reorderStageItem, { loading: reorderLoading }] = useMutation(
    REORDER_PROJECT_STAGE_ITEM,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.REORDER_PROJECT_EQC_TYPE_STAGE_ITEM, {
          label: GA_LABEL.REORDER_PROJECT_EQC_TYPE_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        fetchStageItemData({ variables: { filter: stageItemFilter } });
      }
    }
  );

  useEffect(() => {
    if (stageId) {
      setStageItemFilter({
        ...initialStageItemFilter,
        projectEqcTypeStageId: parseInt(stageId, 10)
      });
      setPaginationProp(initialPaginationValue);
      fetchStageItemData({
        variables: {
          filter: {
            ...initialStageItemFilter,
            projectEqcTypeStageId: parseInt(stageId, 10)
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageId]);

  const refetchStageDetails = () => {
    setStageItemFilter({
      ...initialStageItemFilter,
      projectEqcTypeStageId: parseInt(stageId, 10)
    });
    setPaginationProp(initialPaginationValue);
    fetchStageItemData({
      variables: {
        filter: {
          ...initialStageItemFilter,
          projectEqcTypeStageId: parseInt(stageId, 10)
        }
      }
    });
  };

  const handleAddEdit = (record) => {
    if (record) {
      setStageItemData(record);
    } else {
      setStageItemData();
    }
    setShowModal(true);
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setStageItemFilter({
      ...stageItemFilter,
      skip: skip,
      limit: pagination.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip,
          limit: pagination.pageSize
        }
      }
    });
  };
  const getActionButtons = (record) => {
    return (
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC_TYPES}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        <div className={clsx(isDesktopViewport && 'd-flex')}>
          <Tooltip title="Edit">
            <Button
              shape="round"
              icon={<EditButton />}
              className="edit-button pointer b-0"
              disabled={
                eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.PUBLISHED ||
                !eqcTypeData?.project?.isActive
              }
              onClick={() => {
                handleAddEdit(record);
              }}
            />
          </Tooltip>
          {(!isDesktopViewport
            ? eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED &&
              eqcTypeData?.project?.isActive
            : true) && (
            <Tooltip title="Delete">
              <Button
                className="delete-button pointer b-0"
                shape="round"
                icon={<DeleteButton />}
                disabled={
                  eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.PUBLISHED ||
                  !eqcTypeData?.project?.isActive
                }
                onClick={() => {
                  setTitle('Stage Item');
                  setName(record?.title);
                  setMutationId(record?.id);
                  setDeleteStageItemModal(true);
                }}
              />
            </Tooltip>
          )}
        </div>
      </CanPerform>
    );
  };
  const columns = [
    {
      width: '5%',
      className: 'drag-visible',
      render: () =>
        eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED &&
        eqcTypeData?.project?.isActive && (
          <Tooltip title="Change order">
            <div>
              <DragHandle />
            </div>
          </Tooltip>
        )
    },
    {
      title: 'ID',
      key: 'id',
      render: (text, record, index) => {
        return <div>{stageItemFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'QC CHECKLIST POINT',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'INPUT',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'PHOTOS',
      key: 'photo',
      render: (record) => {
        if (record?.photo === true) {
          return <RightIcon />;
        }
        return <WrongIcon />;
      }
    },
    {
      title: 'REMARKS',
      key: 'remark',
      render: (record) => {
        if (record?.remark === true) {
          return <RightIcon />;
        }
        return <WrongIcon />;
      }
    },
    {
      key: 'action',
      render: (record) => getActionButtons(record)
    }
  ];

  const SortableItem = sortableElement((props) =>
    isDesktopViewport ? (
      <tr {...props} />
    ) : (
      <div {...props} className="ant-card ant-card-bordered" />
    )
  );
  const SortableContainer = sortableContainer((props) =>
    isDesktopViewport ? <tbody {...props} /> : <div {...props} />
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      reorderStageItem({
        variables: {
          data: {
            stageItemIds: [
              stageItemListData[oldIndex]?.id,
              stageItemListData[newIndex]?.id
            ],
            projectEqcTypeStageId: parseInt(stageId, 10)
          }
        }
      });
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      axis="y"
      lockAxis="y"
      lockOffset={['0%', '100%']}
      // eslint-disable-next-line no-undef
      helperContainer={document.getElementById('sortable-container-div')}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = findIndex(
      stageItemListData,
      (item) => item?.id === restProps['data-row-key']
    );
    if (index !== -1) return <SortableItem index={index} {...restProps} />;
    return null;
  };
  const handleRefetch = () => {
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip: stageItemListData?.length,
          limit: DEFAULT_PAGE_SIZE
        }
      }
    });
  };
  return (
    <>
      {showModal && (
        <AddEditModal
          showModal={showModal}
          setShowModal={setShowModal}
          stageData={stageItemData}
          isUpdate={!!stageItemData}
          setStageData={setStageItemData}
          refetchStageDataWithInitialValues={refetchStageDetails}
          stageId={stageId}
          isDisabled={
            eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.PUBLISHED ||
            !eqcTypeData?.project?.isActive
          }
        />
      )}
      <DeleteModalStage
        showModal={deleteStageItemModal}
        setShowModal={setDeleteStageItemModal}
        title={title}
        name={name}
        deleteStage={deleteStageItem}
        mutationId={mutationId}
        setMutationId={setMutationId}
      />
      {(!isDesktopViewport
        ? eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED &&
          eqcTypeData?.project?.isActive
        : true) && (
        <CanPerform
          action={ALLOWED_ACTION_KEYS.ADD_PROJECT_STAGE_ITEM}
          type={ALLOWED_ACTION_TYPE.BOTH}
        >
          <Portal portalId="stageitem-add-btn">
            <Button
              shape="round"
              type="primary"
              id="add-btn"
              icon={<AddButton />}
              className="add-button"
              disabled={
                eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.PUBLISHED ||
                eqcTypeData?.status === PROJECT_EQC_TYPE_STATUS?.ARCHIVED ||
                !eqcTypeData?.project?.isActive
              }
              onClick={() => {
                handleAddEdit();
              }}
            >
              {isDesktopViewport && 'Add Item'}
            </Button>
          </Portal>
        </CanPerform>
      )}
      {isDesktopViewport ? (
        <CommonTable
          columns={columns}
          data={stageItemListData || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey="id"
          loading={loading || reorderLoading}
          components={{
            body: stageItemListData?.length > 0 && {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
        />
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading || reorderLoading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          wrapperClassName="stage-item-scroll-wrapper"
          dataLength={stageItemListData?.length}
          skeletonRows={columns?.length - 3}
        >
          <div id="sortable-container-div" />
          <DraggableContainer>
            {map(stageItemListData, (item, index) => {
              return (
                <SortableItem index={index}>
                  <CommonCard key={item?.id} className="stage-card">
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">
                        {eqcTypeData?.status ===
                          PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED &&
                          eqcTypeData?.project?.isActive && <DragHandle />}
                      </div>
                      <div>
                        <div className="card-header fw-medium">
                          <Row wrap={false}>
                            <Col flex="auto">
                              <span className="mr-5">{index + 1}.</span>
                              {item?.title}
                            </Col>
                          </Row>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15">
                            <span className="fw-medium mr-5">Input:</span>
                            {item?.type}
                          </div>
                          <div className="mb-15 d-flex align-center">
                            <span className="fw-medium mr-5">Photos:</span>
                            {item?.photo === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon />
                            )}
                          </div>
                          <div className=" d-flex align-center">
                            <span className="fw-medium mr-5">Remarks:</span>
                            {item?.remark === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon />
                            )}
                          </div>
                        </div>
                      </div>
                      <span className="position-absolute stage-item-action-btn">
                        {getActionButtons(item)}
                      </span>
                    </div>
                  </CommonCard>
                </SortableItem>
              );
            })}
          </DraggableContainer>
        </InfiniteScrollHandler>
      )}
    </>
  );
};

export default StageItemTable;
