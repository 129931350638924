import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Alert } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import App from './app/App';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import { initGA } from './common/trackEvents';
import { useOnlineStatus } from './common/useNetworkDetect';
import LoaderComponent from './components/LoaderComponent';
import history from './historyData';
import ApproverLink from './modules/approver/ApproverLink';
import ApproverSuccess from './modules/approver/ApproverSuccess';
import ForgotPassword from './modules/auth/ForgotPassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import RefreshToken from './modules/auth/RefreshToken';
import ResetPassword from './modules/auth/ResetPassword';
import SetPassword from './modules/auth/SetPassword';
import ReportImageViewer from './modules/reportImageViewer/ReportImageViewer';
import { GET_PROFILE } from './modules/users/graphql/Queries';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  const { initializeAuth, getToken } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const path = history?.location?.pathname;
  const idToken = getToken();
  const isOnline = useOnlineStatus();
  const [getUserProfile] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth('', '', res?.getLoggedInUser);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });
  useEffect(() => {
    if ((path === ROUTES.LOGOUT && idToken) || idToken) {
      setLoading(true);
      getUserProfile();
    } else {
      setLoading(false);
    }
    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
   }, [idToken]);

  useEffect(() => {
    initGA(process.env.REACT_APP_GA_CONFIG);
    // eslint-disable-next-line no-undef
  }, []);
  if (loading) return <LoaderComponent />;

  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  return (
    <>
      {!isOnline && (
        <div className="alert-div">
          <Alert
            message="Please check your network connection and try again."
            type="error"
            banner
          />
        </div>
      )}
      <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
        <Router history={history}>
          <Switch>
            <PublicRoute
              exact
              path={ROUTES.FORGOT}
              component={ForgotPassword}
            />
            <Route path={ROUTES.SET_PASSWORD} component={SetPassword} />
            <PublicRoute path={ROUTES.RESET} component={ResetPassword} />
            <PublicRoute exact path={ROUTES.LOGIN} component={Login} />
            <Route exact path={ROUTES.APPROVER_LINK} component={ApproverLink} />
            <Route
              exact
              path={ROUTES.APPROVER_SUCCESS}
              component={ApproverSuccess}
            />
            <Route
              exact
              path={ROUTES.REPORT_IMAGE_VIEWER}
              component={ReportImageViewer}
            />
            <PrivateRoute exact path={ROUTES.LOGOUT} component={Logout} />
            <PrivateRoute
              exact
              path={ROUTES.AUTHENTICATION}
              component={RefreshToken}
            />
            <PrivateRoute path={ROUTES.MAIN} component={App} />
          </Switch>
        </Router>
      </Sentry.ErrorBoundary>
    </>
  );
};
export default Routes;
