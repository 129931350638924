import { Menu, Popover, Space } from 'antd';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import alternateProfile from '../../../assets/images/alternateProfile.png';
import { ROUTES } from '../../../common/constants';

const UserProfile = ({ userData }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (value) => {
    setVisible(value);
  };
  const userMenuOptions = (
    <Menu onClick={() => setVisible(false)}>
      <Menu.Item key={ROUTES.PROFILE}>
        <NavLink to={ROUTES.PROFILE}>Profile</NavLink>
      </Menu.Item>
      <Menu.Item key={ROUTES.CHANGE}>
        <NavLink to={ROUTES.CHANGE}>Change Password</NavLink>
      </Menu.Item>
      <Menu.Item key={ROUTES.LOGOUT}>
        <NavLink to={ROUTES.LOGOUT}>Logout</NavLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="gx-avatar-row pointer header-right">
      <div className="header-right-menu" />
      <Popover
        className="d-flex flex-row user-profile"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        getTooltipContainer={(triggerNode) => triggerNode.parentNode}
      >
        <Space direction="horizontal">
          <img
            src={userData?.getLoggedInUser?.profileImage || alternateProfile}
            className="profileImage gx-pointer mr-5"
            alt="Avatar"
          />
        </Space>
      </Popover>
    </div>
  );
};

export default UserProfile;
