import { Card, Col, Image, Row, Space } from 'antd';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { AudioIcon } from '../../../../../../../assets/svg';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  INS_STATUS_KEYS
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import AudioPlayerModal from '../../../../../../../components/AudioPlayerModal';
import EllipsisText from '../../../../../../../components/EllipsisText';

const CloseDetails = ({ instructionData }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');

  return (
    <div className="instruction-tab-details ">
      <Card className="instruction-tab-details-instruction-details ">
        <div className="d-flex align-center justify-between mb-15">
          <div className="d-flex">
            <h2>
              Closed Details
              {instructionData?.status !== INS_STATUS_KEYS.CLOSED &&
                ` (Pending)`}
            </h2>
          </div>
        </div>
        <div className="mobile-card">
          <Row
            gutter={isDesktopViewport ? [75, 40] : [40, 30]}
            className="fw-medium mb-20"
          >
            <Col span={isDesktopViewport ? 10 : 24}>
              <div className="text-secondary mb-12">Remarks</div>
              <EllipsisText text={instructionData?.closingRemark || 'N/A'} />
            </Col>
            <Col span={isDesktopViewport ? 7 : 24}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">Photos</div>
                <div>
                  {instructionData?.closingPhoto?.length > 0 ? (
                    <Image.PreviewGroup
                      preview={{
                        visible:
                          instructionData?.closingRemark === previewImageKey &&
                          isVisible,
                        onVisibleChange: (visible) => {
                          setIsVisible(visible);
                          if (visible) {
                            Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                              label: GA_LABEL.OPENED_IMAGE_VIEWER,
                              // eslint-disable-next-line no-undef
                              pathname: window?.location?.href
                            });
                          }
                        }
                      }}
                    >
                      <Space className="image-preview-wrapper">
                        {React.Children.map(
                          instructionData?.closingPhoto,
                          (photo) => (
                            <Image
                              src={photo}
                              alt="logo"
                              height="64px"
                              width="64px"
                              onClick={() =>
                                setPreviewImageKey(
                                  instructionData?.closingRemark
                                )
                              }
                            />
                          )
                        )}
                        {instructionData?.closingPhoto?.length > 1 && (
                          <div
                            className="extra-count pointer"
                            onClick={() => {
                              setIsVisible(true);
                              setPreviewImageKey(
                                instructionData?.closingRemark
                              );
                            }}
                          >
                            {`+${instructionData?.closingPhoto?.length - 1}`}
                          </div>
                        )}
                      </Space>
                    </Image.PreviewGroup>
                  ) : (
                    '-'
                  )}
                </div>
              </Space>
            </Col>
            <Col span={isDesktopViewport ? 7 : 24}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">Audio</div>
                <div>
                  {instructionData?.closingAudio?.length > 0 ? (
                    <div
                      className="audio-player-wrapper"
                      onClick={() => {
                        setShowAudioModal(true);
                        Event(GA_EVENT.DOWNLOAD_AUDIO, {
                          label: GA_LABEL.DOWNLOAD_AUDIO,
                          // eslint-disable-next-line no-undef
                          pathname: window?.location?.href
                        });
                      }}
                    >
                      <AudioIcon />
                      {instructionData?.closingAudio?.length > 1 && (
                        <div className="extra-count">{`+${
                          instructionData?.closingAudio?.length - 1
                        }`}</div>
                      )}
                    </div>
                  ) : (
                    '-'
                  )}
                </div>
              </Space>
            </Col>
          </Row>
        </div>
      </Card>
      {instructionData?.closingAudio?.length > 0 && (
        <AudioPlayerModal
          audios={instructionData?.closingAudio}
          showModal={showAudioModal}
          setShowModal={setShowAudioModal}
        />
      )}
    </div>
  );
};

export default CloseDetails;
