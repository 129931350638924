import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_PROJECT_LIST = gql`
  mutation updateProject($id: ID!, $data: UpdateProjectInput!) {
    updateProject(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT_AGENCY = gql`
  mutation createProjectAgency($data: CreateProjectAgencyInput!) {
    createProjectAgency(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_AGENCY = gql`
  mutation updateProjectAgency($id: ID!, $data: UpdateProjectAgencyInput!) {
    updateProjectAgency(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT_AGENCY = gql`
  mutation deleteProjectAgency($id: ID!) {
    deleteProjectAgency(id: $id) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject($data: CreateProjectInput!) {
    createProject(data: $data) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($data: UpdateProjectInput!, $id: ID!) {
    updateProject(data: $data, id: $id) {
      status
      message
    }
  }
`;

export const ONBOARD_PROJECT_USERS = gql`
  mutation onboardProjectUsers($data: [CreateProjectUserInput!]) {
    onboardProjectUsers(data: $data) {
      status
      message
    }
  }
`;

export const ONBOARD_PROJECT_AGENCIES = gql`
  mutation onboardProjectAgencies($data: [CreateProjectAgencyInput!]!) {
    onboardProjectAgencies(data: $data) {
      status
      message
    }
  }
`;

export const ONBOARD_PROJECT_EQC_TYPES = gql`
  mutation onboardProjectEqcTypes($data: [OnboardProjectEqcTypeInput!]!) {
    onboardProjectEqcTypes(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_EQC_TYPE = gql`
  mutation updateProjectEqcType($data: UpdateProjectEqcTypeInput!, $id: ID!) {
    updateProjectEqcType(data: $data, id: $id) {
      status
      message
    }
  }
`;
export const DUPLICATE_PROJECT_EQC_TYPE = gql`
  mutation duplicateProjectEqcType($data: DuplicateProjectEqcTypeInput!) {
    duplicateProjectEqcType(data: $data) {
      status
      message
    }
  }
`;

export const ADD_PROJECT_EQC_TYPE = gql`
  mutation createProjectEqcType($data: CreateProjectEqcTypeInput!) {
    createProjectEqcType(data: $data) {
      status
      message
    }
  }
`;

export const RENAME_PROJECT_EQC = gql`
  mutation renameEqc($eqcName: String!, $id: ID!) {
    renameEqc(eqcName: $eqcName, id: $id) {
      status
      message
    }
  }
`;
export const CREATE_PROJECT_USER = gql`
  mutation createProjectUser($data: CreateProjectUserInput!) {
    createProjectUser(data: $data) {
      status
      message
    }
  }
`;

export const ADD_AUDITOR_COMMENT = gql`
  mutation addAuditorComment($data: AddAuditorCommentInput!) {
    addAuditorComment(data: $data) {
      status
      message
    }
  }
`;
export const CREATE_PROJECT_EQC_STAGE = gql`
  mutation createProjectEqcTypeStage($data: CreateProjectEqcTypeStageInput!) {
    createProjectEqcTypeStage(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_EQC_STAGE = gql`
  mutation updateProjectEqcTypeStage(
    $id: ID!
    $data: UpdateProjectEqcTypeStageInput!
  ) {
    updateProjectEqcTypeStage(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT_EQC_STAGE_ITEM = gql`
  mutation createProjectEqcTypeStageItem(
    $data: CreateProjectEqcTypeStageItemInput!
  ) {
    createProjectEqcTypeStageItem(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_EQC_STAGE_ITEM = gql`
  mutation updateProjectEqcTypeStageItem(
    $id: ID!
    $data: UpdateProjectEqcTypeStageItemInput!
  ) {
    updateProjectEqcTypeStageItem(id: $id, data: $data) {
      status
      message
    }
  }
`;
export const UPDATE_PROJECT_USER = gql`
  mutation updateProjectUser($id: ID!, $data: UpdateProjectUserInput!) {
    updateProjectUser(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT_STAGE = gql`
  mutation deleteProjectEqcTypeStage($id: ID!) {
    deleteProjectEqcTypeStage(id: $id) {
      status
      message
    }
  }
`;
export const DELETE_PROJECT_USER = gql`
  mutation deleteProjectUser($id: ID!) {
    deleteProjectUser(id: $id) {
      status
      message
    }
  }
`;

export const DELETE_PROJECT_STAGE_ITEM = gql`
  mutation deleteProjectEqcTypeStageItem($id: ID!) {
    deleteProjectEqcTypeStageItem(id: $id) {
      status
      message
    }
  }
`;

export const REORDER_PROJECT_STAGE = gql`
  mutation reOrderProjectEqcTypeStage($data: ReOrderProjectEqcTypeStageInput!) {
    reOrderProjectEqcTypeStage(data: $data) {
      status
      message
    }
  }
`;

export const REORDER_PROJECT_STAGE_ITEM = gql`
  mutation reOrderProjectEqcTypeStageItem(
    $data: ReOrderProjectEqcTypeStageItemInput!
  ) {
    reOrderProjectEqcTypeStageItem(data: $data) {
      status
      message
    }
  }
`;

export const PUBLISH_EQC_TYPE = gql`
  mutation publishProjectEqcType($data: PublishProjectEqcTypeInput!, $id: ID!) {
    publishProjectEqcType(data: $data, id: $id) {
      status
      message
    }
  }
`;
export const RENAME_INSTRUCTION = gql`
  mutation renameInstruction($id: ID!, $name: String!) {
    renameInstruction(id: $id, name: $name) {
      status
      message
    }
  }
`;

export const MARK_QC_PASS = gql`
  mutation markQCPassFromWeb($data: MarkQCPassInput!) {
    markQCPassFromWeb(data: $data) {
      status
      message
    }
  }
`;
