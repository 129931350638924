import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../common/utils';
import { RENAME_PROJECT_EQC } from '../../../../graphql/Mutation';

const EditEqcModal = (props) => {
  const {
    showModal,
    setShowModal,
    eqcData,
    setEqcData,
    refetchEqcData
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const { required } = formValidatorRules;
  const { projectId } = useParams();
  const handleCancel = () => {
    setShowModal(false);
    setEqcData();
    form.resetFields();
  };
  useEffect(() => {
    if (eqcData) {
      const { uniqueCode } = eqcData;
      form.setFieldsValue({ uniqueCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcData]);

  const [renameProjectEqc] = useMutation(RENAME_PROJECT_EQC);

  const onFormSubmit = async ({ eqcName }) => {
    const { uniqueCode } = eqcData;
    try {
      const response = await renameProjectEqc({
        variables: {
          id: eqcData?.id,
          eqcName: `${uniqueCode}/${eqcName}`
        }
      });
      if (response?.data?.renameEqc) {
        setEqcData();
        form.resetFields();
        Event(GA_EVENT.RENAME_PROJECT_EQC, {
          label: GA_LABEL.RENAME_PROJECT_EQC,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_eqc_id: eqcData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setShowModal(false);
        refetchEqcData();
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        form={form}
        visible={showModal}
        onCancel={handleCancel}
        closable={false}
        footer={null}
      >
        <h2 className="mb-15">Edit EQC</h2>
        <Form layout="vertical" form={form} onFinish={onFormSubmit}>
          <Form.Item name="uniqueCode" label="Unique Code">
            <Input allowClear disabled />
          </Form.Item>
          <Form.Item
            name="eqcName"
            label="Name"
            rules={[
              required,
              {
                max: 250,
                message: 'Name cannot be more than 250 characters'
              }
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default EditEqcModal;
