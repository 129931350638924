import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, message, Modal } from 'antd';
import React, { useContext, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import { AppContext } from '../../AppContext';
import { ZoomIn, ZoomOut } from '../../assets/svg';
import {
  CROPPER_HEIGHT,
  CROPPER_WIDTH,
  GA_EVENT,
  GA_LABEL
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { fileUpload } from '../../common/utils';
import { UPDATE_TENANT_LOGO } from './graphql/Mutation';
import { GET_URL } from './graphql/Queries';

const CropperModal = (props) => {
  const { showModal, setShowModal, filePrefix, refetch, fileData, src } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const cropperRef = useRef(null);
  const [fileObj, setFileObj] = useState();

  const [updateTenantLogo] = useMutation(UPDATE_TENANT_LOGO, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.EDIT_TENANT_LOGO, {
        label: GA_LABEL.EDIT_TENANT_LOGO,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setShowModal(false);
      refetch();
    }
  });
  const [fetchSignedUrl] = useLazyQuery(GET_URL, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const signedImageUrl = res?.getSignedPutUrl?.signedUrl;
      const imgData = fileObj.replace(/^data:image\/\w+;base64,/, '');
      const fileBuffer = Buffer.from(imgData, 'base64');
      fileUpload(signedImageUrl, fileBuffer)
        .then(() => {
          updateTenantLogo({
            variables: {
              logo: res?.getSignedPutUrl?.key
            }
          });
        })
        .catch(() => {
          message.error('Error while uploading image');
        });
    }
  });
  const handleCancel = () => {
    setShowModal(false);
  };
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setFileObj(cropper.getCroppedCanvas().toDataURL());
  };

  const handleUpload = (info) => {
    try {
      fetchSignedUrl({
        variables: {
          data: {
            fileName: `${filePrefix}/${info.file.name}`,
            contentType: info.file.type,
            acl: 'public-read',
            isTenantModule: true
          }
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error while uploading image', error);
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      className="crop-modal"
      closable={false}
      onCancel={handleCancel}
      onOk={() => {
        handleUpload(fileData);
      }}
    >
      <div>
        <h2>Crop Image</h2>
        <Cropper
          ref={cropperRef}
          src={src}
          style={{ height: 200 }}
          aspectRatio={CROPPER_WIDTH / CROPPER_HEIGHT}
          crop={onCrop}
          responsive
          movable
          zoomable
          zoomOnTouch
          zoomOnWheel
          dragMode="move"
        />
        <div className="mt-25 d-flex justify-center zoom-controls">
          <Button
            className="mr-10 b-0"
            icon={<ZoomIn />}
            onClick={() => cropperRef?.current?.cropper?.zoom(0.1)}
          />
          <Button
            className="b-0"
            icon={<ZoomOut />}
            onClick={() => cropperRef?.current?.cropper?.zoom(-0.1)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CropperModal;
