import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { findIndex, map, sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DragIcon,
  EditButton,
  RightIcon,
  WrongIcon
} from '../../../../../assets/svg';
import {
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL
} from '../../../../../common/constants';
import { Event } from '../../../../../common/trackEvents';
import CommonCard from '../../../../../components/CommonCard';
import CommonTable from '../../../../../components/CommonTable';
import InfiniteScrollHandler from '../../../../../components/InfiniteScrollHandler';
import Portal from '../../../../../components/Portal';
import {
  DELETE_STAGE_ITEM,
  REORDER_STAGE_ITEM
} from '../../../graphql/Mutation';
import { GET_STAGE_ITEM_LIST } from '../../../graphql/Queries';
import DeleteModalStage from '../DeleteModalStage';
import AddModal from './AddModal';

const StageItemTable = ({ stageId }) => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const initialStageItemFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'order', order: 'ASC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };

  const DragHandle = sortableHandle(() => (
    <DragIcon className="drag-icon" onClick={(e) => e.preventDefault()} />
  ));

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showModal, setShowModal] = useState(false);
  const [stageItemData, setStageItemData] = useState();
  const [deleteStageItemModal, setDeleteStageItemModal] = useState(false);
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [mutationId, setMutationId] = useState('');
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [stageItemListData, setStageItemListData] = useState([]);
  const [stageItemFilter, setStageItemFilter] = useState({
    ...initialStageItemFilter,
    eqcTypeStageId: parseInt(stageId, 10)
  });
  const { eqcTypeId } = useParams();
  const [fetchStageItemData, { loading, refetch }] = useLazyQuery(
    GET_STAGE_ITEM_LIST,
    {
      variables: { filter: stageItemFilter },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.eqcTypeStageItemList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.eqcTypeStageItemList?.total
        };
        setPaginationProp(pagination);
        if (scrollFlag) {
          const datalist = [...stageItemListData, ...data];
          setStageItemListData(sortBy(datalist, ['order']));
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setStageItemListData(sortBy(datalist, ['order']));
        }
        setHasMore(!!data?.length);
      },
      onError() {}
    }
  );
  const handleRefetchAfterDelete = () => {
    const newSkip =
      stageItemListData?.length === 1
        ? Math.max(0, stageItemFilter?.skip - paginationProp?.pageSize)
        : stageItemFilter?.skip;
    setStageItemFilter({
      ...stageItemFilter,
      skip: newSkip
    });
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip: newSkip
        }
      }
    });
  };
  const [deleteStageItem] = useMutation(DELETE_STAGE_ITEM, {
    refetch,
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_GLOBAL_EQC_TYPE_STAGE_ITEM, {
        label: GA_LABEL.DELETE_GLOBAL_EQC_TYPE_STAGE_ITEM,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        eqc_type_id: eqcTypeId,
        stage_id: stageId,
        stage_item_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setDeleteStageItemModal(false);
      handleRefetchAfterDelete();
    }
  });

  const [reorderStageItem, { loading: reorderLoading }] = useMutation(
    REORDER_STAGE_ITEM,
    {
      onCompleted() {
        Event(GA_EVENT.REORDER_GLOBAL_EQC_TYPE_STAGE_ITEM, {
          label: GA_LABEL.REORDER_GLOBAL_EQC_TYPE_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          eqc_type_id: eqcTypeId,
          stage_id: stageId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        fetchStageItemData({ variables: { filter: stageItemFilter } });
      },
      onError() {}
    }
  );

  useEffect(() => {
    if (stageId) {
      setStageItemFilter({
        ...initialStageItemFilter,
        eqcTypeStageId: parseInt(stageId, 10)
      });
      setPaginationProp(initialPaginationValue);
      fetchStageItemData({
        variables: {
          filter: {
            ...initialStageItemFilter,
            eqcTypeStageId: parseInt(stageId, 10)
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageId]);

  const refetchStageDetails = () => {
    setStageItemFilter({
      ...initialStageItemFilter,
      eqcTypeStageId: parseInt(stageId, 10)
    });
    setPaginationProp(initialPaginationValue);
    fetchStageItemData({
      variables: {
        filter: {
          ...initialStageItemFilter,
          eqcTypeStageId: parseInt(stageId, 10)
        }
      }
    });
  };

  const handleAddEdit = (record) => {
    if (record) {
      setStageItemData(record);
    } else {
      setStageItemData();
    }
    setShowModal(true);
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setStageItemFilter({
      ...stageItemFilter,
      skip: skip,
      limit: pagination.pageSize
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip,
          limit: pagination.pageSize
        }
      }
    });
  };
  const getActionButton = (record) => {
    return (
      <div className={clsx(isDesktopViewport && 'd-flex')}>
        <Tooltip title="Edit">
          <Button
            shape="round"
            icon={<EditButton />}
            className="edit-button pointer b-0"
            onClick={() => {
              handleAddEdit(record);
            }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            className="delete-button pointer b-0"
            shape="round"
            icon={<DeleteButton />}
            onClick={() => {
              setTitle('Stage Item');
              setName(record?.title);
              setMutationId(record?.id);
              setDeleteStageItemModal(true);
            }}
          />
        </Tooltip>
      </div>
    );
  };
  const columns = [
    {
      width: '5%',
      className: 'drag-visible',
      render: () => (
        <Tooltip title="Change order">
          <div>
            <DragHandle />
          </div>
        </Tooltip>
      )
    },
    {
      title: 'ID',
      key: 'id',
      render: (text, record, index) => {
        return <div>{stageItemFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'QC CHECKLIST POINT',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'INPUT',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'PHOTOS',
      key: 'photo',
      render: (record) => {
        if (record?.photo === true) {
          return <RightIcon />;
        }
        return <WrongIcon />;
      }
    },
    {
      title: 'REMARKS',
      key: 'remark',
      render: (record) => {
        if (record?.remark === true) {
          return <RightIcon />;
        }
        return <WrongIcon />;
      }
    },
    {
      key: 'action',
      render: (record) => getActionButton(record)
    }
  ];

  const SortableItem = sortableElement((props) =>
    isDesktopViewport ? (
      <tr {...props} />
    ) : (
      <div {...props} className="ant-card ant-card-bordered" />
    )
  );
  const SortableContainer = sortableContainer((props) =>
    isDesktopViewport ? <tbody {...props} /> : <div {...props} />
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      reorderStageItem({
        variables: {
          data: {
            eqcTypeStageItemIds: [
              stageItemListData[oldIndex]?.id,
              stageItemListData[newIndex]?.id
            ],
            eqcTypeStageId: parseInt(stageId, 10)
          }
        }
      });
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      axis="y"
      lockAxis="y"
      lockOffset={['0%', '100%']}
      // eslint-disable-next-line no-undef
      helperContainer={document.getElementById('sortable-container-div')}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = findIndex(
      stageItemListData,
      (item) => item?.id === restProps['data-row-key']
    );
    if (index !== -1) return <SortableItem index={index} {...restProps} />;
    return null;
  };
  const handleRefetch = () => {
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip: stageItemListData?.length,
          limit: DEFAULT_PAGE_SIZE
        }
      }
    });
  };
  return (
    <>
      {showModal && (
        <AddModal
          showModal={showModal}
          setShowModal={setShowModal}
          stageData={stageItemData}
          isUpdate={!!stageItemData}
          setStageData={setStageItemData}
          refetchStageDataWithInitialValues={refetchStageDetails}
          refetchStageData={refetchStageDetails}
          stageId={stageId}
        />
      )}

      {deleteStageItemModal && (
        <DeleteModalStage
          showModal={deleteStageItemModal}
          setShowModal={setDeleteStageItemModal}
          title={title}
          name={name}
          deleteStage={deleteStageItem}
          mutationId={mutationId}
          setMutationId={setMutationId}
        />
      )}

      <Portal portalId="stageitem-add-btn">
        <Button
          shape="round"
          type="primary"
          id="add-btn"
          icon={<AddButton />}
          className="add-button"
          onClick={() => {
            handleAddEdit();
          }}
        >
          {isDesktopViewport && 'Add Item'}
        </Button>
      </Portal>

      {isDesktopViewport ? (
        <CommonTable
          columns={columns}
          data={stageItemListData || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey="id"
          loading={loading || reorderLoading}
          components={{
            body: stageItemListData?.length > 0 && {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
        />
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading || reorderLoading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          wrapperClassName="stage-item-scroll-wrapper"
          dataLength={stageItemListData?.length}
          skeletonRows={columns?.length - 4}
        >
          <div id="sortable-container-div" />
          <DraggableContainer>
            {map(stageItemListData, (item, index) => {
              return (
                <SortableItem index={index}>
                  <CommonCard key={item?.id} className="stage-card">
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">
                        <DragHandle />
                      </div>
                      <div>
                        <div className="card-header fw-medium">
                          <Row wrap={false}>
                            <Col flex="auto">
                              <span className="mr-5">{index + 1}.</span>
                              {item?.title}
                            </Col>
                          </Row>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15">
                            <span className="fw-medium mr-5">Input:</span>
                            {item?.type}
                          </div>
                          <div className="mb-15 d-flex align-center">
                            <span className="fw-medium mr-5">Photos:</span>
                            {item?.photo === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon />
                            )}
                          </div>
                          <div className=" d-flex align-center">
                            <span className="fw-medium mr-5">Remarks:</span>
                            {item?.remark === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon />
                            )}
                          </div>
                        </div>
                      </div>
                      <span className="position-absolute stage-item-action-btn">
                        {getActionButton(item)}
                      </span>
                    </div>
                  </CommonCard>
                </SortableItem>
              );
            })}
          </DraggableContainer>
        </InfiniteScrollHandler>
      )}
    </>
  );
};

export default StageItemTable;
