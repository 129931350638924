import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Skeleton,
  Space,
  Switch
} from 'antd';
import { omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  PERMISSIONS
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { formValidatorRules } from '../../../common/utils';
import GoogleMapComponent from '../../../components/GoogleMap';
import { UPDATE_PROJECT } from '../graphql/Mutation';
import { GET_SINGLE_PROJECT } from '../graphql/Queries';

const { required } = formValidatorRules;

const EditProjectModal = (props) => {
  const { showModal, setShowModal, projectId, callback } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [marker, setMarker] = useState(null);
  const [radius, setRadius] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [fetchProjectDetails, { loading }] = useLazyQuery(GET_SINGLE_PROJECT, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const formData = { ...res?.getProject };
      if (res?.getProject?.permissions?.includes(PERMISSIONS.FINGERPRINT)) {
        formData.fingerprint = true;
      }
      if (res?.getProject?.permissions?.includes('LOCATION')) {
        formData.location = true;
      }
      form.setFieldsValue(formData);
      setMarker({
        lat: res?.getProject?.latitude,
        lng: res?.getProject?.longitude
      });
      setRadius(res?.getProject?.radius);
    },
    onError() {}
  });
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.EDIT_PROJECT, {
        label: GA_LABEL.EDIT_PROJECT,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
    }
  });
  const handleCancel = () => {
    setShowModal(false);
    setInputValue('');
    form.resetFields();
  };
  const submitForm = async (values) => {
    const { location, fingerprint } = values;
    const permissions = [];
    if (location) {
      permissions.push(PERMISSIONS.LOCATION);
    }
    if (fingerprint) {
      permissions.push(PERMISSIONS.FINGERPRINT);
    }
    const data = omit({ ...values, permissions }, ['location', 'fingerprint']);
    await updateProject({
      variables: { data, id: Number(projectId) }
    });
    handleCancel();
    if (callback) {
      callback();
    }
  };
  useEffect(() => {
    if (projectId && showModal) {
      fetchProjectDetails({ variables: { id: Number(projectId) } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, showModal]);

  useEffect(() => {
    form.setFieldsValue({ latitude: marker?.lat, longitude: marker?.lng });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker]);

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      footer={null}
      closable={false}
      width={720}
    >
      <div>
        <h2 className="mb-15">Edit Project</h2>
        {loading ? (
          <Skeleton active paragraph={{ rows: 20 }} />
        ) : (
          <Form form={form} layout="vertical" onFinish={submitForm}>
            <Row gutter={[18]}>
              <Col span={isDesktopViewport ? 8 : 24}>
                <Form.Item
                  rules={[
                    required,
                    { max: 50, message: 'Maximum 50 characters allowed' }
                  ]}
                  name="name"
                  label="Name"
                >
                  <Input allowClear placeholder="Enter Name" />
                </Form.Item>
              </Col>
              <Col span={isDesktopViewport ? 8 : 24}>
                <Form.Item
                  rules={[
                    required,
                    { max: 20, message: 'Maximum 20 characters allowed' }
                  ]}
                  name="uniqueCode"
                  label="Unique Code"
                >
                  <Input allowClear placeholder="Enter Project Code" />
                </Form.Item>
              </Col>
              <Col span={isDesktopViewport ? 8 : 24}>
                <Form.Item
                  rules={[
                    required,
                    { max: 50, message: 'Maximum 50 characters allowed' }
                  ]}
                  name="clientName"
                  label="Client Name"
                >
                  <Input allowClear placeholder="Enter Client Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      max: 1000,
                      message: 'Description cannot be more than 1000 characters'
                    }
                  ]}
                >
                  <Input.TextArea
                    allowClear
                    autoSize={{ minRows: 3, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="mb-10">Permissions</div>
            <Space size={isDesktopViewport ? 50 : 20} className="mb-20">
              <div className="d-flex align-center">
                <div className="mr-5">Location:</div>
                <Form.Item
                  name="location"
                  className="m-0"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </div>
              <div className="d-flex align-center">
                <div className="mr-5">Authentication:</div>
                <Form.Item
                  name="fingerprint"
                  className="m-0"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </div>
            </Space>
            <h3 className="mt-25">Site Details</h3>
            <Row gutter={[18]}>
              <Col span={isDesktopViewport ? 8 : 24}>
                <Form.Item rules={[required]} name="latitude" label="Latitude">
                  <InputNumber placeholder="Enter Latitude" disabled />
                </Form.Item>
              </Col>
              <Col span={isDesktopViewport ? 8 : 24}>
                <Form.Item
                  rules={[required]}
                  name="longitude"
                  label="Longitude"
                >
                  <InputNumber placeholder="Enter Longitude" disabled />
                </Form.Item>
              </Col>
              <Col span={isDesktopViewport ? 8 : 24}>
                <Form.Item rules={[required]} name="radius" label="Radius (m)">
                  <InputNumber
                    placeholder="Enter Radius"
                    onChange={setRadius}
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>
            <GoogleMapComponent
              marker={marker}
              setMarker={setMarker}
              radius={radius}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
            <Row justify="end">
              <div className="form-buttons">
                <Button
                  shape="round"
                  className="cancel-button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button shape="round" type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default EditProjectModal;
