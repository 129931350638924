import { Button, Card, Col, Row, Space, Tag } from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import { Import, InfoIcon } from '../../../../../../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHBRACKET,
  DEFAULTDATEFORMAT,
  GA_EVENT,
  GA_LABEL,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import InstructionRenameModal from './InstructionRenameModal';

const InstructionSummary = ({ instructionData }) => {
  const { projectId } = useParams();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const { getToken, getCurrentUser } = useContext(AppContext);
  const [showRenameModal, setShowRenameModal] = useState(false);

  const currentUser = getCurrentUser();
  const renderDownloadButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/report/instructions/${instructionData?.id}?access_token=Bearer ${token}`;
    return (
      <Button
        shape="round"
        icon={<Import />}
        className={`report-button b-0 ${
          isDesktopViewport ? '' : 'b-0 width-percent-100 justify-center'
        }`}
        target="_blank"
        href={url}
        onClick={() => {
          Event(GA_EVENT.DOWNLOAD_INSTRUCTION_FINAL_REPORT, {
            label: GA_LABEL.DOWNLOAD_INSTRUCTION_FINAL_REPORT,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            project_id: projectId,
            project_instruction_id: instructionData?.id,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName
          });
        }}
      >
        Download Report
      </Button>
    );
  };
  const handleRenameModal = () => {
    setShowRenameModal(true);
    Event(GA_EVENT.VIEW_PROJECT_INSTRUCTION_RENAME_MODAL, {
      label: GA_LABEL.VIEW_PROJECT_INSTRUCTION_RENAME_MODAL,
      // eslint-disable-next-line no-undef
      pathname: window?.location?.href,
      project_id: projectId,
      project_instruction_id: instructionData?.id,
      user_id: currentUser?.id,
      user_name: currentUser?.name,
      tenant_id: currentUser?.tenantUser?.tenant?.id,
      tenant_name: currentUser?.tenantUser?.tenant?.organizationName
    });
  };
  return (
    <div className="instruction-tab-details">
      <Card className="instruction-tab-details-summary">
        <div className="d-flex align-center justify-between mb-20">
          <div className="d-flex align-center">
            <h2 className="mb-0">Summary</h2>
            <Tag
              className={`${
                INS_STATUS_CLASSNAME[instructionData?.status]
              } ml-10`}
            >
              {INS_STATUS_LABEL[instructionData?.status]}
            </Tag>
          </div>
          {isDesktopViewport && renderDownloadButton()}
        </div>
        <Row
          gutter={isDesktopViewport ? [75, 40] : [40, 30]}
          className="fw-medium mb-20"
        >
          <Col span={isDesktopViewport ? 6 : 24}>
            <Space direction="vertical" size={12}>
              <Space size={10}>
                <div className="d-flex align-center">
                  <div className="text-secondary mr-5">Name</div>
                  {instructionData?.instructionRenameLogs?.length > 0 && (
                    <InfoIcon className="pointer" onClick={handleRenameModal} />
                  )}
                </div>
              </Space>
              <div>{instructionData?.name || '-'}</div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 6 : 24}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary">Type</div>
              <div>{instructionData?.type || '-'}</div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 6 : 24}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary">Agency</div>
              <div>{instructionData?.projectAgency?.agency?.name || '-'}</div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 6 : 24}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary">Due Date</div>
              <div>
                {instructionData?.reminderDate
                  ? moment(instructionData?.reminderDate).format(
                      DEFAULTDATEFORMAT
                    )
                  : '-'}
              </div>
            </Space>
          </Col>
          <Col span={isDesktopViewport ? 6 : 24}>
            <Space direction="vertical" size={12}>
              <div className="text-secondary">Raised By</div>
              <div>{instructionData?.creator?.name || '-'}</div>
              <div>
                {instructionData?.createdAt &&
                  moment(instructionData?.createdAt).format(
                    DATETIMEWITHBRACKET
                  )}
              </div>
            </Space>
          </Col>
          {instructionData?.status !== INS_STATUS_KEYS.NOTICE && (
            <>
              <Col span={isDesktopViewport ? 6 : 24}>
                <Space direction="vertical" size={12}>
                  <div className="text-secondary">Responded By</div>
                  <div>{instructionData?.responder?.name || '-'}</div>
                  <div>
                    {instructionData?.respondedOn &&
                      moment(instructionData?.respondedOn).format(
                        DATETIMEWITHBRACKET
                      )}
                  </div>
                </Space>
              </Col>
              <Col span={isDesktopViewport ? 6 : 24}>
                <Space direction="vertical" size={12}>
                  <div className="text-secondary">Closed By</div>
                  <div>{instructionData?.closer?.name || '-'}</div>
                  <div>
                    {instructionData?.closedOn &&
                      moment(instructionData?.closedOn).format(
                        DATETIMEWITHBRACKET
                      )}
                  </div>
                </Space>
              </Col>
              <Col span={isDesktopViewport ? 6 : 24}>
                <Space direction="vertical" size={12}>
                  <div className="text-secondary">Revision</div>
                  <div>
                    {instructionData?.instructionIssueHistories?.[0]
                      ?.revision || '-'}
                  </div>
                </Space>
              </Col>
            </>
          )}
        </Row>
        {!isDesktopViewport && renderDownloadButton()}
      </Card>
      <InstructionRenameModal
        showModal={showRenameModal}
        setShowModal={setShowRenameModal}
        data={instructionData?.instructionRenameLogs}
      />
    </div>
  );
};

export default InstructionSummary;
