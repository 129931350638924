import { useLazyQuery } from '@apollo/client';
import { Button, Tabs } from 'antd';
import * as eva from 'eva-icons';
import { findIndex, includes, isNaN, values } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../AppContext';
import { ForwardArrow, ProjectIcon } from '../../../../assets/svg';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  BREAKPOINTS,
  ROUTES,
  TAB_KEYS
} from '../../../../common/constants';
import CanPerform from '../../../../components/CanPerform';
import HasAccess from '../../../../components/HasAccess';
import Error404 from '../../../../Error404';
import { GET_SINGLE_PROJECT } from '../../graphql/Queries';
import EditProjectModal from '../EditProjectModal';
import Agency from './pages/agencies/Agency';
import Eqc from './pages/eqc/Eqc';
import EqcType from './pages/eqcTypes/EqcTypes';
import Instruction from './pages/instructions/Instruction';
import User from './pages/users/User';

const ProjectDetails = () => {
  const { getCurrentUser, dispatch } = useContext(AppContext);
  const { projectId, tab } = useParams();
  const { TabPane } = Tabs;
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const [showEditModal, setShowEditModal] = useState(false);
  const currentUserId = getCurrentUser()?.id || {};
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getWebProject, { data, refetch }] = useLazyQuery(GET_SINGLE_PROJECT, {
    onCompleted: (res) => {
      const index = findIndex(
        res?.getProject?.projectUsers,
        (user) => Number(user?.userId) === Number(currentUserId)
      );
      if (index !== -1) {
        const loggedInUserRole = res?.getProject?.projectUsers?.[index]?.roles;
        dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
      } else {
        dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
      }
    }
  });

  useEffect(() => {
    if (includes(values(TAB_KEYS), tab) && !isNaN(Number(projectId))) {
      getWebProject({
        variables: {
          id: Number(projectId)
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!includes(values(TAB_KEYS), tab) || isNaN(Number(projectId))) {
    return <Error404 />;
  }
  return (
    <>
      <div className="project-details">
        <div className="project-details-title d-flex justify-between align-center page-header">
          <div className="project-details-title-text d-flex">
            <ProjectIcon
              onClick={() => {
                history.push(ROUTES.PROJECTS, {
                  navFilter,
                  navPagination
                });
              }}
              className="pointer"
            />
            <ForwardArrow />
            <h1 className="mr-10 text-break">{data?.getProject?.name}</h1>
          </div>
          <CanPerform action={ALLOWED_ACTION_KEYS.EDIT_PROJECT}>
            <Button
              shape="round"
              type="primary"
              icon={<i data-eva="edit-outline" data-eva-width="24" />}
              className="edit-button-project d-flex"
              onClick={() => setShowEditModal(true)}
            >
              {isDesktopViewport && 'Edit'}
            </Button>
          </CanPerform>
        </div>
        <div className="mobile-card-wrapper">
          <div className="details-content">
            <Tabs
              onChange={(key) =>
                history.push(`${ROUTES.PROJECTS}/${projectId}/${key}`, {
                  navFilter,
                  navPagination
                })
              }
              activeKey={tab}
              destroyInactiveTabPane
            >
              {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
                <TabPane tab="Eqc" key={TAB_KEYS.EQC}>
                  <div className="details">
                    <Eqc uniqueCode={data?.getProject?.uniqueCode} />
                  </div>
                </TabPane>
              )}
              {HasAccess({ type: ACCESS_TYPE.INSTRUCTION }) && (
                <TabPane tab="Issue" key={TAB_KEYS.INSTRUCTION}>
                  <div>
                    <Instruction uniqueCode={data?.getProject?.uniqueCode} />
                  </div>
                </TabPane>
              )}
              {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
                <TabPane tab="Eqc Types" key={TAB_KEYS.EQC_TYPES}>
                  <div>
                    <EqcType />
                  </div>
                </TabPane>
              )}
              <TabPane tab="User" key={TAB_KEYS.USERS}>
                <div>
                  <User />
                </div>
              </TabPane>
              <TabPane tab="Agencies" key={TAB_KEYS.AGENCIES}>
                <div>
                  <Agency />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      <EditProjectModal
        setShowModal={setShowEditModal}
        showModal={showEditModal}
        projectId={projectId}
        callback={refetch}
      />
    </>
  );
};

export default ProjectDetails;
