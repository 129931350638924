import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Empty, message, Row, Tag, Tooltip } from 'antd';
import { debounce, isEmpty, map, pull, remove, some } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import { DeleteButton, EditButton } from '../../../assets/svg';
import {
  ADD_PROJECT_STEPS_KEYS,
  BREAKPOINTS,
  DROPDOWN_LIMIT,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  SCROLL_CONST,
  TAB_KEYS
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import { ONBOARD_PROJECT_EQC_TYPES } from '../graphql/Mutation';
import { GET_ALL_EQC_TYPE } from '../graphql/Queries';
import AddProjectEqcTypeModal from './AddProjectEqcTypeModal';
import EditProjectEqcTypeModal from './EditProjectEqcTypeModal';

let searchDebounce = null;
const AddProjectEqcType = ({ projectId }) => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [selectedEqcTypes, setSelectedEqcTypes] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState({});
  const history = useHistory();
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [allEqcTypes, setAllEqcTypes] = useState([]);
  const [isUserRequired, setIsUserRequired] = useState(true);

  const [fetchAllEqcTypes, { loading }] = useLazyQuery(GET_ALL_EQC_TYPE, {
    fetchPolicy: 'network-only',
    variables: { filter: { projectId } },
    onCompleted: (res) => {
      const data = res?.projectEqcTypeDropdownList?.data;
      if (currentPage === 0) {
        setAllEqcTypes(data);
      } else {
        setAllEqcTypes((oldData) => [...oldData, ...data]);
      }
      setCurrentPage((page) => page + 1);
      setHasMore(data?.length >= DROPDOWN_LIMIT);
    }
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  useEffect(() => {
    fetchAllEqcTypes({
      variables: {
        filter: {
          skip: currentPage * DROPDOWN_LIMIT,
          limit: DROPDOWN_LIMIT,
          projectId
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const deleteEqcType = (recordId) => {
    const newIds = [...selectedEqcTypes];
    const newData = [...tableData];
    pull(newIds, recordId);
    remove(newData, ({ id }) => id === recordId);
    setSelectedEqcTypes(newIds);
    setTableData(newData);
  };
  const handleEditModal = (record, index) => {
    setEditData({ ...record, index });
    setShowEditModal(true);
  };
  const [onboardProjectEqcTypes, { loading: mutationLoading }] = useMutation(
    ONBOARD_PROJECT_EQC_TYPES,
    {
      onError() {},
      onCompleted: (res) => {
        Event(GA_EVENT.ONBOARD_PROJECT_EQC_TYPE, {
          label: GA_LABEL.ONBOARD_PROJECT_EQC_TYPE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        if (res?.onboardProjectEqcTypes) {
          history.push(`${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.EQC}`);
        }
      }
    }
  );
  const getActionButtons = (record, index) => {
    return (
      <div className="d-flex">
        <Tooltip title="Edit">
          <Button
            shape="round"
            icon={<EditButton />}
            className="edit-button pointer b-0"
            onClick={() => handleEditModal(record, index)}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            className="delete-button pointer b-0"
            shape="round"
            icon={<DeleteButton />}
            onClick={() => deleteEqcType(record?.id)}
          />
        </Tooltip>
      </div>
    );
  };
  const getUsers = (record) =>
    record?.length ? (
      <Row gutter={[8, 4]}>
        {map(record, ({ userName }, index) => (
          <Tag className="light-tag" key={index}>
            {userName}
          </Tag>
        ))}
      </Row>
    ) : (
      '-'
    );

  const getAgencies = (record) =>
    record?.length ? (
      <Row gutter={[8, 4]}>
        {map(record, ({ id, agencyName }) => (
          <Tag className="light-tag" key={id}>
            {agencyName}
          </Tag>
        ))}
      </Row>
    ) : (
      '-'
    );

  const columns = [
    {
      title: (
        <div>
          <span className="required-mark">*</span>
          <span>EQC TYPE</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: (
        <div>
          <span className="required-mark">*</span>
          <span>USER</span>
        </div>
      ),
      dataIndex: 'user',
      key: 'user',
      render: (user) => getUsers(user)
    },
    {
      title: (
        <div>
          <span className="required-mark">*</span>
          <span>AGENCY</span>
        </div>
      ),
      key: 'agency',
      dataIndex: 'agency',
      render: (agency) => getAgencies(agency)
    },
    {
      key: 'action',
      render: (text, record, index) => getActionButtons(record, index)
    }
  ];

  const handleAddModalSubmit = ({ type }) => {
    const newData = [...tableData];
    const newIds = [...selectedEqcTypes];
    map(type, (eqcType) => {
      const parsedData = JSON.parse(eqcType);
      newData.push(parsedData);
      newIds.push(parsedData?.id);
    });
    setTableData(newData);
    setSelectedEqcTypes(newIds);
    setShowAddModal(false);
  };
  const handleEditModalSubmit = (values) => {
    const { user, agency, index } = values;
    const newData = [...tableData];
    const temp = newData[index];
    newData[index] = {
      ...temp,
      user: map(user, (item) =>
        typeof item === 'string' ? JSON.parse(item) : item
      ),
      agency: map(agency, (item) =>
        typeof item === 'string' ? JSON.parse(item) : item
      )
    };
    setTableData(newData);
    setShowEditModal(false);
  };

  const submitProject = () => {
    if (!tableData.length) {
      return message.error('Please add EQC Types before proceeding!');
    }
    const data = [];
    const validated = !some(tableData, ({ id, user, agency }) => {
      if ((isUserRequired && isEmpty(user)) || isEmpty(agency)) {
        return message.error('Missing agencies or users!');
      }
      data.push({
        projectId,
        eqcTypeId: Number(id),
        projectUserIds: map(user, ({ userId }) => userId),
        projectAgencyIds: map(agency, ({ agencyId }) => agencyId)
      });
    });
    if (validated) {
      onboardProjectEqcTypes({
        variables: { data }
      });
    }
  };
  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && hasMore && !loading) {
      fetchAllEqcTypes({
        variables: {
          filter: {
            skip: currentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            projectId,
            search: searchText
          }
        }
      });
    }
  };
  const searchQuery = (search) => {
    setSearchText(search);
    setCurrentPage(0);
    fetchAllEqcTypes({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          skip: 0,
          limit: DROPDOWN_LIMIT,
          search,
          projectId
        }
      }
    });
  };
  const handleSearch = (value) => {
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(value);
  };
  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };
  return (
    <>
      <div className="header d-flex align-center justify-between mb-20">
        <h2 className="m-0">EQC Type</h2>
        <div className="eqc-stage-header-buttons d-flex">
          <Button
            shape="round"
            type="primary"
            id="add-btn"
            className="add-button"
            onClick={() => setShowAddModal(true)}
          >
            Add
          </Button>
        </div>
      </div>
      {isDesktopViewport ? (
        <div className="mb-20">
          <CommonTable data={tableData} columns={columns} rowKey="id" />
        </div>
      ) : (
        <div className=" position-relative">
          {tableData.length ? (
            <div className="mb-15 width-percent-100 infinite-data-container">
              {map(tableData, (user, index) => {
                return (
                  <CommonCard key={user?.id}>
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">{index + 1}.</div>
                      <div>
                        <div className="card-header fw-medium">
                          <span>{user?.name}</span>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15 d-flex align-center">
                            <span className="fw-medium mr-10">Users:</span>
                            {getUsers(user?.user)}
                          </div>
                          <div className=" d-flex align-center">
                            <span className="fw-medium mr-10">Agencies:</span>
                            {getAgencies(user?.agency)}
                          </div>
                        </div>
                      </div>
                      <span className="d-flex position-absolute user-action-btn">
                        {getActionButtons(user, index)}
                      </span>
                    </div>
                  </CommonCard>
                );
              })}
            </div>
          ) : (
            <Empty description="No Eqc Type added yet!" className="mb-20" />
          )}
        </div>
      )}
      <Row justify="end">
        <Col className="form-buttons">
          <Button
            shape="round"
            onClick={() => {
              Event(GA_EVENT.CLICK_PREVIOUS_BUTTON, {
                label: `While onboard project eqc types`,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
                project_id: projectId,
                user_id: currentUser?.id,
                user_name: currentUser?.name,
                tenant_id: currentUser?.tenantUser?.tenant?.id,
                tenant_name: currentUser?.tenantUser?.tenant?.organizationName
              });
              history.push(
                `${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.AGENCIES}`
              );
            }}
            className="cancel-button"
          >
            Previous
          </Button>
          <Button
            shape="round"
            type="primary"
            onClick={submitProject}
            loading={mutationLoading}
          >
            Add Project
          </Button>
        </Col>
      </Row>
      <AddProjectEqcTypeModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        data={allEqcTypes}
        handleModalSubmit={handleAddModalSubmit}
        selectedEqcTypes={selectedEqcTypes}
        onPopupScroll={handleScroll}
        onSearch={handleSearch}
        onClear={handleClear}
      />
      <EditProjectEqcTypeModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        data={editData}
        projectId={projectId}
        handleModalSubmit={handleEditModalSubmit}
        isUserRequired={isUserRequired}
        setIsUserRequired={setIsUserRequired}
      />
    </>
  );
};

export default AddProjectEqcType;
