import { Button, Form, Input, Modal } from 'antd';
import { get, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { WARNINGS } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonDropdown from '../../../components/CommonDropdown';
import {
  GET_PROJECT_AGENCY_FOR_EQC_TYPES,
  GET_PROJECT_USERS_FOR_EQC_TYPES
} from '../graphql/Queries';

const { required } = formValidatorRules;

const EditProjectEqcTypeModal = (props) => {
  const {
    showModal,
    setShowModal,
    handleModalSubmit,
    data: { name, user, agency, index },
    projectId,
    isUserRequired,
    setIsUserRequired
  } = props;
  const [form] = Form.useForm();
  const [existingUsers, setExistingUsers] = useState([]);
  const [existingAgencies, setExistingAgencies] = useState([]);

  const handleCancel = () => {
    form.resetFields();
    setShowModal(false);
  };
  const submitForm = () => {
    handleModalSubmit({
      user: existingUsers,
      agency: existingAgencies,
      index
    });
    form.resetFields();
  };
  useEffect(() => {
    if (showModal) {
      form.setFieldsValue({
        name,
        agency: agency && map(agency, (item) => item?.agencyId),
        user: user && map(user, (item) => item?.userId)
      });
      setExistingAgencies(agency);
      setExistingUsers(user);
    }
    return () => {
      setExistingUsers([]);
      setExistingAgencies([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      footer={null}
      closable={false}
      destroyOnClose
    >
      <h2 className="mb-15">Edit EQC Type</h2>
      <Form form={form} layout="vertical" onFinish={submitForm}>
        <Form.Item label="Eqc Type" name="name">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="user"
          label="User"
          rules={isUserRequired && [required]}
          className={isUserRequired ? '' : 'mb-5'}
        >
          <CommonDropdown
            placeholder="Select users"
            allowClear
            mode="multiple"
            showSearch
            optionFilterProp="children"
            query={GET_PROJECT_USERS_FOR_EQC_TYPES}
            responsePath="projectEqcTypeUserDropdownList.data"
            valuePath="id"
            labelPath="user.name"
            optionKey="user"
            variables={{ filter: { projectId } }}
            fetchPolicy="network-only"
            onChange={(values, records) => {
              setExistingUsers(
                map(records, ({ id, user: { name: userName } }) =>
                  JSON.stringify({ userId: id, userName })
                )
              );
            }}
            callback={(response) => {
              setIsUserRequired(
                !get(
                  response,
                  'projectEqcTypeUserDropdownList.projectAdminContains'
                )
              );
            }}
          />
        </Form.Item>
        {!isUserRequired && (
          <div className="text-warning mb-15">
            {WARNINGS.PROJECT_ADMIN_AUTO_ASSIGNED}
          </div>
        )}
        <Form.Item name="agency" label="Agency" rules={[required]}>
          <CommonDropdown
            className="mr-3"
            placeholder="Select agencies"
            allowClear
            mode="multiple"
            showSearch
            optionFilterProp="children"
            query={GET_PROJECT_AGENCY_FOR_EQC_TYPES}
            responsePath="projectEqcTypeAgencyDropdownList.data"
            valuePath="id"
            labelPath="agency.name"
            optionKey="agency"
            variables={{ filter: { projectId } }}
            fetchPolicy="network-only"
            onChange={(values, records) => {
              setExistingAgencies(
                map(records, ({ agency: { name: agencyName }, id }) =>
                  JSON.stringify({ agencyId: id, agencyName })
                )
              );
            }}
          />
        </Form.Item>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditProjectEqcTypeModal;
