import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Row } from 'antd';
import { debounce, filter, findIndex, includes, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import { MinusCircle } from '../../../assets/svg';
import {
  ACCESS_TYPE,
  ADD_PROJECT_STEPS_KEYS,
  BREAKPOINTS,
  DROPDOWN_LIMIT,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  SCROLL_CONST,
  TAB_KEYS
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { formValidatorRules } from '../../../common/utils';
import CommonSelect from '../../../components/CommonSelect';
import HasAccess from '../../../components/HasAccess';
import LoaderComponent from '../../../components/LoaderComponent';
import { ONBOARD_PROJECT_AGENCIES } from '../graphql/Mutation';
import {
  GET_ALL_AGENCIES,
  GET_ONBOARDED_PROJECT_AGENCY_LIST
} from '../graphql/Queries';

const { Option } = CommonSelect;
const { required } = formValidatorRules;
let searchDebounce = null;

const AddProjectAgencies = ({ projectId }) => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [contacts, setContacts] = useState([]);
  const [existingIds, setExistingIds] = useState([]);
  const [remainingAgencies, setRemainingAgencies] = useState([]);
  const [allAgencies, setAllAgencies] = useState([]);
  const history = useHistory();
  const hasInspectionAccess = HasAccess({ type: ACCESS_TYPE.INSPECTION });
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');

  const [fetchAllAgencies, { loading: allAgenciesLoading }] = useLazyQuery(
    GET_ALL_AGENCIES,
    {
      fetchPolicy: 'network-only',
      variables: { filter: { projectId } },
      onCompleted: (res) => {
        const data = res?.agencyDropdownList?.data;
        if (currentPage === 0) {
          setRemainingAgencies(data);
          setAllAgencies(data);
        } else {
          setRemainingAgencies((oldData) => [...oldData, ...data]);
          setAllAgencies((oldData) => [...oldData, ...data]);
        }
        setCurrentPage((page) => page + 1);
        setHasMore(data?.length >= DROPDOWN_LIMIT);
      }
    }
  );
  const { data: existingAgencies, loading: projectAgenciesLoading } = useQuery(
    GET_ONBOARDED_PROJECT_AGENCY_LIST,
    {
      fetchPolicy: 'network-only',
      variables: { filter: { projectId } }
    }
  );
  const [onboardProjectUsers, { loading: mutationLoading }] = useMutation(
    ONBOARD_PROJECT_AGENCIES,
    {
      onError() {},
      onCompleted: () => {
        Event(GA_EVENT.ONBOARD_PROJECT_AGENCIES, {
          label: GA_LABEL.ONBOARD_PROJECT_AGENCIES,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        if (hasInspectionAccess) {
          history.push(
            `${ROUTES.ADD_PROJECTS}/${Number(projectId)}/${
              ADD_PROJECT_STEPS_KEYS.EQC_TYPES
            }`
          );
        } else {
          history.push(
            `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.INSTRUCTION}`
          );
        }
      }
    }
  );
  useEffect(() => {
    fetchAllAgencies({
      variables: {
        filter: {
          skip: currentPage * DROPDOWN_LIMIT,
          limit: DROPDOWN_LIMIT,
          projectId
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formSubmit = ({ projectAgencies }) => {
    const data = map(projectAgencies, ({ agencyId, contactIds }) => {
      return {
        contactIds,
        agencyId: Number(agencyId),
        projectId,
        projectEqcTypeIds: []
      };
    });
    onboardProjectUsers({
      variables: { data }
    });
  };

  useEffect(() => {
    if (existingAgencies?.onboardProjectAgencyList?.data?.length) {
      const tempArr = [];
      const contactIds = [];
      const formData = map(
        existingAgencies?.onboardProjectAgencyList?.data,
        (agency) => {
          const agencyIndex = findIndex(
            remainingAgencies,
            (remainingAgency) => agency?.agency?.id === remainingAgency?.id
          );
          if (agencyIndex !== -1) {
            contactIds.push(remainingAgencies[agencyIndex]?.contacts);
          } else {
            contactIds.push(agency?.agency?.contacts);
          }
          tempArr.push(agency?.agency?.id);
          return {
            agencyId: agency?.agency?.id,
            contactIds: map(
              agency?.projectAgencyContacts,
              ({ contact: { id } }) => id
            )
          };
        }
      );
      setContacts(contactIds);
      setExistingIds(tempArr);
      form.setFieldsValue({ projectAgencies: formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingAgencies]);
  const searchQuery = (search) => {
    setSearchText(search);
    setCurrentPage(0);
    fetchAllAgencies({
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          skip: 0,
          limit: DROPDOWN_LIMIT,
          search,
          projectId
        }
      }
    });
  };
  const updateAgencyList = (value, index) => {
    const newIds = [...existingIds];
    newIds[index] = value;
    setExistingIds(newIds);
  };
  const handleAgency = (value, index) => {
    const formData = form.getFieldValue('projectAgencies');
    formData[index].contactIds = [];
    form.setFieldsValue({ projectAgencies: formData });
    const agencyListIndex = findIndex(allAgencies, ({ id }) => id === value);
    const oldContacts = [...contacts];
    oldContacts[index] = allAgencies?.[agencyListIndex]?.contacts;
    setContacts(oldContacts);
    updateAgencyList(value, index);
    if (searchText) {
      searchQuery();
    }
  };

  useEffect(() => {
    if (existingIds?.length && allAgencies?.length) {
      const remainingAgency = filter(
        allAgencies,
        (agency) => !includes(existingIds, agency.id)
      );
      setRemainingAgencies(remainingAgency);
    }
  }, [existingIds, allAgencies]);

  const getAddedAgency = (index) => {
    const dataIndex = findIndex(
      existingAgencies?.onboardProjectAgencyList?.data,
      (agency) => agency?.agency?.id === existingIds[index]
    );
    if (dataIndex === -1) {
      return null;
    }
    return (
      <Option key={`agency-${existingIds[index]}`} value={existingIds[index]}>
        {
          existingAgencies?.onboardProjectAgencyList?.data?.[dataIndex]?.agency
            ?.name
        }
      </Option>
    );
  };
  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && hasMore && !allAgenciesLoading) {
      fetchAllAgencies({
        variables: {
          filter: {
            skip: currentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            search: searchText,
            projectId
          }
        }
      });
    }
  };

  const handleSearch = (value) => {
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(value);
  };
  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };
  if (projectAgenciesLoading) return <LoaderComponent setHeight={0} />;
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={formSubmit}
        initialValues={{
          projectAgencies: [{ agencyId: undefined, contactIds: undefined }]
        }}
      >
        <Row
          gutter={isDesktopViewport ? 30 : 8}
          align="start"
          className="mb-10"
        >
          <Col span={11}>
            <div>
              <span className="required-mark">*</span>
              <span>Agencies</span>
            </div>
          </Col>
          <Col span={11}>
            <span className="required-mark">*</span>
            <span>Contact</span>
          </Col>
        </Row>
        <Form.List name="projectAgencies">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Row
                    gutter={isDesktopViewport ? 30 : 8}
                    key={field.key}
                    align="start"
                    wrap={false}
                  >
                    <Col span={11}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'agencyId']}
                        fieldKey={[field.fieldKey, 'agencyId']}
                        rules={[required]}
                      >
                        <CommonSelect
                          showSearch
                          optionFilterProp="children"
                          allowClear
                          onChange={(value) => handleAgency(value, index)}
                          onPopupScroll={handleScroll}
                          onSearch={handleSearch}
                          onClear={handleClear}
                          dropdownMatchSelectWidth={false}
                        >
                          {map(remainingAgencies, (agency) => (
                            <Option
                              key={`agency-${agency?.id}`}
                              value={agency?.id}
                            >
                              {agency?.name}
                            </Option>
                          ))}
                          {getAddedAgency(index)}
                        </CommonSelect>
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'contactIds']}
                        fieldKey={[field.fieldKey, 'contactIds']}
                        rules={[required]}
                      >
                        <CommonSelect
                          showSearch
                          optionFilterProp="children"
                          allowClear
                          mode="multiple"
                          dropdownMatchSelectWidth={false}
                          placement="bottomRight"
                        >
                          {map(contacts[index], ({ id, name }) => (
                            <Option key={`contact-${id}`} value={id}>
                              {name}
                            </Option>
                          ))}
                        </CommonSelect>
                      </Form.Item>
                    </Col>
                    {fields?.length > 1 && (
                      <Col>
                        <Form.Item>
                          <MinusCircle
                            onClick={() => {
                              remove(field.name);
                              updateAgencyList('', index);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                ))}
                <Button
                  className="p-0"
                  type="link"
                  onClick={() => {
                    add();
                  }}
                >
                  <div className="gx-text-underline">+ Add Agency</div>
                </Button>
              </div>
            );
          }}
        </Form.List>
        <Row justify="end">
          <Col className="form-buttons">
            <Button
              shape="round"
              onClick={() => {
                Event(GA_EVENT.CLICK_PREVIOUS_BUTTON, {
                  label: `While onboard agencies in new project`,
                  // eslint-disable-next-line no-undef
                  pathname: window?.location?.href,
                  project_id: projectId,
                  user_id: currentUser?.id,
                  user_name: currentUser?.name,
                  tenant_id: currentUser?.tenantUser?.tenant?.id,
                  tenant_name: currentUser?.tenantUser?.tenant?.organizationName
                });
                history.push(
                  `${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.USERS}`
                );
              }}
              className="cancel-button"
            >
              Previous
            </Button>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              loading={mutationLoading}
            >
              {hasInspectionAccess ? 'Next' : 'Add Project'}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddProjectAgencies;
