import { Button, Card, Descriptions, Image, Row, Space, Tag } from 'antd';
import clsx from 'clsx';
import * as eva from 'eva-icons';
import { includes, map, nth, takeRight, toUpper } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AudioIcon, MessageIcon } from '../../assets/svg';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  QCSTATUS,
  STAGE_STATUS
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { titleCase } from '../../common/utils';
import AudioPlayerModal from '../../components/AudioPlayerModal';
import EllipsisText from '../../components/EllipsisText';
import CommentModal from './CommentModal';
import PreviousCommentModal from './PreviousCommentModal';

const ApproverStageItemDetails = ({
  data,
  refetch,
  commentData,
  setCommentData
}) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showModal, setShowModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [index, setIndex] = useState();
  const [stageItemData, setStageItemData] = useState();
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [audios, setAudios] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const stageData = data?.getEqcStageHistoryWithoutAuth?.itemHistory;
  const previousHistoryData =
    data?.getEqcStageHistoryWithoutAuth?.previousHistoryData;
  const generalCommentData = takeRight(
    previousHistoryData,
    previousHistoryData ? previousHistoryData.length - 1 : 0
  );
  const eqcStageItemIds = map(commentData?.eqcStageItems, 'eqcStageItemId');
  const getPhotos = (photos, imageId) => {
    return photos?.length ? (
      <Image.PreviewGroup
        preview={{
          visible: imageId === previewImageKey && isVisible,
          onVisibleChange: (visible) => {
            setIsVisible(visible);
            if (visible) {
              Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                label: GA_LABEL.OPENED_IMAGE_VIEWER,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href
              });
            }
          }
        }}
      >
        <Space className="image-preview-wrapper">
          {React.Children.map(photos, (photo) => (
            <Image
              src={photo}
              alt="logo"
              height={isDesktopViewport ? '64px' : '32px'}
              width={isDesktopViewport ? '64px' : '32px'}
              onClick={() => setPreviewImageKey(imageId)}
            />
          ))}
          {photos?.length - 1 > 0 && (
            <div
              className="pointer extra-count"
              onClick={() => {
                setIsVisible(true);
                setPreviewImageKey(imageId);
              }}
            >{`+ ${photos?.length - 1}`}</div>
          )}
        </Space>
      </Image.PreviewGroup>
    ) : (
      'N/A'
    );
  };
  const eqcStatus = (status) => {
    switch (status) {
      case toUpper(QCSTATUS.PASS):
        return <Tag className="active-tag">{titleCase(QCSTATUS.PASS)}</Tag>;
      case toUpper(QCSTATUS.SKIP):
        return <Tag className="yellow-tag">{titleCase(QCSTATUS.SKIP)}</Tag>;
      case toUpper(QCSTATUS.NOT_RECORDED):
        return (
          <Tag className="inactive-tag">{titleCase(QCSTATUS.NOT_RECORDED)}</Tag>
        );
      default:
        <Tag color="red">{titleCase(QCSTATUS.FAIL)}</Tag>;
        break;
    }
  };
  return (
    <>
      {showModal && (
        <PreviousCommentModal
          showModal={showModal}
          setShowModal={setShowModal}
          data={generalCommentData}
          index={index}
          stageItemData={stageItemData}
        />
      )}
      {showCommentModal && (
        <CommentModal
          showModal={showCommentModal}
          setShowModal={setShowCommentModal}
          stageItemData={stageItemData}
          data={data}
          refetch={refetch}
          setCommentData={setCommentData}
          commentData={commentData}
        />
      )}
      <div className="approver-stage-details">
        <div className="approver-stage-details-header d-flex">
          <i data-eva="checkmark-square-outline" className="mr-16" />
          <h2>QC Checklist Point</h2>
        </div>
        <div>
          {map(stageData, (stageItem, stageIndex) => {
            const hasPreviousComments = !!generalCommentData.filter(
              (revision) => {
                const temp = nth(revision.itemHistory, stageIndex || 0);
                return !!temp.generalRemark;
              }
            ).length;
            const lastCommentData = nth(
              nth(previousHistoryData, 0)?.itemHistory,
              stageIndex || 0
            );
            return (
              <Card className="mb-24" key={stageItem?.eqcStageItemId}>
                <div>
                  <h3 className="mb-24">{`${stageIndex + 1}.  ${
                    stageItem.title
                  }`}</h3>
                </div>
                <div>
                  <div
                    className={
                      isDesktopViewport
                        ? 'd-flex justify-between align-end'
                        : 'd-flex flex-vertical'
                    }
                  >
                    <Descriptions
                      layout="vertical"
                      column={isDesktopViewport ? 10 : 4}
                    >
                      <Descriptions.Item
                        span={2}
                        label="Review"
                        className={clsx(!isDesktopViewport && 'common-item')}
                      >
                        <EllipsisText text={stageItem?.qcOption || 'N/A'} />
                      </Descriptions.Item>
                      <Descriptions.Item span={2} label="Remarks">
                        <EllipsisText text={stageItem?.remarks || 'N/A'} />
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={2}
                        label="Audio Remarks"
                        className={clsx(!isDesktopViewport && 'common-item')}
                      >
                        {!stageItem?.remarkAudios ||
                        stageItem?.remarkAudios?.length === 0 ||
                        stageItem?.remarkAudios === null ? (
                          'N/A'
                        ) : (
                          <div
                            className="audio-player-wrapper"
                            onClick={() => {
                              setShowAudioModal(true);
                              setAudios(stageItem?.remarkAudios);
                              Event(GA_EVENT.DOWNLOAD_AUDIO, {
                                label: GA_LABEL.DOWNLOAD_AUDIO,
                                // eslint-disable-next-line no-undef
                                pathname: window?.location?.href
                              });
                            }}
                          >
                            <AudioIcon />
                            {stageItem?.remarkAudios?.length - 1 > 0 && (
                              <div className="extra-count">{`+${
                                stageItem?.remarkAudios?.length - 1
                              }`}</div>
                            )}
                          </div>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={2}
                        label="Photos"
                        className={clsx(!isDesktopViewport && 'common-item')}
                      >
                        {stageItem?.photos?.length === 0 ||
                        stageItem?.photos === null ? (
                          'N/A'
                        ) : (
                          <>
                            {getPhotos(
                              stageItem?.photos,
                              stageItem?.eqcStageItemId
                            )}
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={2}
                        label="Status"
                        className={clsx(!isDesktopViewport && 'common-item')}
                      >
                        <div>
                          {stageItem?.qcStatus &&
                            eqcStatus(stageItem?.qcStatus)}
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                    {data &&
                      data?.getEqcStageHistoryWithoutAuth?.status ===
                        STAGE_STATUS.APPROVAL_PENDING && (
                        <div>
                          <Button
                            type="primary"
                            shape="round"
                            icon={<MessageIcon />}
                            onClick={() => {
                              setShowCommentModal(true);
                              setStageItemData(stageItem);
                            }}
                            className={`${
                              !isDesktopViewport
                                ? 'width-percent-100 justify-center mb-24'
                                : 'mb-24'
                            } ${
                              includes(
                                eqcStageItemIds,
                                stageItem?.eqcStageItemId
                              ) && 'general-comment'
                            }`}
                          >
                            Comment
                          </Button>
                        </div>
                      )}
                  </div>
                  {data?.getEqcStageHistoryWithoutAuth?.status !==
                    STAGE_STATUS.APPROVAL_PENDING &&
                  (stageItem?.generalRemark ||
                    stageItem?.generalRemarkImages?.length > 0) ? (
                    <Card className="comment-card mt-24">
                      {stageItem?.generalRemark && (
                        <div className="mb-24">
                          <div className="d-flex align-center justify-between mb-5">
                            <h4>Comment </h4>
                          </div>
                          <EllipsisText
                            text={stageItem?.generalRemark || '-'}
                          />
                        </div>
                      )}
                      {stageItem?.generalRemarkImages &&
                        stageItem.generalRemarkImages?.length > 0 && (
                          <Row className="flex-vertical">
                            <h4>Comment Images</h4>
                            <div
                              className={
                                isDesktopViewport
                                  ? 'd-flex justify-between align-end'
                                  : 'd-flex flex-vertical'
                              }
                            >
                              <div className="mb-24 mt-5">
                                {getPhotos(
                                  stageItem?.generalRemarkImages,
                                  stageItem?.generalRemark
                                )}
                              </div>
                            </div>
                          </Row>
                        )}
                      <div className="width-percent-100">
                        <h4>Reviewed By</h4>
                        <div className="mt-5 d-flex align-center justify-between">
                          <div>{stageItem?.approverName || '-'}</div>
                          <div>{stageItem?.createdAt}</div>
                        </div>
                      </div>
                    </Card>
                  ) : (
                    ''
                  )}
                  {lastCommentData?.generalRemark ||
                  lastCommentData?.generalRemarkImages?.length > 0 ? (
                    <Card className="comment-card">
                      {lastCommentData?.generalRemark && (
                        <div className="mb-24">
                          <div className=" mb-5">
                            <h4>Last Comment </h4>
                          </div>
                          <EllipsisText
                            text={lastCommentData?.generalRemark || '-'}
                          />
                        </div>
                      )}
                      {lastCommentData?.generalRemarkImages &&
                        lastCommentData.generalRemarkImages?.length > 0 && (
                          <Row className="flex-vertical mb-24">
                            <h4>Last Comment Images</h4>
                            <div className="d-flex justify-between mt-5">
                              {getPhotos(
                                lastCommentData?.generalRemarkImages,
                                lastCommentData?.projectEqcTypeStageItemId
                              )}
                            </div>
                          </Row>
                        )}
                      <Row justify="space-between">
                        <div className={`width-percent-100 `}>
                          <h4>Reviewed By</h4>
                          <div className="mt-5 d-flex align-center justify-between">
                            <div>{lastCommentData?.approverName || '-'}</div>
                            <div>{lastCommentData?.createdAt}</div>
                          </div>
                        </div>
                        {hasPreviousComments && (
                          <div>
                            <Button
                              shape="round"
                              className={
                                !isDesktopViewport
                                  ? 'previous-comment width-percent-100 justify-center'
                                  : 'previous-comment'
                              }
                              onClick={() => {
                                setShowModal(true);
                                setIndex(stageIndex);
                                setStageItemData(stageItem);
                              }}
                            >
                              View All Previous Comments
                            </Button>
                          </div>
                        )}
                      </Row>
                    </Card>
                  ) : (
                    hasPreviousComments && (
                      <div>
                        <Button
                          shape="round"
                          className={
                            !isDesktopViewport
                              ? 'previous-comment width-percent-100 justify-center'
                              : 'previous-comment'
                          }
                          onClick={() => {
                            setShowModal(true);
                            setIndex(stageIndex);
                            setStageItemData(stageItem);
                          }}
                        >
                          View All Previous Comments
                        </Button>
                      </div>
                    )
                  )}
                </div>
              </Card>
            );
          })}
        </div>
        {audios?.length > 0 && (
          <AudioPlayerModal
            audios={audios}
            showModal={showAudioModal}
            setShowModal={setShowAudioModal}
          />
        )}
      </div>
    </>
  );
};

export default ApproverStageItemDetails;
