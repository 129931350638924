import { useMutation } from '@apollo/client';
import { Button, Col, Divider, Form, Input, Modal, Row, Switch } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { filter, forEach, keys, map, omit, toLower } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from '../../../../../../../../apollo';
import { AppContext } from '../../../../../../../../AppContext';
import { DeleteButton } from '../../../../../../../../assets/svg';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  TYPES,
  TYPES_LABELS
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../../../common/utils';
import CommonSelect from '../../../../../../../../components/CommonSelect';
import TextEditor from '../../../../../../../../components/TextEditor';
import {
  CREATE_PROJECT_EQC_STAGE_ITEM,
  UPDATE_PROJECT_EQC_STAGE_ITEM
} from '../../../../../../graphql/Mutation';

const { required } = formValidatorRules;
const { Option } = CommonSelect;

const YesNoUi = (isDesktopViewport, isDisabled) => {
  return (
    <>
      <Row gutter={20}>
        <Col span={isDesktopViewport ? 8 : 12}>
          <Form.Item label="Value">
            <Input allowClear placeholder="Yes" disabled />
          </Form.Item>
        </Col>
        <Col span={isDesktopViewport ? 8 : 12}>
          <Form.Item name="yesOption" label=" " valuePropName="checked">
            <Checkbox disabled={isDisabled}>Mark for QC fail</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={isDesktopViewport ? 8 : 12}>
          <Form.Item label="Value">
            <Input allowClear placeholder="No" disabled defaultValue="No" />
          </Form.Item>
        </Col>
        <Col span={isDesktopViewport ? 8 : 12}>
          <Form.Item name="noOption" label=" " valuePropName="checked">
            <Checkbox checked disabled={isDisabled}>
              Mark for QC fail
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const OptionUi = (isDesktopViewport, isDisabled) => {
  return (
    <>
      <Form.List name="options">
        {(fields, { add, remove }) => {
          return (
            <>
              <Row gutter={[15, 15]} className="width-percent-100">
                {fields.map((field) => (
                  <Col span={24} key={field.key}>
                    <Row gutter={[30]}>
                      <Col flex="auto">
                        <Form.Item
                          {...field}
                          label="Value"
                          name={[field.name, 'option']}
                          fieldKey={[field.fieldKey, 'option']}
                          validateTrigger={['onChange', 'onClick']}
                          rules={[
                            required,
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                const items = map(
                                  getFieldValue()?.options,
                                  (item) => item?.option
                                );
                                if (
                                  filter(
                                    items,
                                    (val) => toLower(val) === toLower(value)
                                  )?.length > 1 &&
                                  value
                                ) {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  return Promise.reject(
                                    'should be a unique option'
                                  );
                                }
                                return Promise.resolve();
                              }
                            })
                          ]}
                        >
                          <Input
                            placeholder="Enter  Value"
                            disabled={isDisabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col className="d-flex align-center">
                        <Form.Item
                          {...field}
                          label={isDesktopViewport && ' '}
                          name={[field.name, 'qcFail']}
                          fieldKey={[field.fieldKey, 'qcFail']}
                          valuePropName="checked"
                          className="mr-10 mb-0"
                        >
                          <Checkbox disabled={isDisabled}>
                            Mark for QC fail
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          label={isDesktopViewport && ' '}
                          className="mb-0"
                        >
                          <DeleteButton
                            className="pointer"
                            onClick={() => {
                              if (!isDisabled && fields?.length > 2)
                                remove(field?.name);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                ))}

                <Row>
                  <Col className="mb-15">
                    <Button
                      className="grey-button"
                      shape="round"
                      disabled={isDisabled}
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      Add Option
                    </Button>
                  </Col>
                </Row>
              </Row>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

const AddEditModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    stageData,
    setStageData,
    stageId,
    refetchStageDataWithInitialValues,
    isDisabled
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [displayOption, setDisplayOption] = useState(true);
  const [imageLoader, setImageLoader] = useState(false);
  const [isYesNoType, setIsYesNoType] = useState(true);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [displayOptionValue, setDisplayOptionValue] = useState(
    YesNoUi(isDesktopViewport)
  );
  const { projectId } = useParams();

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };
  const toggleImageLoader = () => {
    setImageLoader((value) => !value);
  };
  const [createStage, { loading: createLoading }] = useMutation(
    CREATE_PROJECT_EQC_STAGE_ITEM,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_PROJECT_EQC_TYPE_STAGE_ITEM, {
          label: GA_LABEL.ADD_PROJECT_EQC_TYPE_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setStageData();
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      }
    }
  );

  const [updateStage, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT_EQC_STAGE_ITEM,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_PROJECT_EQC_TYPE_STAGE_ITEM, {
          label: GA_LABEL.EDIT_PROJECT_EQC_TYPE_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageId,
          stage_item_id: stageData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setStageData();
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      }
    }
  );

  const handleTypeChange = (options) => {
    switch (options?.value) {
      case TYPES['Y/N']:
        setDisplayOption(true);
        setIsYesNoType(true);
        setDisplayOptionValue(YesNoUi(isDesktopViewport, isDisabled));
        break;
      case TYPES.OPTION:
        setDisplayOption(true);
        setIsYesNoType(false);
        setDisplayOptionValue(OptionUi(isDesktopViewport, isDisabled));
        break;
      default:
        setDisplayOption(false);
        setIsYesNoType(false);
        break;
    }
  };
  const handleSelect = (labeledValue, options) => {
    handleTypeChange(options);
    if (labeledValue === TYPES.OPTION) {
      form.setFieldsValue({ options: [{}, { qcFail: true }] });
    }
  };
  useEffect(() => {
    if (isUpdate) {
      handleTypeChange({ value: stageData?.type });
      if (stageData?.type === 'Y/N') {
        form.setFieldsValue({ yesOption: stageData?.options[0]?.qcFail });
        form.setFieldsValue({ noOption: stageData?.options[1]?.qcFail });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);
  const onAgencySubmitFinish = async (formValues) => {
    if (
      formValues?.description?.replace(/<(.|\n)*?>/g, '').trim().length === 0 &&
      !formValues?.description?.includes('<img')
    ) {
      form.setFieldsValue('description', '');
    }
    let options = [];
    if (formValues.type === 'Y/N') {
      options = [
        {
          option: 'Yes',
          qcFail: formValues.yesOption
        },
        {
          option: 'No',
          qcFail: formValues.noOption
        }
      ];
    }
    if (formValues.type === 'OPTION') {
      forEach(formValues.options, (option) => {
        const typeOptionValue = {
          option: option.option,
          qcFail: option.qcFail || false
        };
        options.push(typeOptionValue);
      });
      const checkCheckedValue = (value) => {
        return value.qcFail === false;
      };
      const isValid = options.some(checkCheckedValue);
      if (!isValid) {
        toast({
          message: 'At least one option should be marked as QcPass',
          type: 'error'
        });
        return;
      }
    }

    let newFormValues = {
      title: formValues.title,
      description:
        formValues?.description?.replace(/<(.|\n)*?>/g, '').trim().length ===
          0 && !formValues?.description?.includes('<img')
          ? ''
          : formValues?.description,
      type: formValues?.type,
      photo: formValues?.photo || false,
      remark: formValues?.remark || false,
      options,
      projectEqcTypeStageId: Number(stageId)
    };
    if (isDisabled) {
      newFormValues = omit(newFormValues, ['title', 'type', 'options']);
    }
    const variables = isUpdate
      ? { data: newFormValues, id: stageData.id }
      : newFormValues;

    try {
      if (isUpdate) {
        await updateStage({
          variables: variables
        });
        return;
      }
      await createStage({
        variables: {
          data: variables
        }
      });
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    if (showModal && isUpdate) {
      form.setFieldsValue({
        ...stageData,
        description: stageData?.description || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      className="stageitem-modal"
      footer={null}
      closable={false}
      width={isDesktopViewport ? 700 : '90vw'}
    >
      <h2 className="mb-15">
        {isUpdate ? 'Edit Stage Item' : 'Add Stage Item'}
      </h2>
      <Form
        form={form}
        initialValues={
          isUpdate
            ? { description: '' }
            : {
                type: 'Y/N',
                noOption: true,
                options: [{}, { qcFail: true }],
                description: ''
              }
        }
        layout="vertical"
        onFinish={onAgencySubmitFinish}
      >
        <Form.Item
          rules={[
            required,
            {
              max: 500,
              message: 'Title cannot be more than 500 characters'
            }
          ]}
          name="title"
          label="Checklist Point"
        >
          <Input
            allowClear
            placeholder="Enter Checklist Point"
            disabled={isDisabled}
          />
        </Form.Item>
        <Form.Item name="description" label="Hint">
          <TextEditor toggleImageLoader={toggleImageLoader} />
        </Form.Item>
        <Row gutter={30}>
          <Col span={isDesktopViewport ? 12 : 24}>
            <Form.Item name="type" label="Type">
              <CommonSelect
                className="mr-3"
                placeholder="Select Type"
                onSelect={handleSelect}
                disabled={isDisabled}
              >
                {keys(TYPES_LABELS).map((type) => {
                  return (
                    <Option key={TYPES_LABELS[type]} value={type}>
                      {TYPES_LABELS[type]}
                    </Option>
                  );
                })}
              </CommonSelect>
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 6 : 12}>
            <Form.Item
              name="photo"
              label="EQC Photo Required"
              valuePropName="checked"
            >
              <Switch defaultChecked={isUpdate && stageData?.photo} />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 6 : 12}>
            <Form.Item
              name="remark"
              label="Remarks Required"
              valuePropName="checked"
            >
              <Switch defaultChecked={isUpdate && stageData?.remark} />
            </Form.Item>
          </Col>
        </Row>
        {displayOption && (
          <>
            <Divider dashed />
            <h3>{isYesNoType ? 'Yes/No' : 'Options'}</h3>
            {displayOptionValue}
          </>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            disabled={imageLoader}
            loading={createLoading || updateLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditModal;
