import { Card, Image, Modal } from 'antd';
import { map, nth } from 'lodash';
import moment from 'moment';
import React from 'react';
import { DATETIMEWITHDIVIDE, GA_EVENT, GA_LABEL } from '../../common/constants';
import { Event } from '../../common/trackEvents';
import EllipsisText from '../../components/EllipsisText';

const PreviousCommentModal = (props) => {
  const { showModal, setShowModal, data, index, stageItemData } = props;

  const handleCancel = () => {
    setShowModal(false);
  };
  const tempData = [...data];

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      onCancel={handleCancel}
      footer={null}
    >
      <div>
        <h2>Previous Comments</h2>
        {tempData && tempData.length > 0 ? (
          map(tempData, (val, serialNo) => {
            const temp = nth(val.itemHistory, index || 0);
            const {
              generalRemark = '',
              generalComment = '',
              createdAt = '',
              generalCommentImages = [],
              generalRemarkImages = [],
              approverName
            } = temp;

            if (stageItemData && stageItemData.createdBy) {
              if (generalRemark || generalRemarkImages?.length) {
                return (
                  <div key={serialNo} className="previous-comment-wrap">
                    <Card className="comment-card mb-15">
                      <p>
                        <div className="comment-time">
                          {moment(createdAt, 'YYYY-MM-DD HH:mm Z').format(
                            DATETIMEWITHDIVIDE
                          )}
                        </div>
                        <EllipsisText text={generalRemark || '-'} />
                      </p>
                      <div className="mb-10">
                        <Image.PreviewGroup
                          preview={{
                            onVisibleChange: (visible) => {
                              if (visible) {
                                Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                                  label: GA_LABEL.OPENED_IMAGE_VIEWER,
                                  // eslint-disable-next-line no-undef
                                  pathname: window?.location?.href
                                });
                              }
                            }
                          }}
                        >
                          {React.Children.map(generalRemarkImages, (img) => {
                            return (
                              <Image
                                src={img}
                                alt="logo"
                                height="64px"
                                width="64px"
                              />
                            );
                          })}
                        </Image.PreviewGroup>
                      </div>
                      <div>
                        <b>Reviewed By:</b> {approverName}
                      </div>
                    </Card>
                  </div>
                );
              }
              return null;
            }

            if (generalComment || generalCommentImages?.length) {
              return (
                <div key={serialNo} className="previous-comment-wrap">
                  <Card className="comment-card mb-15">
                    <div className="comment-time">
                      {moment(createdAt, 'YYYY-MM-DD HH:mm Z').format(
                        DATETIMEWITHDIVIDE
                      )}
                    </div>
                    <EllipsisText text={generalComment || '-'} />
                    <div className="mb-10">
                      <Image.PreviewGroup
                        preview={{
                          onVisibleChange: (visible) => {
                            if (visible) {
                              Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                                label: GA_LABEL.OPENED_IMAGE_VIEWER,
                                // eslint-disable-next-line no-undef
                                pathname: window?.location?.href
                              });
                            }
                          }
                        }}
                      >
                        {React.Children.map(generalCommentImages, (img) => {
                          return (
                            <Image
                              src={img}
                              alt="logo"
                              height="64px"
                              width="64px"
                            />
                          );
                        })}
                      </Image.PreviewGroup>
                    </div>
                    <div>
                      <b>Reviewed By:</b> {approverName}
                    </div>
                  </Card>
                </div>
              );
            }

            return null;
          })
        ) : (
          <div className="previous-comment-wrap">
            <p className="previous-comment-p2">No Comments Found</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PreviousCommentModal;
