import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import { includes, values } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  ADD_USER_ROLES,
  GA_EVENT,
  GA_LABEL,
  REGEX,
  USER_ROLES
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import {
  formatPhoneNumberWithoutMask,
  formValidatorRules,
  titleCase
} from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import { CREATE_USER, UPDATE_USER } from './graphql/Mutations';

const { required, email } = formValidatorRules;
const { Option } = CommonSelect;

const AddUserModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    userData,
    setUserData,
    refetchUserDataWithInitialValues
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [showChecklistMakerNote, setShowChecklistMakerNote] = useState(false);
  const [form] = Form.useForm();
  const [createUserMutate, { loading: createUserLoading }] = useMutation(
    CREATE_USER,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_GLOBAL_USER, {
          label: GA_LABEL.ADD_GLOBAL_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setUserData();
        form.resetFields();
        setShowModal(false);
        refetchUserDataWithInitialValues();
      }
    }
  );

  const [updateUserMutate, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_GLOBAL_USER, {
          label: GA_LABEL.EDIT_GLOBAL_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          updated_user_id: userData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setUserData();
        form.resetFields();
        setShowModal(false);
        refetchUserDataWithInitialValues();
      }
    }
  );

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setUserData();
  };

  const onUserSubmitFinish = async (formValues) => {
    const newFormValues = {
      ...formValues,
      phoneNo: formatPhoneNumberWithoutMask(formValues.phoneNo)
    };

    const variables = isUpdate
      ? { data: newFormValues, id: userData.id }
      : newFormValues;

    try {
      if (isUpdate) {
        updateUserMutate({
          variables: { ...variables }
        });
        return;
      }
      createUserMutate({
        variables: { data: { ...variables } }
      });
    } catch (error) {
      return error;
    }
  };

  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };
  const handleSelect = (value) => {
    if (isUpdate) {
      if (
        !includes(userData?.roles, ADD_USER_ROLES.CHECKLIST_MAKER) &&
        value === ADD_USER_ROLES.CHECKLIST_MAKER
      ) {
        setShowChecklistMakerNote(true);
      } else {
        setShowChecklistMakerNote(false);
      }
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      confirmLoading={createUserLoading || updateUserLoading}
      onOk={handleAdd}
      className="dialog"
      okText={isUpdate ? 'Save' : 'Add'}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <h2>{isUpdate ? 'Edit user' : 'Add user'}</h2>
      <Form
        form={form}
        initialValues={userData || { isActive: true }}
        layout="vertical"
        onFinish={onUserSubmitFinish}
      >
        <Form.Item
          rules={[
            required,
            {
              max: 250,
              message: 'Username cannot be more than 250 characters'
            }
          ]}
          name="name"
          label="Name"
        >
          <Input allowClear placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          rules={[{ required, message: 'Required' }]}
          name="roles"
          label="Roles"
        >
          <CommonSelect
            className="mr-3"
            placeholder="Select Role"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={handleSelect}
            disabled={includes(userData?.roles, USER_ROLES.SYSTEM_ADMIN)}
          >
            {values(ADD_USER_ROLES).map((role) => (
              <Option key={role} value={role}>
                {titleCase(role)}
              </Option>
            ))}
            {includes(userData?.roles, USER_ROLES.SYSTEM_ADMIN) && (
              <Option
                key={USER_ROLES.SYSTEM_ADMIN}
                value={USER_ROLES.SYSTEM_ADMIN}
              >
                {titleCase(USER_ROLES.SYSTEM_ADMIN)}
              </Option>
            )}
          </CommonSelect>
        </Form.Item>

        {!isUpdate && (
          <Form.Item rules={[required, email]} name="email" label="Email">
            <Input allowClear placeholder="Enter Email" />
          </Form.Item>
        )}
        <Form.Item
          label="Mobile Number"
          name="phoneNo"
          rules={[
            required,
            () => ({
              validator(rule, value) {
                if (value) {
                  // eslint-disable-next-line no-param-reassign
                  value = value.split(' ').join('');
                  const numberPattern = REGEX.PHONE;
                  if (!numberPattern.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('should be a valid phone number');
                  }
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input allowClear placeholder="Enter Mobile Number" />
        </Form.Item>
        {showChecklistMakerNote && (
          <div className="text-danger mb-20">
            <b>Caution:</b> Changing role to Checklist Maker will remove this
            user from all the assigned projects
          </div>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
