import { Divider } from 'antd';
import { map } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { GlobeIcon } from '../../../assets/svg';
import CommonSelect from '../../../components/CommonSelect';

const { Option } = CommonSelect;

const TenantSelect = () => {
  const { getTenantData, getCurrentTenant, changeTenant } = useContext(
    AppContext
  );
  const currentTenant = getCurrentTenant();
  const tenantData = getTenantData();
  const handleChange = (value) => {
    changeTenant(value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  };
  return (
    <div className="gx-avatar-row width-percent-100 d-flex justify-end align-center tenant-select-row mt-25">
      <div className="filter-wrapper">
        <GlobeIcon />
        <Divider type="vertical" />
        <CommonSelect
          className="width-200"
          onChange={handleChange}
          defaultValue={currentTenant}
        >
          {map(tenantData, (tenant) => (
            <Option value={tenant?.userTenant?.id} key={tenant?.id}>
              {tenant?.userTenant?.organizationName}
            </Option>
          ))}
        </CommonSelect>
      </div>
    </div>
  );
};

export default TenantSelect;
