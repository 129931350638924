import { useQuery } from '@apollo/client';
import { Image, Layout } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import logo from '../../assets/Logo.png';
import LoaderComponent from '../../components/LoaderComponent';
import { GET_REPORT_IMAGES } from './graphql/Queries';

const ReportImageViewer = () => {
  const { url } = useParams();
  const { Header } = Layout;

  const { data, loading } = useQuery(GET_REPORT_IMAGES, {
    variables: {
      urlString: url
    }
  });
  if (loading) return <LoaderComponent />;
  return (
    <div className="report-image-viewer">
      <Header>
        <h2 className="mb-0">{data?.reportImages?.reportName}</h2>
        <Image src={logo} alt="digiqc-logo" />
      </Header>
      <div className="report-image-viewer-content">
        <h2>{data?.reportImages?.imageType}</h2>
        <Image.PreviewGroup>
          {map(data?.reportImages?.images, (photo, index) => (
            <Image src={photo} alt="report-images" id={`image-${index}`} />
          ))}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default ReportImageViewer;
