import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USERS = gql`
  query userList($filter: UsersListFilter!) {
    userList(filter: $filter) {
      total
      data {
        id
        name
        email
        isDisabled
        isActive
        roles
        phoneNo
      }
    }
  }
`;
export const GET_USERS_DROPDOWN_LIST = gql`
  query userDropdownList($filter: UsersListFilter!) {
    userDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getLoggedInUser {
    getLoggedInUser {
      id
      name
      email
      password
      roles
      profileImage
      phoneNo
      createdBy
      profileImage
      isDisabled
      isActive
      forcePasswordReset
      tenants {
        id
        tenant {
          id
          organizationName
        }
      }
      projectUsers {
        id
        roles
      }
      tenantUser {
        id
        tenant {
          id
          organizationName
          adminEmail
          ownerName
          phoneNumber
          logo
          userLimit
          access
          subscription {
            id
            validTill
            status
            planObj {
              id
              name
            }
          }
        }
      }
    }
  }
`;
