import { Card, Col, Image, Row, Space } from 'antd';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { AudioIcon } from '../../../../../../../assets/svg';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import AudioPlayerModal from '../../../../../../../components/AudioPlayerModal';
import EllipsisText from '../../../../../../../components/EllipsisText';

const InstructionMoreDetails = ({ instructionData }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');

  return (
    <div className="instruction-tab-details">
      <Card className="instruction-tab-details-instruction-details ">
        <div className="d-flex align-center justify-between mb-15">
          <div className="d-flex">
            <h2>Issue Details</h2>
          </div>
        </div>
        <div className="mobile-card">
          <Row
            gutter={isDesktopViewport ? [75, 40] : [40, 30]}
            className="fw-medium mb-20"
          >
            <Col span={isDesktopViewport ? 10 : 24}>
              <div className="text-secondary mb-12">Remarks</div>
              <EllipsisText text={instructionData?.description || 'N/A'} />
            </Col>
            <Col span={isDesktopViewport ? 7 : 24}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">Photos</div>
                <div>
                  {instructionData?.descriptionPhoto?.length > 0 ? (
                    <Image.PreviewGroup
                      preview={{
                        visible:
                          instructionData?.description === previewImageKey &&
                          isVisible,
                        onVisibleChange: (visible) => {
                          setIsVisible(visible);
                          if (visible) {
                            Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                              label: GA_LABEL.OPENED_IMAGE_VIEWER,
                              // eslint-disable-next-line no-undef
                              pathname: window?.location?.href
                            });
                          }
                        }
                      }}
                    >
                      <Space className="image-preview-wrapper">
                        {React.Children.map(
                          instructionData?.descriptionPhoto,
                          (photo) => (
                            <Image
                              src={photo}
                              alt="logo"
                              height="64px"
                              width="64px"
                              onClick={() =>
                                setPreviewImageKey(instructionData?.description)
                              }
                            />
                          )
                        )}
                        {instructionData?.descriptionPhoto?.length > 1 && (
                          <div
                            className="pointer extra-count"
                            onClick={() => {
                              setIsVisible(true);
                              setPreviewImageKey(instructionData?.description);
                            }}
                          >
                            {`+${
                              instructionData?.descriptionPhoto?.length - 1
                            }`}
                          </div>
                        )}
                      </Space>
                    </Image.PreviewGroup>
                  ) : (
                    '-'
                  )}
                </div>
              </Space>
            </Col>
            <Col span={isDesktopViewport ? 7 : 24}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">Audio</div>
                <div>
                  {instructionData?.descriptionAudio?.length > 0 ? (
                    <div
                      className="audio-player-wrapper"
                      onClick={() => {
                        setShowAudioModal(true);
                        Event(GA_EVENT.DOWNLOAD_AUDIO, {
                          label: GA_LABEL.DOWNLOAD_AUDIO,
                          // eslint-disable-next-line no-undef
                          pathname: window?.location?.href
                        });
                      }}
                    >
                      <AudioIcon />
                      {instructionData?.descriptionAudio?.length > 1 && (
                        <div className="extra-count">{`+${
                          instructionData?.descriptionAudio?.length - 1
                        }`}</div>
                      )}
                    </div>
                  ) : (
                    'N/A'
                  )}
                </div>
              </Space>
            </Col>
          </Row>
        </div>
      </Card>
      {instructionData?.descriptionAudio?.length > 0 && (
        <AudioPlayerModal
          audios={instructionData?.descriptionAudio}
          showModal={showAudioModal}
          setShowModal={setShowAudioModal}
        />
      )}
    </div>
  );
};

export default InstructionMoreDetails;
