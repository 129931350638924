import { useLazyQuery } from '@apollo/client';
import { Empty } from 'antd';
import * as eva from 'eva-icons';
import { findIndex, isNaN } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../../AppContext';
import { ForwardArrow, ProjectIcon } from '../../../../../../../assets/svg';
import { ROUTES, TAB_KEYS } from '../../../../../../../common/constants';
import LoaderComponent from '../../../../../../../components/LoaderComponent';
import Error404 from '../../../../../../../Error404';
import {
  GET_PROJECT_EQC,
  GET_SINGLE_PROJECT
} from '../../../../../graphql/Queries';
import EqcStages from './EqcStages';
import EqcSummary from './EqcSummary';

const EqcDetails = () => {
  const { getCurrentUser, dispatch } = useContext(AppContext);
  const { projectId, eqcId } = useParams();
  const currentUserId = getCurrentUser()?.id || {};
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const eqcFilter = history?.location?.state?.eqcFilter;
  const eqcPagination = history?.location?.state?.eqcPagination;
  const eqcTypeRecord = history?.location?.state?.eqcTypeRecord;
  const userRecord = history?.location?.state?.userRecord;
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getWebProject, { data: projectData }] = useLazyQuery(
    GET_SINGLE_PROJECT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const index = findIndex(
          res?.getProject?.projectUsers,
          (user) => Number(user?.userId) === Number(currentUserId)
        );
        if (index !== -1) {
          const loggedInUserRole =
            res?.getProject?.projectUsers?.[index]?.roles;
          dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
        } else {
          dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
        }
      }
    }
  );

  useEffect(() => {
    if (!isNaN(Number(projectId))) {
      getWebProject({
        variables: {
          id: projectId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const [fetchEqcDataLazyQuery, { data: eqcData, loading }] = useLazyQuery(
    GET_PROJECT_EQC,
    {
      fetchPolicy: 'network-only'
    }
  );

  const fetchEqcData = () => {
    if (!isNaN(Number(eqcId))) {
      fetchEqcDataLazyQuery({
        variables: {
          id: eqcId
        }
      });
    }
  };

  useEffect(() => {
    fetchEqcData();
    return () => {
      dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isNaN(Number(projectId)) || isNaN(Number(eqcId))) {
    return <Error404 />;
  }
  return loading ? (
    <LoaderComponent />
  ) : (
    <>
      <div className="project-details">
        <div className="project-details-title justify-between page-header">
          <div className="project-details-title-text d-flex">
            <ProjectIcon
              onClick={() => {
                history.push(ROUTES.PROJECTS, {
                  navFilter,
                  navPagination
                });
              }}
              className="pointer"
            />
            <ForwardArrow />
            <h1
              className="pointer mr-10 text-primary"
              onClick={() => {
                history.push(
                  `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.EQC}`,
                  {
                    navFilter,
                    navPagination,
                    eqcFilter,
                    eqcPagination,
                    eqcTypeRecord,
                    userRecord
                  }
                );
              }}
            >
              {projectData?.getProject?.name}
            </h1>
            <ForwardArrow />
            <h1>{eqcData?.getEqc?.eqcName}</h1>
          </div>
        </div>
        {eqcData?.getEqc ? (
          <div className="eqc-tab-details mobile-card-wrapper">
            <div className="mb-15 ">
              <EqcSummary
                data={eqcData?.getEqc}
                refetch={() => {
                  if (!eqcData?.isAudited) {
                    fetchEqcData();
                  }
                }}
              />
            </div>
            <div>
              <EqcStages data={eqcData?.getEqc} />
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
};

export default EqcDetails;
