import { useQuery } from '@apollo/client';
import { Layout, Menu } from 'antd';
import * as eva from 'eva-icons';
import Gleap from 'gleap';
import { filter } from 'lodash';
import React, { useEffect } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import alternateLogo from '../../../assets/images/alternateLogo.png';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  MODULES,
  ROUTES,
  SYSTEM_ROLES
} from '../../../common/constants';
import CanPerform from '../../../components/CanPerform';
import HasAccess from '../../../components/HasAccess';
import { GET_PROFILE } from '../../../modules/users/graphql/Queries';
import UserProfile from './UserProfile';

const { Header } = Layout;

const AppHeader = ({ location: { pathname } }) => {
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { data: userData } = useQuery(GET_PROFILE, {
    onCompleted(res) {
      Gleap.identify(res?.getLoggedInUser?.id, {
        name: res?.getLoggedInUser?.name,
        email: res?.getLoggedInUser?.email,
        phone: res?.getLoggedInUser?.phoneNo
      });
    }
  });
  const isProjectAdmin = !!filter(
    userData?.getLoggedInUser?.projectUsers,
    (user) => user?.roles === SYSTEM_ROLES.PROJECT_ADMIN
  ).length;
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });

  // eslint-disable-next-line no-undef
  window.onbeforeunload = closeIt;

  function closeIt() {
    Gleap.clearIdentity();
  }
  return (
    <Header>
      <>
        <Link to={ROUTES.MAIN}>
          <div className="logo">
            <img
              src={
                userData?.getLoggedInUser?.tenantUser?.tenant?.logo ||
                alternateLogo
              }
              alt="logo"
              className="header-logo"
            />
          </div>
        </Link>
        <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg">
          <div className="header d-flex align-center justify-center">
            <Menu
              theme="lite"
              mode="horizontal"
              selectedKeys={[`/${pathname.split('/')[1]}`]}
              defaultSelectedKeys={[
                hasInspectionAccess ? ROUTES.MAIN : ROUTES.PROJECTS
              ]}
            >
              {hasInspectionAccess &&
                CanPerform({
                  action: ALLOWED_ACTION_KEYS.VIEW_DASHBOARD_PAGE
                }) && (
                  <Menu.Item key={ROUTES.MAIN} className="d-flex align-center">
                    <NavLink to={ROUTES.MAIN}>{MODULES.DASHBOARD}</NavLink>
                  </Menu.Item>
                )}
              {CanPerform({
                action: ALLOWED_ACTION_KEYS.VIEW_PROJECTS_PAGE
              }) && (
                <Menu.Item
                  key={ROUTES.PROJECTS}
                  className="d-flex align-center"
                >
                  <NavLink to={ROUTES.PROJECTS}>{MODULES.PROJECTS}</NavLink>
                </Menu.Item>
              )}
              {hasInspectionAccess &&
                CanPerform({
                  action: ALLOWED_ACTION_KEYS.VIEW_EQC_TYPES_PAGE
                }) && (
                  <Menu.Item
                    key={ROUTES.EQC_TYPES}
                    className="d-flex align-center"
                  >
                    <NavLink to={ROUTES.EQC_TYPES}>{MODULES.EQC_TYPES}</NavLink>
                  </Menu.Item>
                )}
              {CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_USERS_PAGE }) && (
                <>
                  <Menu.Item key={ROUTES.USERS}>
                    <NavLink to={ROUTES.USERS}>{MODULES.USERS}</NavLink>
                  </Menu.Item>
                </>
              )}
              {CanPerform({
                action: ALLOWED_ACTION_KEYS.VIEW_AGENCY_PAGE
              }) && (
                <Menu.Item
                  key={ROUTES.AGENCIES}
                  className="d-flex align-center"
                >
                  <NavLink to={ROUTES.AGENCIES}>{MODULES.AGENCIES}</NavLink>
                </Menu.Item>
              )}
              {CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_LOGS_PAGE }) && (
                <Menu.Item key={ROUTES.LOGS} className="d-flex align-center">
                  <NavLink to={ROUTES.LOGS}>{MODULES.LOGS}</NavLink>
                </Menu.Item>
              )}
              {hasInspectionAccess &&
                (CanPerform({
                  action: ALLOWED_ACTION_KEYS.VIEW_REPORTS_PAGE
                }) ||
                  isProjectAdmin) && (
                  <Menu.Item
                    key={ROUTES.REPORTS}
                    className="d-flex align-center"
                  >
                    <NavLink to={ROUTES.REPORTS}>{MODULES.REPORTS}</NavLink>
                  </Menu.Item>
                )}
            </Menu>
          </div>
        </div>
        <div className="header-notification">
          <UserProfile userData={userData} />
        </div>
      </>
    </Header>
  );
};
export default withRouter(AppHeader);
