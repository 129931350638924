import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import { isEmpty, omit } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  STAGE_STATUS
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { UPDATE_APPROVAL_STATUS } from './graphql/Mutation';

const ApproveModal = (props) => {
  const { showModal, setShowModal, data, title, status, commentData } = props;
  const { token } = useParams();
  const history = useHistory();
  const handleCancel = () => {
    setShowModal(false);
  };

  const [updateApprovalStatus, { loading }] = useMutation(
    UPDATE_APPROVAL_STATUS,
    {
      onError() {},
      onCompleted() {
        setShowModal(false);
        const updatedAt = moment().utc().format();
        Event(GA_EVENT.APPROVE_EQC, {
          label: GA_LABEL.APPROVE_EQC,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          status
        });
        history.push(ROUTES.APPROVER_SUCCESS, {
          status: status === STAGE_STATUS.REDO ? 'Redo' : 'Approve',
          name: data?.getEqcStageHistoryWithoutAuth?.eqc?.eqcName,
          updatedAt: updatedAt
        });
      }
    }
  );

  const updateStatus = (approval) => {
    const mutationData = {
      stageHistoryId: Number(data?.getEqcStageHistoryWithoutAuth?.id),
      status: approval,
      commentData
    };
    if (isEmpty(commentData?.eqcStage)) {
      mutationData.commentData = omit(mutationData.commentData, ['eqcStage']);
    }
    if (isEmpty(commentData?.eqcStageItems)) {
      mutationData.commentData = omit(mutationData.commentData, [
        'eqcStageItems'
      ]);
    }
    updateApprovalStatus({
      variables: {
        data: {
          ...mutationData,
          token
        }
      }
    });
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      closable={false}
      footer={null}
    >
      <h2>{`${title} Stage`}</h2>
      <h4 className="mb-40">
        Are you sure you want to submit? You won't be able to modify data after
        you submit.
      </h4>
      <div className="form-buttons">
        <Button shape="round" className="cancel-button" onClick={handleCancel}>
          No, Cancel
        </Button>
        <Button
          shape="round"
          className="save-button"
          htmlType="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            updateStatus(status);
          }}
        >
          {`Yes, ${title}`}
        </Button>
      </div>
    </Modal>
  );
};

export default ApproveModal;
