import { useMutation, useQuery } from '@apollo/client';
import { Button, DatePicker, Form, Modal } from 'antd';
import { get, map, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formValidatorRules } from '../../../../../../common/utils';
import CommonSelect from '../../../../../../components/CommonSelect';
import { MARK_QC_PASS } from '../../../../graphql/Mutation';
import { GET_PROJECT_AGENCY_LIST } from '../../../../graphql/Queries';

const { Option } = CommonSelect;

const MarkQCPassModal = (props) => {
  const {
    showModal,
    setShowModal,
    eqcData,
    setEqcData,
    refetchEqcData
  } = props;
  const [isLastStage, setIsLastStage] = useState(false);
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { required } = formValidatorRules;
  const { data: agencyData } = useQuery(GET_PROJECT_AGENCY_LIST, {
    onError() {},
    variables: {
      filter: { projectId }
    }
  });
  const handleCancel = () => {
    setShowModal(false);
    setEqcData();
    form.resetFields();
  };
  useEffect(() => {
    if (eqcData) {
      const orderedEQCStages = orderBy(
        get(eqcData, 'eqcStages'),
        ['id'],
        ['desc']
      );
      setIsLastStage(
        Number(get(eqcData, 'stageHistories[0].eqcStageId')) ===
          Number(get(orderedEQCStages, '[0].id'))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcData]);

  const [markQCPass, { loading }] = useMutation(MARK_QC_PASS, {
    onError() {},
    onCompleted() {
      setEqcData();
      form.resetFields();
      setShowModal(false);
      refetchEqcData();
    }
  });

  const onFormSubmit = async ({ inspectorReminderDate, projectAgencyId }) => {
    markQCPass({
      variables: {
        data: {
          eqcId: eqcData?.id,
          eqcStageId: get(eqcData, 'eqcStageHistories[0].eqcStageId'),
          projectId,
          inspectorReminderDate,
          projectAgencyId
        }
      }
    });
  };

  return (
    <Modal
      maskClosable={false}
      centered
      form={form}
      visible={showModal}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <h2 className="mb-15">Mark QC Pass</h2>
      <Form layout="vertical" form={form} onFinish={onFormSubmit}>
        <Form.Item name="projectAgencyId" label="Agencies" rules={[required]}>
          <CommonSelect
            allowClear
            showSearch
            optionFilterProp="children"
            placeholder="Select agencies"
          >
            {map(
              agencyData?.projectAgencyList?.data,
              ({ agency: { name: agencyName }, id }) => (
                <Option key={id} value={id}>
                  {agencyName}
                </Option>
              )
            )}
          </CommonSelect>
        </Form.Item>
        {!isLastStage && (
          <Form.Item
            name="inspectorReminderDate"
            label="Inspector Reminder Date"
          >
            <DatePicker format="DD-MM-YYYY" className="width-percent-100" />
          </Form.Item>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default MarkQCPassModal;
