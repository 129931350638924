import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import EqcDetails from './components/eqcDetails/EqcDetails';
import EqcType from './EqcType';

const EqcTypeWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.EQC_TYPES} component={EqcType} />
      <Route path={`${ROUTES.EQC_TYPES}/:eqcTypeId`} component={EqcDetails} />
    </Switch>
  );
};

export default EqcTypeWrapper;
