import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../common/utils';
import { DUPLICATE_PROJECT_EQC_TYPE } from '../../../../graphql/Mutation';

const DuplicateEqcTypeModal = (props) => {
  const {
    showModal,
    setShowModal,
    mutationId,
    setMutationId,
    refetchEqcTypeData
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { required } = formValidatorRules;

  const handleCancel = () => {
    setShowModal(false);
    setMutationId();
    form.resetFields();
  };
  const [duplicateProjectEqcType] = useMutation(DUPLICATE_PROJECT_EQC_TYPE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DUPLICATE_PROJECT_EQC_TYPE, {
        label: GA_LABEL.DUPLICATE_PROJECT_EQC_TYPE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_eqc_type_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setMutationId();
      form.resetFields();
      setShowModal(false);
      refetchEqcTypeData();
    }
  });

  const onFormSubmit = async (formValues) => {
    try {
      await duplicateProjectEqcType({
        variables: {
          data: {
            ...formValues,
            projectId: Number(projectId),
            projectEqcTypeId: Number(mutationId)
          }
        }
      });
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        form={form}
        visible={showModal}
        onCancel={handleCancel}
        closable={false}
        footer={null}
      >
        <h2 className="mb-15">Duplicate Eqc Type</h2>
        <Form layout="vertical" form={form} onFinish={onFormSubmit}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              required,
              {
                max: 250,
                message: 'Name cannot be more than 250 characters'
              }
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DuplicateEqcTypeModal;
