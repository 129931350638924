import { Card } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../common/constants';
import AgenciesTable from './AgenciesTable';

const AgenciesContent = ({
  setAgencyId,
  agencyId,
  setModalVisible,
  setTitle
}) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  return (
    <Card className="agency-page-wrapper">
      <div
        className={clsx(
          'header',
          'd-flex',
          'align-center',
          'justify-between',
          isDesktopViewport && 'mb-10'
        )}
      >
        {isDesktopViewport && <h1 className="mr-10">Agencies</h1>}
        <div
          className={`agency-header-buttons d-flex ${
            !isDesktopViewport ? 'width-percent-100' : ''
          }`}
        >
          <div
            id="agency-btn"
            className="d-flex align-center justify-between width-percent-100"
          />
        </div>
      </div>
      <AgenciesTable
        setAgencyId={setAgencyId}
        agencyId={agencyId}
        setModalVisible={setModalVisible}
        setTitle={setTitle}
      />
    </Card>
  );
};

export default AgenciesContent;
