import { Card } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../common/constants';
import UserList from './components/UserList';

const Users = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  return (
    <>
      {!isDesktopViewport && (
        <>
          <div className="page-header text-primary">Users</div>
        </>
      )}
      <div className="mobile-card-wrapper">
        <Card>
          <div className="header d-flex align-center justify-between mb-10">
            {isDesktopViewport && <h1>Users</h1>}
            <div
              className={clsx(
                'users-header-buttons d-flex',
                !isDesktopViewport && 'width-percent-100'
              )}
            >
              <div
                id="search-component"
                className={clsx('mr-5', !isDesktopViewport && 'flex-grow-1')}
              />
              <div id="add-button" />
              <div id="user-import-export-buttons" className="d-flex" />
            </div>
          </div>
          <UserList />
        </Card>
      </div>
    </>
  );
};

export default Users;
