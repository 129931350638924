import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { GA_EVENT, GA_LABEL, REGEX } from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import { UPDATE_LOGGED_IN_USER } from './graphql/Mutation';

const { required } = formValidatorRules;

const EditModal = (props) => {
  const { showModal, setShowModal, userData, refetchUserData } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const [updateUser] = useMutation(UPDATE_LOGGED_IN_USER, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.EDIT_USER_PROFILE, {
        label: GA_LABEL.EDIT_USER_PROFILE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      form.resetFields();
      setShowModal(false);
      refetchUserData();
    }
  });

  const onFinish = async (formValues) => {
    const newFormValues = {
      name: formValues.name,
      phoneNo: formValues.phoneNo
    };

    const variables = newFormValues;

    try {
      updateUser({
        variables: { data: { ...variables } }
      });
    } catch (error) {
      return error;
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      className="dialog"
      footer={null}
      closable={false}
    >
      <h2>Edit Details</h2>
      <Form
        form={form}
        initialValues={userData}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          rules={[
            required,
            {
              max: 250,
              message: 'Name cannot be more than 250 characters'
            }
          ]}
          name="name"
          label="Name"
        >
          <Input allowClear placeholder="Enter Name" />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input allowClear disabled placeholder="Enter Email" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNo"
          rules={[
            required,
            () => ({
              validator(rule, value) {
                if (value) {
                  // eslint-disable-next-line no-param-reassign
                  value = value.split(' ').join('');
                  const numberPattern = REGEX.PHONE;
                  if (!numberPattern.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('should be a valid phone number');
                  }
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input allowClear placeholder="Enter Phone Number" />
        </Form.Item>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditModal;
