import { filter, includes, isEmpty } from 'lodash';
import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppContext } from '../AppContext';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  ROUTES,
  SYSTEM_ROLES
} from '../common/constants';
import CanPerform from '../components/CanPerform';
import HasAccess from '../components/HasAccess';
import LoaderComponent from '../components/LoaderComponent';
import Error404 from '../Error404';
import AgenciesWrapper from '../modules/agencies';
import ChangePassword from '../modules/auth/ChangePassword';
import DashboardWrapper from '../modules/dashboard';
import EqcTypeWrapper from '../modules/eqctype';
import LogsWrapper from '../modules/logs';
import ProfileWrapper from '../modules/profile';
import ProjectWrapper from '../modules/projects';
import ReportsWrapper from '../modules/report';
import UserWrapper from '../modules/users';

const ContentRoutes = () => {
  const {
    state: {
      currentUser,
      currentUser: { projectUsers, roles }
    }
  } = useContext(AppContext);
  const isProjectAdmin = !!filter(
    projectUsers,
    (user) => user?.roles === SYSTEM_ROLES.PROJECT_ADMIN
  ).length;
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });
  if (isEmpty(currentUser)) {
    return <LoaderComponent />;
  }
  const RedirectToEqcType = () => {
    if (includes(roles, SYSTEM_ROLES.CHECKLIST_MAKER)) {
      return <Redirect to={ROUTES.EQC_TYPES} />;
    }
    if (!hasInspectionAccess) {
      return <Redirect to={ROUTES.PROJECTS} />;
    }
    return <DashboardWrapper />;
  };
  return (
    <>
      <Switch>
        {CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_PROJECTS_PAGE }) && (
          <Route path={ROUTES.PROJECTS} component={ProjectWrapper} />
        )}
        {CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_LOGS_PAGE }) && (
          <Route path={ROUTES.LOGS} component={LogsWrapper} />
        )}
        {hasInspectionAccess &&
          (CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_REPORTS_PAGE }) ||
            isProjectAdmin) && (
            <Route path={ROUTES.REPORTS} component={ReportsWrapper} />
          )}
        {CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_USERS_PAGE }) && (
          <Route path={ROUTES.USERS} component={UserWrapper} />
        )}
        <Route path={ROUTES.PROFILE} component={ProfileWrapper} />
        {hasInspectionAccess &&
          CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_EQC_TYPES_PAGE }) && (
            <Route path={ROUTES.EQC_TYPES} component={EqcTypeWrapper} />
          )}
        {CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_AGENCY_PAGE }) && (
          <Route path={ROUTES.AGENCIES} component={AgenciesWrapper} />
        )}
        <Route exact path={ROUTES.MAIN} component={RedirectToEqcType} />
        <Route path={ROUTES.CHANGE} component={ChangePassword} />
        <Route path="*" exact component={Error404} />
      </Switch>
    </>
  );
};

export default ContentRoutes;
