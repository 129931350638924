import { useQuery } from '@apollo/client';
import { Col, message, Row } from 'antd';
import * as eva from 'eva-icons';
import { filter } from 'lodash';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { ForwardArrow, ProjectIcon } from '../../../../../../../assets/svg';
import {
  BREAKPOINTS,
  INS_STATUS_KEYS,
  ROUTES,
  TAB_KEYS
} from '../../../../../../../common/constants';
import {
  GET_PROJECT_INSTRUCTION,
  GET_SINGLE_PROJECT
} from '../../../../../graphql/Queries';
import CloseDetails from './CloseDetails';
import InstructionMoreDetails from './InstructionMoreDetails';
import InstructionSummary from './InstructionSummary';
import RecommendationDetails from './RecommendationDetails';
import RejectionDetails from './RejectionDetails';
import ResponseDetails from './ResponseDetails';

const InstructionDetails = () => {
  const { projectId, instructionId } = useParams();

  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const instructionFilter = history?.location?.state?.instructionFilter;
  const instructionPagination = history?.location?.state?.instructionPagination;
  const selectedUserRecord = history?.location?.state?.selectedUserRecord;
  const selectAgencyRecord = history?.location?.state?.selectAgencyRecord;
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: projectData } = useQuery(GET_SINGLE_PROJECT, {
    variables: {
      id: projectId
    }
  });

  const { data: instructionData } = useQuery(GET_PROJECT_INSTRUCTION, {
    fetchPolicy: 'network-only',
    variables: {
      id: instructionId
    },
    onError() {
      history.replace(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.INSTRUCTION}`
      );
      setTimeout(() => {
        message.destroy();
        message.error('Instruction not found!');
      }, 1000);
    }
  });
  const respondedDetails = filter(
    instructionData?.getInstruction?.instructionIssueHistories,
    ({ status }) => status === INS_STATUS_KEYS.RESPONDED
  );
  const rejectedDetails = filter(
    instructionData?.getInstruction?.instructionIssueHistories,
    ({ status }) => status === INS_STATUS_KEYS.REJECTED
  );
  return (
    <>
      <div className="project-details">
        <div className="project-details-title justify-between page-header">
          <div className="project-details-title-text d-flex align-center">
            <ProjectIcon
              onClick={() => {
                history.push(ROUTES.PROJECTS, {
                  navFilter,
                  navPagination
                });
              }}
              className="pointer"
            />
            <ForwardArrow />
            <h1
              className="pointer text-primary mr-10"
              onClick={() => {
                history.push(
                  `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.INSTRUCTION}`,
                  {
                    navFilter,
                    navPagination,
                    instructionFilter,
                    instructionPagination,
                    selectedUserRecord,
                    selectAgencyRecord
                  }
                );
              }}
            >
              {projectData?.getProject?.name}
            </h1>
            <ForwardArrow />
            <h1>{instructionData?.getInstruction?.name}</h1>
          </div>
        </div>
        <div
          className={`mobile-card-wrapper ${
            isDesktopViewport ? '' : 'd-flex flex-vertical'
          }`}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <InstructionSummary
                instructionData={instructionData?.getInstruction}
              />
            </Col>
            <Col
              span={
                isDesktopViewport &&
                instructionData?.getInstruction?.status !==
                  INS_STATUS_KEYS.NOTICE
                  ? 12
                  : 24
              }
            >
              <InstructionMoreDetails
                instructionData={instructionData?.getInstruction}
              />
            </Col>
            {instructionData?.getInstruction?.status !==
              INS_STATUS_KEYS.NOTICE && (
              <Col
                span={isDesktopViewport ? 12 : 24}
                className="closing-details"
              >
                <CloseDetails
                  instructionData={instructionData?.getInstruction}
                />
              </Col>
            )}
            {instructionData?.getInstruction?.instructionHistory?.length >
              0 && (
              <Col span={24}>
                <RecommendationDetails
                  data={instructionData?.getInstruction?.instructionHistory}
                />
              </Col>
            )}
            {respondedDetails?.length > 0 && (
              <Col span={24}>
                <ResponseDetails data={respondedDetails} />
              </Col>
            )}
            {rejectedDetails?.length > 0 && (
              <Col span={24}>
                <RejectionDetails data={rejectedDetails} />
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default InstructionDetails;
