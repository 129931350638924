import { useLazyQuery } from '@apollo/client';
import { Select } from 'antd';
import { debounce, filter, get, includes, map, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DROPDOWN_LIMIT, SCROLL_CONST } from '../common/constants';
import usePrevious from '../common/usePrevious';

const { Option } = Select;
let searchDebounce = null;

const CommonDropdown = (props) => {
  const {
    query,
    variables = {},
    responsePath,
    valuePath,
    labelPath,
    optionKey,
    callback,
    fetchPolicy,
    hasSelectAll,
    showSearch,
    useEffectDeps = [],
    conditionToCheckBeforeQuery = true,
    onChange = () => {},
    isSelectedAll,
    currentPage: currPage = 0,
    className,
    customOptions = [],
    ...rest
  } = props;
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(currPage);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [currentProjectId, setCurrentProjectId] = useState(rest?.projectId);
  const prevRestProjectId = usePrevious(rest?.projectId);

  const [fetchListData, { loading }] = useLazyQuery(query, {
    fetchPolicy,
    onCompleted: (res) => {
      const data = get(res, responsePath, []);
      if (currentPage === 0) {
        setListData(data);
      } else {
        setListData((oldData) => uniqBy([...oldData, ...data], valuePath));
      }
      if (currentProjectId !== prevRestProjectId) {
        setCurrentPage(0);
        setCurrentProjectId(rest?.projectId);
      } else {
        setCurrentPage((page) => page + 1);
      }
      setHasMore(data?.length >= DROPDOWN_LIMIT);
      if (typeof callback === 'function') {
        callback(res);
      }
    },
    onError() {}
  });
  useEffect(() => {
    if (prevRestProjectId !== rest?.projectId) {
      setCurrentPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest?.projectId]);

  useEffect(() => {
    if (conditionToCheckBeforeQuery) {
      fetchListData({
        fetchPolicy,
        variables: {
          ...variables,
          filter: {
            ...(variables?.filter || {}),
            skip: currentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectDeps);

  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && hasMore && !loading) {
      const obj = {
        variables: {
          ...variables,
          filter: {
            ...(variables?.filter || {}),
            skip: currentPage * DROPDOWN_LIMIT,
            limit: DROPDOWN_LIMIT,
            search: searchText
          }
        }
      };
      fetchListData(obj);
    }
  };
  const searchQuery = (search) => {
    setSearchText(search);
    setCurrentPage(0);
    fetchListData({
      fetchPolicy: 'network-only',
      variables: {
        ...variables,
        filter: {
          ...(variables?.filter || {}),
          search,
          skip: 0,
          limit: DROPDOWN_LIMIT
        }
      }
    });
  };
  const handleSearch = (value) => {
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(value);
  };
  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };
  return (
    <div className="common-dropdown-wrapper position-relative width-percent-100">
      <Select
        loading={loading}
        onPopupScroll={handleScroll}
        showSearch={showSearch}
        onSearch={showSearch && handleSearch}
        onClear={handleClear}
        onBlur={handleClear}
        className={`${className} ${hasSelectAll ? 'has-select-all' : ''}`}
        getPopupContainer={(trigger) => trigger.parentElement}
        onChange={(e) => {
          let records = [];
          if (typeof e === 'object') {
            records = filter(listData, (item) => {
              return includes(e, get(item, valuePath));
            });
          } else {
            records = filter(listData, (item) => {
              return e === get(item, valuePath);
            });
          }
          onChange(e, records);
          handleClear();
        }}
        {...rest}
      >
        <>
          {!searchText && hasSelectAll && listData?.length && (
            <Option key={`${optionKey}-select-all`} value="all">
              {isSelectedAll ? 'Selected All' : 'Select All'}
            </Option>
          )}
          {map(uniqBy([...listData, ...customOptions], 'id'), (item, index) => {
            const optionValue = get(item, valuePath);
            const optionLabel =
              typeof labelPath === 'object'
                ? map(labelPath, (label) => get(item, label)).join(' - ')
                : get(item, labelPath);
            return (
              <Option
                key={`${optionKey}-${optionValue}-${index}`}
                value={optionValue}
              >
                {optionLabel}
              </Option>
            );
          })}
        </>
      </Select>
    </div>
  );
};

export default CommonDropdown;
