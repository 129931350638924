import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { GA_EVENT, GA_LABEL, REGEX } from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { formValidatorRules } from '../../../common/utils';
import {
  CREATE_AGENCY_CONTACT,
  UPDATE_AGENCY_CONTACT
} from '../graphql/Mutation';

const { required, email } = formValidatorRules;

const AddContactModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    agencyContactData,
    setAgencyContactData,
    agencyId,
    refetchAgencyContactDetails
  } = props;
  const [form] = Form.useForm();
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setAgencyContactData();
  };

  const [createAgencyContact, { loading: createLoading }] = useMutation(
    CREATE_AGENCY_CONTACT,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_GLOBAL_AGENCY_CONTACT, {
          label: GA_LABEL.ADD_GLOBAL_AGENCY_CONTACT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          agency_id: agencyId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setAgencyContactData();
        form.resetFields();
        setShowModal(false);
        refetchAgencyContactDetails();
      }
    }
  );
  const [updateAgencyContact, { loading: updateLoading }] = useMutation(
    UPDATE_AGENCY_CONTACT,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_GLOBAL_AGENCY_CONTACT, {
          label: GA_LABEL.EDIT_GLOBAL_AGENCY_CONTACT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          agency_id: agencyId,
          contact_id: agencyContactData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setAgencyContactData();
        form.resetFields();
        setShowModal(false);
        refetchAgencyContactDetails();
      }
    }
  );

  const onFinish = async (formValues) => {
    const newFormValues = {
      ...formValues,
      phoneNo: formValues?.phoneNo?.split(' ').join('')
    };
    const variables = isUpdate
      ? {
          data: { ...newFormValues },
          id: agencyContactData?.id
        }
      : newFormValues;

    try {
      if (isUpdate) {
        await updateAgencyContact({
          variables: { ...variables }
        });
        return;
      }
      await createAgencyContact({
        variables: {
          data: {
            ...variables,
            agencyId
          }
        }
      });
    } catch (error) {
      return error;
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      className="dialog"
      footer={null}
      closable={false}
    >
      <h2>{isUpdate ? 'Edit Agency Contact' : 'Add Agency Contact'}</h2>
      <Form
        form={form}
        initialValues={agencyContactData}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          rules={[
            required,
            {
              max: 250,
              message: 'Name cannot be more than 250 characters'
            }
          ]}
          name="name"
          label="Name"
        >
          <Input allowClear placeholder="Enter Name" />
        </Form.Item>
        <Form.Item rules={[required, email]} name="email" label="Email">
          <Input allowClear placeholder="Enter Email" />
        </Form.Item>
        <Form.Item
          label="Mobile Number"
          name="phoneNo"
          rules={[
            required,
            () => ({
              validator(rule, value) {
                if (value) {
                  // eslint-disable-next-line no-param-reassign
                  value = value.split(' ').join('');
                  const numberPattern = REGEX.PHONE;
                  if (!numberPattern.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('should be a valid phone number');
                  }
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input allowClear placeholder="Enter Mobile Number" />
        </Form.Item>
        <Form.Item rules={[required]} name="designation" label="Designation">
          <Input allowClear placeholder="Enter Designation" />
        </Form.Item>

        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={createLoading || updateLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddContactModal;
