import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Checkbox, Col, Dropdown, Menu, Row, Tag, Tooltip } from 'antd';
import { capitalize, includes, map, omit, values } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  EditButton,
  InfoIconDark,
  KebabMenu,
  Timer
} from '../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULTDATEFORMAT,
  DEFAULTDATETIMEFORMAT,
  DEFAULT_PAGE_SIZE,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL,
  ROUTES,
  TAB_KEYS
} from '../../../../../../common/constants';
import CanPerform from '../../../../../../components/CanPerform';
import CollapsibleFilterWrapper from '../../../../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../../../../components/CommonCard';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import CommonSelect from '../../../../../../components/CommonSelect';
import CommonTable from '../../../../../../components/CommonTable';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../../../../components/SearchComponent';
import {
  GET_PROJECT_INSTRUCTION_LIST,
  PROJECT_AGENCY_DROPDOWN,
  PROJECT_USER_DROPDOWN,
  TYPE_DROPDOWN
} from '../../../../graphql/Queries';
import EditModal from './EditModal';

const { Option } = CommonSelect;
const Instruction = ({ uniqueCode }) => {
  const { projectId } = useParams();
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const instructionFilter = history?.location?.state?.instructionFilter;
  const instructionPagination = history?.location?.state?.instructionPagination;

  const initialProjectInstructionFilter = {
    skip: 0,
    limit: 10,
    projectId,
    sortBy: { field: 'updatedAt', order: 'DESC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [paginationProp, setPaginationProp] = useState(
    instructionPagination || initialPaginationValue
  );
  const [projectInstructionFilter, setProjectInstructionFilter] = useState(
    instructionFilter || initialProjectInstructionFilter
  );
  const [showModal, setShowModal] = useState(false);
  const [instructionData, setInstructionData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [instructionListData, setInstructionListData] = useState();
  const [userId, setUserId] = useState(projectInstructionFilter?.userId);
  const [selectedUserRecord, setSelectedUserRecord] = useState(
    history?.location?.state?.selectedUserRecord
  );
  const [isOverdue, setIsOverdue] = useState(
    projectInstructionFilter?.isOverdue
  );
  const [projectAgencyId, setProjectAgencyId] = useState(
    projectInstructionFilter?.projectAgencyId
  );
  const [selectAgencyRecord, setSelectedAgencyRecord] = useState(
    history?.location?.state?.selectAgencyRecord
  );
  const [type, setType] = useState(projectInstructionFilter?.type);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [instructionStatus, setInstructionStatus] = useState(
    instructionFilter?.status
  );
  const [sortedInfo, setSortedInfo] = useState({});
  const { data: typeData, loading: typeLoading } = useQuery(TYPE_DROPDOWN, {
    fetchPolicy: 'network-only'
  });
  const [fetchProjectInstruction, { loading, called }] = useLazyQuery(
    GET_PROJECT_INSTRUCTION_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.instructionList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.instructionList?.total
        };
        if (scrollFlag) {
          const datalist = [...instructionListData, ...data];
          setInstructionListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setInstructionListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  useEffect(() => {
    fetchProjectInstruction({
      variables: { filter: projectInstructionFilter }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (called) {
      let newFilter = {
        ...projectInstructionFilter,
        status: instructionStatus,
        type,
        projectAgencyId,
        userId,
        skip: 0
      };
      if (isOverdue) {
        newFilter.isOverdue = isOverdue;
      } else {
        newFilter = omit(newFilter, 'isOverdue');
      }
      setPaginationProp(initialPaginationValue);
      setProjectInstructionFilter(newFilter);
      fetchProjectInstruction({ variables: { filter: { ...newFilter } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, projectAgencyId, type, instructionStatus, isOverdue]);
  const handleEdit = (e, record) => {
    e?.stopPropagation?.();
    setInstructionData(record);
    setShowModal(true);
  };
  const getUpdatedBy = (record) => {
    if (record?.status === INS_STATUS_KEYS.RESPONDED) {
      return record?.responder?.name || '-';
    }
    return record?.modifier?.name || record?.creator?.name || '-';
  };
  const getRespondedBy = (record) => {
    return record?.responder?.name || '-';
  };
  const getStatus = (record) => {
    return (
      <Tag className={INS_STATUS_CLASSNAME[record?.status]}>
        {INS_STATUS_LABEL[record?.status]}
      </Tag>
    );
  };
  const getDueDate = (record) => {
    const isOverDue = record?.isOverdue;
    return record?.isNotice || !record?.reminderDate ? (
      '-'
    ) : (
      <div className={`d-flex align-center ${isOverDue ? 'text-danger' : ''}`}>
        <div>{moment(record?.reminderDate).format(DEFAULTDATEFORMAT)}</div>
        {isOverDue && (
          <Tooltip title="Overdue">
            <div className="ml-5 d-flex align-center">
              <Timer height="18" width="18" />
            </div>
          </Tooltip>
        )}
      </div>
    );
  };
  const getTypeName = (record) => {
    if (
      !includes(typeData?.instructionTypeList, record?.type) ||
      record?.type === 'Other'
    ) {
      return `Other - ${record?.type}`;
    }
    return record?.type || '-';
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return (
          <div className="d-flex align-center">
            <span>{projectInstructionFilter?.skip + index + 1}</span>
            <span className="ml-5 d-flex align-center">
              {record?.status === INS_STATUS_KEYS.NOTICE && (
                <Tooltip title="Notice">
                  <InfoIconDark height="20px" width="20px" />
                </Tooltip>
              )}
            </span>
          </div>
        );
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order
    },
    {
      title: 'TYPE',
      key: 'type',
      render: (record) => getTypeName(record),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'type' && sortedInfo?.order
    },
    {
      title: 'AGENCY NAME',
      key: 'agencyName',
      render: (record) => {
        return record?.projectAgency?.agency?.name || '-';
      }
    },
    {
      title: 'STATUS',
      key: 'status',
      render: (status, record) => getStatus(record)
    },
    {
      title: 'DUE DATE',
      key: 'reminderDate',
      render: (reminderDate, record) => getDueDate(record),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'reminderDate' && sortedInfo?.order
    },
    {
      title: 'UPDATED BY',
      key: 'updatedBy',
      render: (record) => getUpdatedBy(record)
    },
    {
      title: 'RESPONDED BY',
      key: 'respondedBy',
      render: (record) => getRespondedBy(record)
    },
    {
      title: 'RAISED AT',
      key: 'createdAt',
      render: (dueDate, record) => {
        return record?.createdAt
          ? moment(`${record?.createdAt}`).format(DEFAULTDATEFORMAT)
          : '-';
      },
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order
    },
    {
      key: 'actions',
      align: 'right',
      render: (record) => {
        return (
          <CanPerform
            action={ALLOWED_ACTION_KEYS.EDIT_INSTRUCTION}
            type={ALLOWED_ACTION_TYPE.PROJECT}
          >
            <Tooltip title="Edit">
              <Button
                shape="round"
                icon={<EditButton />}
                onClick={(e) => handleEdit(e, record)}
                disabled={
                  record?.status === INS_STATUS_KEYS.CLOSED ||
                  record?.status === INS_STATUS_KEYS.NOTICE
                }
              />
            </Tooltip>
          </CanPerform>
        );
      }
    }
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    setProjectInstructionFilter({
      ...projectInstructionFilter,
      skip: skip,
      limit: pagination.pageSize,
      sortBy: sorter?.column
        ? {
            field: sorter.columnKey,
            order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
          }
        : { field: 'createdAt', order: 'DESC' }
    });
    fetchProjectInstruction({
      variables: {
        filter: {
          ...projectInstructionFilter,
          skip,
          limit: pagination.pageSize,
          sortBy: sorter?.column
            ? {
                field: sorter.columnKey,
                order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
              }
            : { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };

  const onSearchChange = async (value) => {
    setPaginationProp(initialPaginationValue);
    setProjectInstructionFilter({
      ...projectInstructionFilter,
      skip: 0,
      search: value
    });
    fetchProjectInstruction({
      variables: {
        filter: { ...projectInstructionFilter, skip: 0, search: value }
      }
    });
  };
  const handleRefetch = () => {
    fetchProjectInstruction({
      variables: {
        filter: {
          ...projectInstructionFilter,
          skip: instructionListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      history.push(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.INSTRUCTION}/${record?.id}`,
        {
          navFilter,
          navPagination,
          instructionFilter: projectInstructionFilter,
          instructionPagination: paginationProp,
          selectedUserRecord,
          selectAgencyRecord
        }
      );
    }
  };
  return (
    <>
      {showModal && (
        <EditModal
          showModal={showModal}
          setShowModal={setShowModal}
          instructionData={instructionData}
          setInstructionData={setInstructionData}
          uniqueCode={uniqueCode}
          refetchInstructionData={() =>
            fetchProjectInstruction({
              variables: {
                filter: {
                  ...projectInstructionFilter
                }
              }
            })
          }
        />
      )}
      <div className="project-tab-details">
        <div
          className={`project-tab-details-header  ${
            isDesktopViewport ? 'd-flex justify-between align-center ' : ''
          }`}
        >
          <h2>Issue Details</h2>
          <CollapsibleFilterWrapper
            searchProps={{
              className: 'search-component',
              getData: onSearchChange,
              defaultValue: instructionFilter?.search
            }}
          >
            <Row
              className="d-flex justify-end filter-search"
              wrap
              gutter={isDesktopViewport ? [10, 10] : [0, 10]}
            >
              <Col span={isDesktopViewport ? null : 12}>
                <Checkbox
                  onChange={(event) => setIsOverdue(event?.target?.checked)}
                  checked={isOverdue}
                >
                  Overdue
                </Checkbox>
              </Col>
              {!isDesktopViewport && <Col span={12} />}
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  placeholder="User"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  value={userId}
                  onChange={(v, record) => {
                    setSelectedUserRecord(record);
                    setUserId(v);
                  }}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  query={PROJECT_USER_DROPDOWN}
                  variables={{
                    filter: {
                      projectId: Number(projectId),
                      instructionDropdownList: true
                    }
                  }}
                  responsePath="projectUserDropdownList.data"
                  valuePath="id"
                  labelPath="name"
                  optionKey="user"
                  customOptions={selectedUserRecord}
                  dropdownMatchSelectWidth={false}
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  placeholder="Agency"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  value={projectAgencyId}
                  onChange={(v, record) => {
                    setSelectedAgencyRecord(record);
                    setProjectAgencyId(v);
                  }}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  query={PROJECT_AGENCY_DROPDOWN}
                  variables={{
                    filter: {
                      projectId: Number(projectId)
                    }
                  }}
                  responsePath="projectAgencyDropdownList.data"
                  valuePath="id"
                  labelPath="agency.name"
                  optionKey="user"
                  customOptions={selectAgencyRecord}
                  dropdownMatchSelectWidth={false}
                  placement="bottomRight"
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonSelect
                  placeholder="Type"
                  className={
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  }
                  allowClear
                  loading={typeLoading}
                  value={type}
                  onChange={(value) => {
                    setType(value);
                  }}
                >
                  {map(typeData?.instructionTypeList, (item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </CommonSelect>
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonSelect
                  allowClear
                  name="status"
                  placeholder="Status"
                  className={`instruction-selector ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  onChange={setInstructionStatus}
                  value={instructionStatus}
                >
                  {values(INS_STATUS_KEYS).map((status) => (
                    <Option key={status} value={status}>
                      {capitalize(status)}
                    </Option>
                  ))}
                </CommonSelect>
              </Col>
              {isDesktopViewport && (
                <Col>
                  <SearchComponent
                    className="search-component width-200"
                    getData={onSearchChange}
                    defaultValue={instructionFilter?.search}
                    name="issue & notice"
                  />
                </Col>
              )}
            </Row>
          </CollapsibleFilterWrapper>
        </div>
        {isDesktopViewport ? (
          <CommonTable
            className="pointer"
            columns={columns}
            data={instructionListData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
            loading={loading}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record)
              };
            }}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={instructionListData?.length}
            skeletonRows={columns?.length - 4}
          >
            {map(instructionListData, (instruction, index) => {
              return (
                <CommonCard
                  key={instruction?.id}
                  onClick={() => handleRowClick(instruction)}
                >
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">
                      <div className="d-flex align-center">{index + 1}.</div>
                    </div>
                    <div>
                      <div className="card-header fw-medium">
                        <span className="text-break">{instruction?.name}</span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Type:</span>
                          {getTypeName(instruction)}
                        </div>
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">Agency Name:</span>
                          {instruction?.projectAgency?.agency?.name}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Status:</span>
                          {getStatus(instruction)}
                        </div>
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">Due Date:</span>
                          {getDueDate(instruction)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Updated By:</span>
                          {getUpdatedBy(instruction)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Responded By:</span>
                          {getRespondedBy(instruction)}
                        </div>
                        <div>
                          <span className="fw-medium mr-5">Raised At:</span>
                          {instruction?.createdAt
                            ? moment(`${instruction?.createdAt}`).format(
                                DEFAULTDATETIMEFORMAT
                              )
                            : '-'}
                        </div>
                      </div>
                    </div>
                    {!(
                      instruction?.status === INS_STATUS_KEYS.CLOSED ||
                      instruction?.status === INS_STATUS_KEYS.NOTICE
                    ) && (
                      <span
                        className="d-flex position-absolute user-action-btn"
                        onClick={(e) => e?.stopPropagation?.()}
                      >
                        <CanPerform
                          action={ALLOWED_ACTION_KEYS.EDIT_INSTRUCTION}
                          type={ALLOWED_ACTION_TYPE.PROJECT}
                        >
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item
                                  key="edit"
                                  onClick={(e) => handleEdit(e, instruction)}
                                >
                                  Edit
                                </Menu.Item>
                              </Menu>
                            }
                            trigger={['click']}
                          >
                            <KebabMenu />
                          </Dropdown>
                        </CanPerform>
                      </span>
                    )}
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default Instruction;
