import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Row } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import { AddButton, DeleteButton, EditButton } from '../../../assets/svg';
import {
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import DeleteAgencyModal from '../DeleteAgencyModal';
import { DELETE_AGENCY_CONTACT } from '../graphql/Mutation';
import { GET_AGENCY_CONTACTS } from '../graphql/Queries';
import AddContactModal from './AddContactModal';

const AgencyContactTable = ({ agencyId }) => {
  const initialAgencyContactFilter = {
    skip: 0,
    limit: 10,
    agencyId: Number(agencyId) || 0,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [deleteModal, setDeleteModal] = useState(false);
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [mutationId, setMutationId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [agencyContactData, setAgencyContactData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [contactListData, setContactListData] = useState([]);

  const [agencyContactFilter, setAgencyContactFilter] = useState(
    initialAgencyContactFilter
  );
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [fetchAgencyContactsData, { loading }] = useLazyQuery(
    GET_AGENCY_CONTACTS,
    {
      variables: { filter: agencyContactFilter },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.contactsList?.data || [];
        const pagination = {
          ...paginationProp,
          total: res?.contactsList?.total
        };
        setPaginationProp(pagination);
        if (scrollFlag) {
          const datalist = [...contactListData, ...data];
          setContactListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setContactListData(datalist);
        }
        setHasMore(!!data?.length);
      },
      onError() {}
    }
  );

  useEffect(() => {
    fetchAgencyContactsData({ variables: { filter: agencyContactFilter } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRefetchAfterDelete = () => {
    const newSkip =
      contactListData?.length === 1
        ? Math.max(0, agencyContactFilter?.skip - paginationProp?.pageSize)
        : agencyContactFilter?.skip;
    setAgencyContactFilter({
      ...agencyContactFilter,
      skip: newSkip,
      sortBy: { field: 'createdAt', order: 'DESC' }
    });
    fetchAgencyContactsData({
      variables: {
        filter: {
          ...agencyContactFilter,
          agencyId: Number(agencyId),
          skip: newSkip,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  const [deleteAgencyContact] = useMutation(DELETE_AGENCY_CONTACT, {
    onCompleted() {
      Event(GA_EVENT.DELETE_GLOBAL_AGENCY_CONTACT, {
        label: GA_LABEL.DELETE_GLOBAL_AGENCY_CONTACT,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        agency_id: agencyId,
        contact_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setDeleteModal(false);
      handleRefetchAfterDelete();
    },
    onError() {}
  });

  useEffect(() => {
    const userFilterCopy = {
      ...agencyContactFilter,
      agencyId: Number(agencyId)
    };
    fetchAgencyContactsData({ variables: { filter: userFilterCopy } });
    setAgencyContactFilter(userFilterCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyId]);

  const handleAddEditContact = () => {
    setShowModal(true);
  };
  const refetchAgencyContactDetails = () => {
    setSortedInfo({});
    setAgencyContactFilter(initialAgencyContactFilter);
    setPaginationProp(initialPaginationValue);
    fetchAgencyContactsData({
      variables: {
        filter: { ...initialAgencyContactFilter, agencyId: Number(agencyId) }
      }
    });
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setAgencyContactFilter({
        ...agencyContactFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.field,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchAgencyContactsData({
        variables: {
          filter: {
            ...agencyContactFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.field,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setAgencyContactFilter({
        ...agencyContactFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });
      fetchAgencyContactsData({
        variables: {
          filter: {
            ...agencyContactFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' }
          }
        }
      });
    }
  };
  const getActionBtn = (record) => {
    return (
      <div className="d-flex">
        <Button
          shape="round"
          icon={<EditButton />}
          className="edit-button pointer b-0"
          onClick={() => {
            setAgencyContactData(record);
            handleAddEditContact();
          }}
        />
        <Button
          className="delete-button pointer b-0"
          shape="round"
          icon={<DeleteButton />}
          onClick={() => {
            setTitle('Agency Contact');
            setName(record?.name);
            setMutationId(record?.id);
            setDeleteModal(true);
          }}
        />
      </div>
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{agencyContactFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order
    },
    {
      title: 'DESIGNATION',
      dataIndex: 'designation',
      key: 'designation',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'designation' && sortedInfo?.order
    },
    {
      title: 'MOBILE NUMBER',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: 200,
      className: 'text-break',
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order
    },
    {
      key: 'action',
      width: 50,
      render: (record) => getActionBtn(record)
    }
  ];

  const onSearchChange = async (value) => {
    setAgencyContactFilter({ ...agencyContactFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchAgencyContactsData({
      variables: { filter: { ...agencyContactFilter, skip: 0, search: value } }
    });
  };
  const handleRefetch = () => {
    fetchAgencyContactsData({
      variables: {
        filter: {
          ...agencyContactFilter,
          skip: contactListData?.length,
          limit: DEFAULT_PAGE_SIZE
        }
      }
    });
  };
  return (
    <>
      {showModal && (
        <AddContactModal
          showModal={showModal}
          setShowModal={setShowModal}
          agencyContactData={agencyContactData}
          isUpdate={!!agencyContactData}
          setAgencyContactData={setAgencyContactData}
          agencyId={Number(agencyId)}
          refetchAgencyContactDetails={refetchAgencyContactDetails}
        />
      )}
      {deleteModal && (
        <DeleteAgencyModal
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          title={title}
          name={name}
          deleteMutation={deleteAgencyContact}
          mutationId={mutationId}
          setMutationId={setMutationId}
        />
      )}
      <Portal portalId="add-btn-contact">
        <SearchComponent
          id="search-container-id"
          getData={onSearchChange}
          className="search-component mr-10"
        />
        <Button
          shape="round"
          type="primary"
          id="add-btn"
          className="add-button d-flex align-center"
          onClick={handleAddEditContact}
          icon={<AddButton width={26} />}
          disabled={!agencyId}
        >
          {isDesktopViewport && 'Add'}
        </Button>
      </Portal>
      {isDesktopViewport ? (
        <div className="table">
          <CommonTable
            loadingData={loading}
            columns={columns}
            data={contactListData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
          />
        </div>
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          wrapperClassName="contact-scroll-wrapper"
          dataLength={contactListData?.length}
          skeletonRows={columns?.length - 2}
        >
          <div id="sortable-container-div" />
          {map(contactListData, (item, index) => {
            return (
              <CommonCard key={item?.id} className="contact-card">
                <div className="common-card d-flex">
                  <div className="mr-5 fw-medium">{index + 1}.</div>
                  <div>
                    <div className="card-header fw-medium">
                      <Row wrap={false}>
                        <Col flex="auto">{item?.name}</Col>
                      </Row>
                    </div>
                    <div className="card-content text-secondary">
                      <br />
                      <div className="mb-10">
                        <span className=" mr-5">{item?.designation}</span>
                      </div>
                      <div className="mb-10 d-flex align-center">
                        <span className=" mr-5">{item?.email}</span>
                      </div>
                      <div className=" d-flex align-center">
                        <span className=" mr-5">{item?.phoneNo}</span>
                      </div>
                    </div>
                  </div>
                  <span className="position-absolute contact-action-btn">
                    {getActionBtn(item)}
                  </span>
                </div>
              </CommonCard>
            );
          })}
        </InfiniteScrollHandler>
      )}
    </>
  );
};

export default AgencyContactTable;
