import { React } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Agencies from './Agencies';

const AgenciesWrapper = () => {
  return (
    <Switch>
      <Route path={ROUTES.AGENCIES} component={Agencies} />
    </Switch>
  );
};

export default AgenciesWrapper;
