import { Button, Col, Row, Space, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import {
  CommentWhiteIcon,
  Import,
  InfoIcon
} from '../../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  EQC_STATUS_CLASSNAME,
  EQC_STATUS_KEYS,
  EQC_STATUS_LABEL,
  GA_EVENT,
  GA_LABEL,
  UOMS
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import { timeTaken } from '../../../../../../../common/utils';
import CanPerform from '../../../../../../../components/CanPerform';
import CommonCard from '../../../../../../../components/CommonCard';
import AddCommentModal from './AddCommentModal';
import AuditorCommentModal from './AuditorCommentModal';
import EqcRenameModal from './EqcRenameModal';

const EqcSummary = ({ data = {}, refetch }) => {
  const {
    projectEqcTypeName,
    eqcName,
    uom,
    status,
    eqcRenameLogs,
    id,
    isAudited,
    eqcCompletedAt,
    eqcStartedAt
  } = data;
  const { projectId } = useParams();
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showAddCommentsModal, setShowAddCommentsModal] = useState(false);
  const [showAuditorCommentsModal, setShowAuditorCommentsModal] = useState(
    false
  );
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const { getToken, getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();

  const CommentButtons = () => (
    <Row gutter={isDesktopViewport ? [12, 12] : [8, 8]} wrap={false}>
      <Col flex="auto">
        <Button
          shape="round"
          icon={<CommentWhiteIcon />}
          className={`comment-button ${
            isDesktopViewport ? '' : 'width-percent-100 d-flex justify-center'
          }`}
          onClick={() => {
            setShowAuditorCommentsModal(true);
            Event(GA_EVENT.VIEW_AUDITOR_COMMENT_MODAL, {
              label: GA_LABEL.VIEW_AUDITOR_COMMENT_MODAL,
              // eslint-disable-next-line no-undef
              pathname: window?.location?.href,
              project_id: projectId,
              project_eqc_id: id,
              user_id: currentUser?.id,
              user_name: currentUser?.name,
              tenant_id: currentUser?.tenantUser?.tenant?.id,
              tenant_name: currentUser?.tenantUser?.tenant?.organizationName
            });
          }}
          disabled={!isAudited}
        >
          Auditor Comments
        </Button>
      </Col>
      <CanPerform
        action={ALLOWED_ACTION_KEYS.ADD_AUDITOR_COMMENTS}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        <Col span={!isDesktopViewport && 12}>
          <Button
            shape="round"
            icon={<CommentWhiteIcon />}
            className={`comment-button ${
              isDesktopViewport ? '' : 'width-percent-100 d-flex justify-center'
            }`}
            onClick={() => setShowAddCommentsModal(true)}
          >
            Add Comments
          </Button>
        </Col>
      </CanPerform>
    </Row>
  );

  const ReportButtons = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/report/eqcs/final-report/${id}?access_token=Bearer ${token}`;
    const detailedUrl = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/report/eqcs/detail-report/${id}?access_token=Bearer ${token}`;
    return (
      <Row
        gutter={isDesktopViewport ? [12, 12] : [8, 8]}
        className={!isDesktopViewport ? 'mb-15' : ''}
      >
        <Col span={!isDesktopViewport && 12}>
          <Button
            shape="round"
            target="_blank"
            href={detailedUrl}
            icon={<Import width="20px" />}
            className={`report-button ${
              isDesktopViewport ? '' : 'width-percent-100 d-flex justify-center'
            }`}
            onClick={() =>
              Event(GA_EVENT.DOWNLOAD_PROJECT_EQC_DETAILED_REPORT, {
                label: GA_LABEL.DOWNLOAD_PROJECT_EQC_DETAILED_REPORT,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
                project_id: projectId,
                project_eqc_id: id,
                user_id: currentUser?.id,
                user_name: currentUser?.name,
                tenant_id: currentUser?.tenantUser?.tenant?.id,
                tenant_name: currentUser?.tenantUser?.tenant?.organizationName
              })
            }
          >
            Detailed Report
          </Button>
        </Col>
        <Col span={!isDesktopViewport && 12}>
          <Button
            shape="round"
            target="_blank"
            href={status === EQC_STATUS_KEYS.PASSED && url}
            disabled={status !== EQC_STATUS_KEYS.PASSED}
            icon={<Import width="20px" />}
            className={`report-button ${
              isDesktopViewport ? '' : 'width-percent-100 d-flex justify-center'
            }`}
            onClick={() =>
              Event(GA_EVENT.DOWNLOAD_PROJECT_EQC_FINAL_REPORT, {
                label: GA_LABEL.DOWNLOAD_PROJECT_EQC_FINAL_REPORT,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
                project_id: projectId,
                project_eqc_id: id,
                user_id: currentUser?.id,
                user_name: currentUser?.name,
                tenant_id: currentUser?.tenantUser?.tenant?.id,
                tenant_name: currentUser?.tenantUser?.tenant?.organizationName
              })
            }
          >
            Final Report
          </Button>
        </Col>
      </Row>
    );
  };
  const handleRenameModal = () => {
    setShowRenameModal(true);
    Event(GA_EVENT.VIEW_PROJECT_EQC_RENAME_MODAL, {
      label: GA_LABEL.VIEW_PROJECT_EQC_RENAME_MODAL,
      // eslint-disable-next-line no-undef
      pathname: window?.location?.href,
      project_id: projectId,
      project_eqc_id: id,
      user_id: currentUser?.id,
      user_name: currentUser?.name,
      tenant_id: currentUser?.tenantUser?.tenant?.id,
      tenant_name: currentUser?.tenantUser?.tenant?.organizationName
    });
  };
  return (
    <div>
      <CommonCard className="eqc-tab-details-summary ">
        <div className="d-flex align-center justify-between mb-15">
          <div className="d-flex">
            <h2>EQC Summary</h2>
          </div>
          {isDesktopViewport && <ReportButtons />}
        </div>
        <div className="d-flex align-center justify-between mb-20 fw-medium">
          <Row gutter={isDesktopViewport ? [55, 40] : [40, 40]}>
            <Col span={!isDesktopViewport && 12}>
              <Space direction="vertical" size={12}>
                <Space size={10}>
                  <div className="d-flex align-center">
                    <div className="text-secondary mr-5">Name</div>
                    {eqcRenameLogs?.length > 0 && (
                      <InfoIcon
                        className="pointer"
                        onClick={handleRenameModal}
                      />
                    )}
                  </div>
                </Space>
                <div className="text-break">{eqcName}</div>
              </Space>
            </Col>
            <Col span={!isDesktopViewport && 12}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">EQC Type</div>
                <div className="text-break">{projectEqcTypeName}</div>
              </Space>
            </Col>
            <Col span={!isDesktopViewport && 12}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">UOM</div>
                <div>{UOMS[uom]}</div>
              </Space>
            </Col>
            <Col span={!isDesktopViewport && 12}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">EQC Status</div>
                <div className="fw-normal">
                  <Tag className={EQC_STATUS_CLASSNAME[status]}>
                    {EQC_STATUS_LABEL[status]}
                  </Tag>
                </div>
              </Space>
            </Col>
            <Col span={!isDesktopViewport && 12}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary fw-medium">Time taken</div>
                <div>
                  {eqcCompletedAt && eqcStartedAt
                    ? timeTaken(eqcStartedAt, eqcCompletedAt)
                    : '-'}
                </div>
              </Space>
            </Col>
          </Row>
          {isDesktopViewport && <CommentButtons />}
        </div>
        {!isDesktopViewport && (
          <>
            <ReportButtons /> <CommentButtons />
          </>
        )}
      </CommonCard>
      <EqcRenameModal
        showModal={showRenameModal}
        setShowModal={setShowRenameModal}
        data={eqcRenameLogs}
      />
      <AuditorCommentModal
        setShowModal={setShowAuditorCommentsModal}
        showModal={showAuditorCommentsModal}
        sectionId={id}
        section="EQC"
      />
      <AddCommentModal
        setShowModal={setShowAddCommentsModal}
        showModal={showAddCommentsModal}
        sectionId={id}
        section="EQC"
        refetch={refetch}
      />
    </div>
  );
};

export default EqcSummary;
