import { useLazyQuery } from '@apollo/client';
import { Button, message, Space } from 'antd';
import clsx from 'clsx';
import * as eva from 'eva-icons';
import { findIndex, isNaN } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import {
  CommentWhiteIcon,
  Export,
  ForwardArrow,
  ProjectIcon,
  PublishIcon,
  UnpublishIcon
} from '../../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  PROJECT_EQC_TYPE_STATUS,
  ROUTES,
  TAB_KEYS
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import CanPerform from '../../../../../../../components/CanPerform';
import LoaderComponent from '../../../../../../../components/LoaderComponent';
import Error404 from '../../../../../../../Error404';
import { GET_PROJECT_EQC_TYPE } from '../../../../../graphql/Queries';
import AddCommentModal from '../../eqc/eqcDetails/AddCommentModal';
import AuditorCommentModal from '../../eqc/eqcDetails/AuditorCommentModal';
import EqcDetailContent from './EqcDetailContent';
import PublishEqcTypeModal from './PublishEqcTypeModal';
import UnpublishEqcTypeModal from './UnpublishEqctypeModal';

const EqcTypeDetails = () => {
  const { eqcTypeId, projectId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showAuditorCommentsModal, setShowAuditorCommentsModal] = useState(
    false
  );
  const [showAddCommentsModal, setShowAddCommentsModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [eqcData, setEqcData] = useState();
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const eqcTypeFilter = history?.location?.state?.eqcTypeFilter;
  const eqcTypePagination = history?.location?.state?.eqcTypePagination;
  const { getToken, getCurrentUser, dispatch } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [
    fetchDataLazyQuery,
    { data: eqcTypeData, refetch, loading }
  ] = useLazyQuery(GET_PROJECT_EQC_TYPE, {
    fetchPolicy: 'network-only',
    variables: {
      id: eqcTypeId
    },
    onCompleted: (res) => {
      const index = findIndex(
        res?.getProjectEqcType?.project?.projectUsers,
        (user) => Number(user?.userId) === Number(currentUser?.id)
      );
      if (index !== -1) {
        const loggedInUserRole =
          res?.getProjectEqcType?.project?.projectUsers?.[index]?.roles;
        dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
      } else {
        dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
      }
    }
  });
  const fetchEqcTypeData = () => {
    if (!isNaN(Number(projectId)) && !isNaN(Number(eqcTypeId))) {
      fetchDataLazyQuery({
        variables: {
          id: eqcTypeId
        }
      });
    }
  };
  useEffect(() => {
    fetchEqcTypeData();
    return () => {
      dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = eqcTypeData?.getProjectEqcType;
  const renderDownloadButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/report/project-eqc-types/${eqcTypeId}/export?access_token=Bearer ${token}`;
    return (
      <Button
        shape="round"
        icon={<Export height="26px" />}
        className="export-button d-flex b-0 ml-10"
        target="_blank"
        href={fetchData?.projectEqcTypeStages?.length > 0 && url}
        onClick={() => {
          if (fetchData?.projectEqcTypeStages?.length <= 0) {
            message.error('Data not found.');
          }
          Event(GA_EVENT.EXPORT_PROJECT_EQC_TYPE, {
            label: GA_LABEL.EXPORT_PROJECT_EQC_TYPE,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            project_id: projectId,
            project_eqc_type_id: eqcTypeId,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName
          });
        }}
      >
        {isDesktopViewport && 'Export'}
      </Button>
    );
  };
  if (loading) {
    return <LoaderComponent setHeight={80} />;
  }
  if (isNaN(Number(projectId)) || isNaN(Number(eqcTypeId))) {
    return <Error404 />;
  }
  return (
    <div className="eqc-type-details">
      <AuditorCommentModal
        setShowModal={setShowAuditorCommentsModal}
        showModal={showAuditorCommentsModal}
        sectionId={eqcTypeId}
        section="EQC_TYPE"
      />
      <AddCommentModal
        setShowModal={setShowAddCommentsModal}
        showModal={showAddCommentsModal}
        sectionId={eqcTypeId}
        section="EQC_TYPE"
        refetch={() => {
          if (!fetchData?.isAudited) {
            fetchEqcTypeData();
          }
        }}
      />
      {showModal && (
        <PublishEqcTypeModal
          showModal={showModal}
          setShowModal={setShowModal}
          setEqcTypeData={setEqcData}
          eqcTypeData={eqcData}
          refetchEqcTypeData={refetch}
        />
      )}
      <UnpublishEqcTypeModal
        showModal={showUnpublishModal}
        setShowModal={setShowUnpublishModal}
        setEqcTypeData={setEqcData}
        eqcTypeData={eqcData}
        refetchEqcTypeData={refetch}
      />
      <div
        className={`project-details-title details-header  justify-between page-header ${
          isDesktopViewport ? 'd-flex' : ''
        }`}
      >
        <div
          className={`project-details-title-text d-flex ${
            isDesktopViewport ? '' : 'mb-15'
          }`}
        >
          <ProjectIcon
            onClick={() => {
              history.push(ROUTES.PROJECTS, {
                navFilter,
                navPagination
              });
            }}
            className="pointer"
          />
          <ForwardArrow />
          <h1
            className="pointer mr-10 text-primary"
            onClick={() => {
              history.push(
                `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.EQC_TYPES}`,
                {
                  navFilter,
                  navPagination,
                  eqcTypeFilter,
                  eqcTypePagination
                }
              );
            }}
          >
            {fetchData?.project?.name}
          </h1>
          <ForwardArrow />
          <h1>{fetchData?.name}</h1>
        </div>
        <Space>
          {renderDownloadButton()}
          {fetchData?.projectEqcTypeStages?.length > 0 && (
            <CanPerform
              action={ALLOWED_ACTION_KEYS.PUBLISH_PROJECT_EQC_TYPE}
              type={ALLOWED_ACTION_TYPE.BOTH}
            >
              {fetchData?.status === PROJECT_EQC_TYPE_STATUS?.PUBLISHED ? (
                <Button
                  shape="round"
                  className="d-flex b-0 "
                  icon={<UnpublishIcon />}
                  onClick={() => {
                    setShowUnpublishModal(true);
                    setEqcData(fetchData);
                  }}
                >
                  {isDesktopViewport && 'Unpublish'}
                </Button>
              ) : (
                fetchData.status === PROJECT_EQC_TYPE_STATUS?.UNPUBLISHED && (
                  <Button
                    shape="round"
                    className="d-flex b-0"
                    icon={<PublishIcon />}
                    onClick={() => {
                      setEqcData(fetchData);
                      setShowModal(true);
                    }}
                  >
                    {isDesktopViewport && 'Publish'}
                  </Button>
                )
              )}
            </CanPerform>
          )}
          <Button
            shape="round"
            icon={<CommentWhiteIcon width="16px" heigth="16px" />}
            className="comment-button b-0"
            onClick={() => {
              Event(GA_EVENT.VIEW_AUDITOR_COMMENT_MODAL, {
                label: GA_LABEL.VIEW_AUDITOR_COMMENT_MODAL,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
                project_id: projectId,
                project_eqc_type_id: eqcTypeId,
                user_id: currentUser?.id,
                user_name: currentUser?.name,
                tenant_id: currentUser?.tenantUser?.tenant?.id,
                tenant_name: currentUser?.tenantUser?.tenant?.organizationName
              });
              setShowAuditorCommentsModal(true);
            }}
            disabled={!fetchData?.isAudited}
          >
            {isDesktopViewport && 'Auditor Comments'}
          </Button>
          <CanPerform
            action={ALLOWED_ACTION_KEYS.ADD_AUDITOR_COMMENTS}
            type={ALLOWED_ACTION_TYPE.BOTH}
          >
            <Button
              shape="round"
              className="b-0"
              onClick={() => setShowAddCommentsModal(true)}
            >
              Add Comments
            </Button>
          </CanPerform>
        </Space>
      </div>
      <div className={clsx(isDesktopViewport && 'mt-25')}>
        <EqcDetailContent eqcTypeData={fetchData} />
      </div>
    </div>
  );
};

export default EqcTypeDetails;
