import { useLazyQuery } from '@apollo/client';
import { Col, Row, Tag, Tooltip } from 'antd';
import { forEach, map, nth, slice, values } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  DEFAULTDATEFORMAT,
  DEFAULT_PAGE_SIZE,
  EQC_STATUS_KEYS,
  ROUTES,
  STAGE_STATUS,
  STAGE_STATUS_KEYS,
  TAB_KEYS
} from '../../../common/constants';
import { titleCase } from '../../../common/utils';
import CollapsibleFilterWrapper from '../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../components/CommonCard';
import CommonDropdown from '../../../components/CommonDropdown';
import CommonSelect from '../../../components/CommonSelect';
import CommonTable from '../../../components/CommonTable';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../components/SearchComponent';
import { GET_PROJECT_DROPDOWN_LIST } from '../../projects/graphql/Queries';
import { GET_USERS_DROPDOWN_LIST } from '../../users/graphql/Queries';
import { ACTIVITY_LOGS, EQC_LIST } from '../graphql/Queries';

const { Option } = CommonSelect;

const Activity = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [sortedInfo, setSortedInfo] = useState({});
  const [scrollFlag, setScrollFlag] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [activityData, setActivityData] = useState([]);
  const history = useHistory();

  const initialActivityLogFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [activityLogFilter, setActivityLogFilter] = useState(
    initialActivityLogFilter
  );

  const [fetchActivityLogs, { loading }] = useLazyQuery(ACTIVITY_LOGS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.eqcActivityLogList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.eqcActivityLogList?.total
      };
      if (scrollFlag) {
        const datalist = [...activityData, ...data];
        setActivityData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setActivityData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const onStageStatusChange = (eqcStageStatus) => {
    setActivityLogFilter({
      ...activityLogFilter,
      eqcStageStatus
    });
    fetchActivityLogs({
      variables: {
        filter: {
          ...activityLogFilter,
          eqcStageStatus
        }
      }
    });
  };

  const onEqcStatusChange = (eqcStatus) => {
    setActivityLogFilter({
      ...activityLogFilter,
      eqcStatus
    });
    fetchActivityLogs({
      variables: {
        filter: {
          ...activityLogFilter,
          eqcStatus
        }
      }
    });
  };

  const onProjectChange = (projectId) => {
    setActivityLogFilter({
      ...activityLogFilter,
      projectId
    });
    fetchActivityLogs({
      variables: {
        filter: {
          ...activityLogFilter,
          projectId
        }
      }
    });
  };
  const onUserChange = (userId) => {
    setActivityLogFilter({
      ...activityLogFilter,
      userId
    });
    fetchActivityLogs({
      variables: {
        filter: {
          ...activityLogFilter,
          userId
        }
      }
    });
  };
  const onEqcTypeChange = (eqcType) => {
    setActivityLogFilter({
      ...activityLogFilter,
      eqcType
    });
    fetchActivityLogs({
      variables: {
        filter: {
          ...activityLogFilter,
          eqcType
        }
      }
    });
  };
  const activityLogsData = async (value) => {
    fetchActivityLogs({
      variables: {
        filter: {
          ...activityLogFilter,
          skip: 0,
          limit: DEFAULT_PAGE_SIZE,
          search: value
        }
      }
    });
    setActivityLogFilter({
      ...activityLogFilter,
      skip: 0,
      limit: DEFAULT_PAGE_SIZE,
      search: value
    });
  };
  useEffect(() => {
    fetchActivityLogs({
      variables: { filter: initialActivityLogFilter }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setActivityLogFilter({
        ...activityLogFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.field,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchActivityLogs({
        variables: {
          filter: {
            ...activityLogFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.field,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setActivityLogFilter({
        ...activityLogFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });
      fetchActivityLogs({
        variables: {
          filter: {
            ...activityLogFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' }
          }
        }
      });
    }
  };

  const ChangeEqcStatus = ({ record }) => {
    if (record?.eqcStatus === 'PASSED') {
      return (
        <Tag className="active-tag">{titleCase(EQC_STATUS_KEYS.PASSED)}</Tag>
      );
    }
    if (record?.eqcStatus === 'IN_PROGRESS') {
      return (
        <Tag className="yellow-tag">
          {titleCase(EQC_STATUS_KEYS.IN_PROGRESS)}
        </Tag>
      );
    }
    return <Tag color="red">{titleCase(EQC_STATUS_KEYS.TERMINATE)}</Tag>;
  };

  const ChangeStageStatus = ({ record }) => {
    switch (record?.eqcStageStatus) {
      case 'PASS':
        return <Tag className="active-tag">{titleCase(STAGE_STATUS.PASS)}</Tag>;
      case 'APPROVED':
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.APPROVED)}</Tag>
        );
      case 'APPROVAL_PENDING':
        return (
          <Tag className="yellow-tag">
            {titleCase(STAGE_STATUS.APPROVAL_PENDING)}
          </Tag>
        );
      case 'REDO':
        return <Tag color="red">{titleCase(STAGE_STATUS.REDO)}</Tag>;

      default:
        return <Tag color="red">{titleCase(STAGE_STATUS.FAIL)}</Tag>;
    }
  };

  const handleRefetch = () => {
    fetchActivityLogs({
      variables: {
        filter: {
          ...activityLogFilter,
          skip: activityData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  const getApproverName = (record) => {
    const fetchName = [];
    const {
      eqcStageStatus,
      eqcStageApprovals,
      externalApproverName,
      internalApproverName
    } = record;
    if (eqcStageStatus === STAGE_STATUS.APPROVAL_PENDING) {
      forEach(eqcStageApprovals, (approver) => {
        const {
          externalApproverName: extApprName,
          internalApproverName: intApprName
        } = approver;
        fetchName.push(intApprName || extApprName);
      });
    } else if (internalApproverName || externalApproverName) {
      fetchName.push(internalApproverName || externalApproverName);
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }
    return '-';
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{activityLogFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'PROJECT',
      dataIndex: 'projectName',
      key: 'projectName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'projectName' && sortedInfo?.order
    },
    {
      title: 'EQC TYPE',
      dataIndex: 'eqcType',
      key: 'eqcType',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'eqcType' && sortedInfo?.order
    },
    {
      title: 'STAGE',
      dataIndex: 'eqcStageName',
      key: 'eqcStageName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'eqcStageName' && sortedInfo?.order
    },
    {
      title: 'EQC',
      dataIndex: 'eqcName',
      key: 'eqcName',
      render: (text, record) => (
        <div className="text-primary">{record?.eqcName}</div>
      )
    },
    {
      title: 'EQC STATUS',
      key: 'eqcStatus',
      render: (eqcStatus, record) => {
        return <ChangeEqcStatus record={record} />;
      }
    },
    {
      title: 'STAGE STATUS',
      key: 'eqcStageStatus',
      render: (eqcStageStatus, record) => {
        return <ChangeStageStatus record={record} />;
      }
    },
    {
      title: 'APPROVER',
      key: 'approver',
      render: (approver, record) => getApproverName(record)
    },
    {
      title: 'USER',
      dataIndex: 'creatorName',
      key: 'creatorName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'creatorName' && sortedInfo?.order
    },
    {
      title: 'DATE',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (dueDate, record) => {
        return record?.createdAt
          ? moment(`${record?.createdAt}`).format(DEFAULTDATEFORMAT)
          : '-';
      }
    }
  ];
  const handleRowClick = (record) => {
    if (record?.projectId && record?.eqcId) {
      history.push(
        `${ROUTES.PROJECTS}/${record?.projectId}/${TAB_KEYS.EQC}/${record?.eqcId}`,
        {
          stageId: record?.stageId
        }
      );
    }
  };
  return (
    <div>
      <div className="d-flex justify-between">
        {isDesktopViewport && <h1>Activity Logs</h1>}
        <div className={isDesktopViewport ? '' : 'width-percent-100'}>
          <CollapsibleFilterWrapper
            searchProps={{
              className: 'search-component',
              getData: activityLogsData
            }}
            className="mb-15"
          >
            <Row
              className={`d-flex filter-search ${
                isDesktopViewport ? 'justify-end' : ''
              }`}
              wrap
              gutter={isDesktopViewport ? [10, 10] : [0, 10]}
            >
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  allowClear
                  placeholder="Project"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  onChange={onProjectChange}
                  query={GET_PROJECT_DROPDOWN_LIST}
                  fetchPolicy="network-only"
                  responsePath="projectDropdownList.data"
                  valuePath="id"
                  labelPath="name"
                  optionKey="project"
                  showSearch
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  allowClear
                  placeholder="User"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  onChange={onUserChange}
                  query={GET_USERS_DROPDOWN_LIST}
                  fetchPolicy="network-only"
                  responsePath="userDropdownList.data"
                  valuePath="id"
                  labelPath="name"
                  optionKey="user"
                  showSearch
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                  placement="bottomRight"
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  allowClear
                  placeholder="Eqc Type"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  onChange={onEqcTypeChange}
                  query={EQC_LIST}
                  fetchPolicy="network-only"
                  responsePath="eqcTypeDropdownList.data"
                  valuePath="name"
                  labelPath="name"
                  optionKey="eqc-type"
                  showSearch
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonSelect
                  allowClear
                  placeholder="Stage Status"
                  className={`instruction-selector ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  onChange={onStageStatusChange}
                >
                  {values(STAGE_STATUS_KEYS).map((stageStatus) => (
                    <Option key={stageStatus} value={stageStatus}>
                      {titleCase(stageStatus)}
                    </Option>
                  ))}
                </CommonSelect>
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonSelect
                  allowClear
                  placeholder="Eqc Status"
                  className={`instruction-selector ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  onChange={onEqcStatusChange}
                >
                  {values(EQC_STATUS_KEYS).map((eqcStatus) => (
                    <Option key={eqcStatus} value={eqcStatus}>
                      {titleCase(eqcStatus)}
                    </Option>
                  ))}
                </CommonSelect>
              </Col>
              {isDesktopViewport && (
                <Col>
                  <SearchComponent
                    id="search-container-id"
                    getData={activityLogsData}
                    className="search-component width-200"
                  />
                </Col>
              )}
            </Row>
          </CollapsibleFilterWrapper>
        </div>
      </div>

      {isDesktopViewport ? (
        <div className="table">
          <CommonTable
            loadingData={loading}
            columns={columns}
            data={activityData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowClassName="pointer"
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record)
              };
            }}
          />
        </div>
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          wrapperClassName="activity-scroll-wrapper"
          dataLength={activityData?.length}
          skeletonRows={columns?.length - 2}
        >
          {map(activityData, (activity, index) => {
            return (
              <CommonCard key={activity?.id} onClick={handleRowClick}>
                <div className="common-card d-flex">
                  <div className="mr-5 fw-medium">{index + 1}.</div>
                  <div>
                    <div className="card-header fw-medium">
                      <span className="text-break">
                        {activity?.projectName}
                      </span>
                    </div>
                    <div className="card-content text-secondary">
                      <br />
                      <div className="mb-15">
                        <span className="fw-medium mr-5">EQC Type:</span>
                        <span className="text-break">{activity?.eqcType}</span>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Stage:</span>
                        <span className="text-break">
                          {activity?.eqcStageName}
                        </span>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">EQC:</span>{' '}
                        <NavLink
                          className="text-primary text-underline pointer text-break"
                          to={`${ROUTES.PROJECTS}/${activity?.projectId}/${TAB_KEYS.EQC}/${activity?.eqcId}`}
                        >
                          {activity?.eqcName}
                        </NavLink>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">EQC Status:</span>
                        <ChangeEqcStatus record={activity} />
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">STAGE Status:</span>
                        <ChangeStageStatus record={activity} />
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Approver:</span>
                        {getApproverName(activity)}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">User:</span>
                        {activity?.creatorName}
                      </div>
                      <div>
                        <span className="fw-medium mr-5">Date:</span>
                        {activity?.createdAt
                          ? moment(`${activity?.createdAt}`).format(
                              DEFAULTDATEFORMAT
                            )
                          : '-'}
                      </div>
                    </div>
                  </div>
                </div>
              </CommonCard>
            );
          })}
        </InfiniteScrollHandler>
      )}
    </div>
  );
};

export default Activity;
