import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Space,
  Switch
} from 'antd';
import { omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import {
  ADD_PROJECT_STEPS_KEYS,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  PERMISSIONS,
  ROUTES
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { formValidatorRules } from '../../../common/utils';
import GoogleMapComponent from '../../../components/GoogleMap';
import LoaderComponent from '../../../components/LoaderComponent';
import { CREATE_PROJECT, UPDATE_PROJECT } from '../graphql/Mutation';
import { GET_SINGLE_PROJECT } from '../graphql/Queries';

const { required } = formValidatorRules;

const AddProjectDetails = ({ projectId }) => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [marker, setMarker] = useState(null);
  const [radius, setRadius] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const history = useHistory();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [fetchProjectDetails, { loading }] = useLazyQuery(GET_SINGLE_PROJECT, {
    variables: { id: Number(projectId) },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const formData = { ...res?.getProject };
      if (res?.getProject?.permissions?.includes(PERMISSIONS.FINGERPRINT)) {
        formData.fingerprint = true;
      }
      if (res?.getProject?.permissions?.includes(PERMISSIONS.LOCATION)) {
        formData.location = true;
      }
      form.setFieldsValue(formData);
      setMarker({
        lat: res?.getProject?.latitude,
        lng: res?.getProject?.longitude
      });
      setRadius(res?.getProject?.radius);
    },
    onError() {}
  });

  const [createProject, { loading: createLoading }] = useMutation(
    CREATE_PROJECT,
    {
      onError() {},
      onCompleted: (res) => {
        if (res?.createProject) {
          message.success('Project created successfully!');
          Event(GA_EVENT.ADD_NEW_PROJECT, {
            label: GA_LABEL.ADD_NEW_PROJECT,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName
          });
          history.push(
            `${ROUTES.ADD_PROJECTS}/${Number(res?.createProject?.id)}/${
              ADD_PROJECT_STEPS_KEYS.USERS
            }`
          );
        }
      }
    }
  );

  const [updateProject, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT,
    {
      onError() {},
      onCompleted: () => {
        Event(GA_EVENT.EDIT_NEW_PROJECT, {
          label: GA_LABEL.EDIT_NEW_PROJECT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        history.push(
          `${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.USERS}`
        );
      }
    }
  );

  const formSubmit = (values) => {
    const { location, fingerprint } = values;
    const permissions = [];
    if (location) {
      permissions.push(PERMISSIONS.LOCATION);
    }
    if (fingerprint) {
      permissions.push(PERMISSIONS.FINGERPRINT);
    }
    const data = omit({ ...values, permissions }, ['location', 'fingerprint']);
    if (projectId) {
      updateProject({
        variables: { data, id: Number(projectId) }
      });
    } else {
      createProject({
        variables: { data }
      });
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchProjectDetails({ variables: { id: Number(projectId) } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({ latitude: marker?.lat, longitude: marker?.lng });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker]);
  if (loading) return <LoaderComponent setHeight={0} />;
  return (
    <>
      <Form form={form} layout="vertical" onFinish={formSubmit}>
        <Row gutter={[18]}>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item
              rules={[
                required,
                { max: 50, message: 'Maximum 50 characters allowed' }
              ]}
              name="name"
              label="Name"
            >
              <Input allowClear placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item
              rules={[
                required,
                { max: 20, message: 'Maximum 20 characters allowed' }
              ]}
              name="uniqueCode"
              label="Unique Code"
            >
              <Input allowClear placeholder="Enter Project Code" />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item
              rules={[
                required,
                { max: 50, message: 'Maximum 50 characters allowed' }
              ]}
              name="clientName"
              label="Client Name"
            >
              <Input allowClear placeholder="Enter Client Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  max: 1000,
                  message: 'Description cannot be more than 1000 characters'
                }
              ]}
            >
              <Input.TextArea
                allowClear
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="mb-10">Permissions</div>
        <Space size={isDesktopViewport ? 50 : 25} className="mb-20">
          <div className="d-flex align-center">
            <div className="mr-16">Location:</div>
            <Form.Item name="location" className="m-0" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <div className="d-flex align-center">
            <div className="mr-16">Authentication:</div>
            <Form.Item
              name="fingerprint"
              className="m-0"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
        </Space>
        <h3 className="mt-25">Site Details</h3>
        <Row gutter={[18]}>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item rules={[required]} name="latitude" label="Latitude">
              <InputNumber placeholder="Latitude" disabled />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item rules={[required]} name="longitude" label="Longitude">
              <InputNumber placeholder="Longitude" disabled />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item rules={[required]} name="radius" label="Radius (m)">
              <InputNumber
                placeholder="Enter Radius"
                onChange={setRadius}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <GoogleMapComponent
          marker={marker}
          setMarker={setMarker}
          radius={radius}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <Row justify="end">
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            loading={createLoading || updateLoading}
          >
            Next
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default AddProjectDetails;
