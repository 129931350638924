import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  ACCESS_TYPE,
  BREAKPOINTS,
  LOGS_TABS_KEYS,
  ROUTES
} from '../../common/constants';
import HasAccess from '../../components/HasAccess';
import Activity from './pages/Activity';
import Instruction from './pages/Instructions';

const Logs = () => {
  const { TabPane } = Tabs;
  const { tab } = useParams();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(LOGS_TABS_KEYS.ACTIVITY);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  useEffect(() => {
    if (tab === LOGS_TABS_KEYS.INSTRUCTION) {
      setActiveKey(LOGS_TABS_KEYS.INSTRUCTION);
    } else if (tab === LOGS_TABS_KEYS.ACTIVITY) {
      setActiveKey(LOGS_TABS_KEYS.ACTIVITY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isDesktopViewport && (
        <>
          <div className="page-header text-primary">Logs</div>
        </>
      )}
      <div className="logs">
        <Tabs
          activeKey={activeKey}
          destroyInactiveTabPane
          onChange={(key) => {
            setActiveKey(key);
            history.push(`${ROUTES.LOGS}/${key}`);
          }}
        >
          {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
            <TabPane
              tab="Activity Logs"
              key={LOGS_TABS_KEYS.ACTIVITY}
              className="details-tab"
            >
              <div className="activity">
                <Activity />
              </div>
            </TabPane>
          )}
          {HasAccess({ type: ACCESS_TYPE.INSTRUCTION }) && (
            <TabPane tab="Issue Logs" key={LOGS_TABS_KEYS.INSTRUCTION}>
              <div className="instruction">
                <Instruction />
              </div>
            </TabPane>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default Logs;
