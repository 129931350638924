import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import clsx from 'clsx';
import { forEach, map, nth, slice } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  EditButton,
  KebabMenu
} from '../../../../../../assets/svg';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import CanPerform from '../../../../../../components/CanPerform';
import CommonCard from '../../../../../../components/CommonCard';
import CommonTable from '../../../../../../components/CommonTable';
import HasAccess from '../../../../../../components/HasAccess';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../../../../components/SearchComponent';
import { DELETE_PROJECT_AGENCY } from '../../../../graphql/Mutation';
import { GET_PROJECT_AGENCY_LIST } from '../../../../graphql/Queries';
import AddAgencyModal from './AddAgencyModal';
import DeleteProjectAgency from './DeleteProjectAgency';

const Agency = () => {
  const { projectId } = useParams();
  const initialProjectAgencyFilter = {
    skip: 0,
    limit: 10,
    projectId,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [projectAgencyFilter, setProjectAgencyFilter] = useState(
    initialProjectAgencyFilter
  );
  const [showModal, setShowModal] = useState(false);
  const [agencyData, setAgencyData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [mutationId, setMutationId] = useState('');
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [agencyListData, setAgencyListData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [fetchProjectAgency, { loading }] = useLazyQuery(
    GET_PROJECT_AGENCY_LIST,
    {
      variables: {
        filter: projectAgencyFilter
      },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.projectAgencyList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.projectAgencyList?.total
        };
        if (scrollFlag) {
          const datalist = [...agencyListData, ...data];
          setAgencyListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setAgencyListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  useEffect(() => {
    fetchProjectAgency({ variables: { filter: projectAgencyFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchAgencyDetails = () => {
    setPaginationProp(initialPaginationValue);
    setProjectAgencyFilter(initialProjectAgencyFilter);
    fetchProjectAgency({
      variables: { filter: { ...initialProjectAgencyFilter } }
    });
  };

  const handleRefetchAfterDelete = () => {
    const newSkip =
      agencyListData?.length === 1
        ? Math.max(0, projectAgencyFilter?.skip - paginationProp?.pageSize)
        : projectAgencyFilter?.skip;
    setProjectAgencyFilter({
      ...projectAgencyFilter,
      skip: newSkip
    });
    fetchProjectAgency({
      variables: {
        filter: {
          ...projectAgencyFilter,
          skip: newSkip
        }
      }
    });
  };

  const [deleteProjectAgency] = useMutation(DELETE_PROJECT_AGENCY, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_PROJECT_AGENCY, {
        label: GA_LABEL.DELETE_PROJECT_AGENCY,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_agency_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      handleRefetchAfterDelete();
    }
  });

  const handleTableChange = (pagination, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });

    if (sorter?.column) {
      setProjectAgencyFilter({
        ...projectAgencyFilter,
        skip: skip,
        limit: pagination.pageSize
      });
      fetchProjectAgency({
        variables: {
          filter: {
            ...projectAgencyFilter,
            skip,
            limit: pagination.pageSize
          }
        }
      });
    } else {
      setProjectAgencyFilter({
        ...projectAgencyFilter,
        skip: skip,
        limit: pagination.pageSize
      });

      fetchProjectAgency({
        variables: {
          filter: {
            ...projectAgencyFilter,
            skip,
            limit: pagination.pageSize
          }
        }
      });
    }
  };

  const handleAddEdit = () => {
    setShowModal(true);
  };
  const getContactNames = (record) => {
    const fetchName = [];
    if (record?.projectAgencyContacts?.length !== 0) {
      forEach(record?.projectAgencyContacts, function (o) {
        fetchName.push(o?.contact?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }

    return '-';
  };

  const getAssignedEqcType = (record) => {
    const fetchName = [];
    if (record?.projectAgencyEqcs?.length !== 0) {
      forEach(record?.projectAgencyEqcs, function (o) {
        fetchName.push(o?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }

    return '-';
  };

  const getActionButtons = (record) => {
    const handleEdit = () => {
      setAgencyData(record);
      handleAddEdit();
    };
    const handleDelete = () => {
      setTitle('Project Agency');
      setName(record?.agency?.name);
      setMutationId(record?.id);
      setDeleteModal(true);
    };
    return (
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_AGENCIES}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        {isDesktopViewport ? (
          <>
            <Tooltip>
              <Button
                shape="round"
                className="b-0"
                icon={<EditButton />}
                onClick={handleEdit}
              />
            </Tooltip>
            <Tooltip>
              <Button
                shape="round"
                className="b-0"
                icon={<DeleteButton />}
                onClick={handleDelete}
              />
            </Tooltip>
          </>
        ) : (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="Edit" onClick={handleEdit}>
                  Edit
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="Delete" onClick={handleDelete}>
                  <span className="text-danger">Delete</span>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <KebabMenu />
          </Dropdown>
        )}
      </CanPerform>
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{projectAgencyFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'NAME',
      key: 'name',
      render: (record) => {
        return record?.agency?.name;
      }
    },
    {
      title: 'TYPE',
      key: 'type',
      render: (record) => {
        return record?.agency?.type;
      }
    },
    {
      title: 'CONTACT NAME',
      key: 'contactName',
      render: (contact, record) => getContactNames(record)
    },
    ...(HasAccess({ type: ACCESS_TYPE.INSPECTION })
      ? [
          {
            title: 'ASSIGNED EQC TYPE',
            key: 'assignedEqc',
            render: (text, record) => getAssignedEqcType(record)
          }
        ]
      : []),
    {
      key: 'actions',
      align: 'right',
      render: (record) => getActionButtons(record)
    }
  ];

  const onSearchChange = async (value) => {
    setProjectAgencyFilter({ ...projectAgencyFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchProjectAgency({
      variables: { filter: { ...projectAgencyFilter, skip: 0, search: value } }
    });
  };
  const handleRefetch = () => {
    fetchProjectAgency({
      variables: {
        filter: {
          ...projectAgencyFilter,
          skip: agencyListData?.length,
          limit: DEFAULT_PAGE_SIZE
        }
      }
    });
  };

  return (
    <>
      {showModal && (
        <AddAgencyModal
          showModal={showModal}
          setShowModal={setShowModal}
          agencyData={agencyData}
          isUpdate={!!agencyData}
          setAgencyData={setAgencyData}
          refetchAgencyData={refetchAgencyDetails}
        />
      )}

      {deleteModal && (
        <DeleteProjectAgency
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          title={title}
          name={name}
          deleteProjectAgency={deleteProjectAgency}
          mutationId={mutationId}
          setMutationId={setMutationId}
        />
      )}
      <div className="project-tab-details">
        <div
          className={`project-tab-details-header ${
            isDesktopViewport ? 'd-flex justify-between align-center' : ''
          }`}
        >
          <h2>Agency Details</h2>
          <div className="d-flex align-center">
            <SearchComponent
              className={`search-component mr-10 ${
                !isDesktopViewport ? 'width-percent-100' : ''
              }`}
              getData={onSearchChange}
            />
            <CanPerform
              action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_AGENCIES}
              type={ALLOWED_ACTION_TYPE.BOTH}
            >
              <Button
                shape="round"
                type="primary"
                className="d-flex align-center"
                icon={
                  <AddButton
                    width="25px"
                    className={clsx(isDesktopViewport && 'mr-5')}
                  />
                }
                onClick={() => {
                  handleAddEdit();
                }}
              >
                {isDesktopViewport && 'Add'}
              </Button>
            </CanPerform>
          </div>
        </div>
        {isDesktopViewport ? (
          <CommonTable
            columns={columns}
            data={agencyListData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
            loading={loading}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={agencyListData?.length}
            skeletonRows={columns?.length - 3}
          >
            {map(agencyListData, (user, index) => {
              return (
                <CommonCard key={user?.id}>
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">{index + 1}.</div>
                    <div>
                      <div className="card-header fw-medium">
                        <span className="text-break">{user?.agency?.name}</span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Type:</span>
                          {user?.agency?.type}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Contact Name:</span>
                          {getContactNames(user)}
                        </div>
                        <div>
                          <span className="fw-medium mr-5">
                            Assigned EQC Type:
                          </span>
                          {getAssignedEqcType(user)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="d-flex position-absolute user-action-btn">
                    {getActionButtons(user)}
                  </span>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default Agency;
