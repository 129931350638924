import { useMutation } from '@apollo/client';
import { Button, Form, Input, message, Spin } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { BackArrow } from '../../assets/svg';
import { Password } from '../../assets/svg/login';
import { GA_EVENT, GA_LABEL, ROUTES } from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import Portal from '../../components/Portal';
import { CHANGE_PASSWORD } from './graphql/Mutations';

const { required, password } = formValidatorRules;

const ChangePassword = () => {
  const { dispatch, getCurrentUser } = useContext(AppContext);
  const history = useHistory();
  const currentUser = getCurrentUser();
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.EDIT_USER_PASSWORD, {
        label: GA_LABEL.EDIT_USER_PASSWORD,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      dispatch({ type: 'LOGOUT' });
      history.push(ROUTES.LOGIN);
    }
  });
  const onFinish = (e) => {
    const { oldPassword, newPassword, confirmPassword } = e;
    if (newPassword !== confirmPassword) {
      message.error('Password does not match');
      return 0;
    }
    changePassword({ variables: { data: { oldPassword, newPassword } } });
  };

  return (
    <div>
      <Portal portalId="header-left-content">
        <h1 className="header-h1">
          <BackArrow
            className="header-h1-backarrow-svg pointer"
            onClick={() => history.goBack()}
          />
          Change Password
        </h1>
      </Portal>
      <div className="change-password-content">
        <div className="change-password-inside-content">
          <div className="gx-mb-4">
            <h1>Change your password</h1>
          </div>
          <Spin spinning={false}>
            <Form
              name="change-password"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="oldPassword"
                label="Old Password"
                rules={[required]}
              >
                <Input.Password
                  addonBefore={<Password />}
                  placeholder="At least 8 characters long"
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[required, password]}
              >
                <Input.Password
                  addonBefore={<Password />}
                  placeholder="At least 8 characters long"
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                rules={[required, password]}
              >
                <Input.Password
                  addonBefore={<Password />}
                  placeholder="At least 8 characters long"
                />
              </Form.Item>
              <div className="form-buttons d-flex">
                <Form.Item className="mb-0 mr-10">
                  <Button
                    shape="round"
                    className="cancel-button"
                    onClick={() => {
                      history.push(ROUTES.LOGIN);
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item className="mb-0">
                  <Button
                    loading={loading}
                    type="primary"
                    shape="round"
                    className="save-button"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
