import { DatePicker } from 'antd';
import * as eva from 'eva-icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS, DEFAULTDATEFORMAT } from '../../common/constants';
import IndividualReport from './IndividualReport';
import ProjectReport from './ProjectReport';

const { RangePicker } = DatePicker;
const CustomRangePicker = ({ setDateSelected, setFromDate, setToDate }) => {
  const disabledDate = (current) => {
    // Can not select future date
    return current && current > moment().endOf('day');
  };
  return (
    <RangePicker
      className="custom-date-picker"
      disabledDate={disabledDate}
      separator="-"
      popupStyle={{ top: 30 }}
      suffixIcon={<i data-eva="calendar-outline" />}
      format={DEFAULTDATEFORMAT}
      dropdownClassName="custom-date-picker-panel"
      placeholder={['Start Date', 'End Date']}
      ranges={{
        Yesterday: [moment().subtract(1, 'days'), moment()],
        'Last 8 Days': [moment().subtract(7, 'days'), moment()],
        'Last 31 Days': [moment().subtract(30, 'days'), moment()],
        'Last 3 Months': [moment().subtract(3, 'months'), moment()]
      }}
      onChange={(e, dates) => {
        if (e) {
          setDateSelected(true);
        } else {
          setDateSelected(false);
        }
        setFromDate(dates[0]);
        setToDate(dates[1]);
      }}
    />
  );
};
const Reports = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [dateSelected, setDateSelected] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="reports">
        {!isDesktopViewport && (
          <div className="profile-header d-flex justify-between">
            <div className="page-header text-primary mr-10">Reports</div>
            <CustomRangePicker
              setDateSelected={setDateSelected}
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
          </div>
        )}
        {isDesktopViewport && (
          <>
            <div className="reports-header d-flex align-center justify-between">
              <div className="d-flex align-center">
                <i data-eva="file-text-outline" />
                <h1>Reports</h1>
              </div>
              <CustomRangePicker
                setDateSelected={setDateSelected}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </div>
          </>
        )}

        <div className="reports-content">
          <ProjectReport
            dateSelected={dateSelected}
            fromDate={fromDate}
            toDate={toDate}
          />
          <IndividualReport
            dateSelected={dateSelected}
            fromDate={fromDate}
            toDate={toDate}
          />
        </div>
      </div>
    </>
  );
};

export default Reports;
