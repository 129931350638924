import { useLazyQuery } from '@apollo/client';
import { Button, Form, Image, Input, message, Modal, Upload } from 'antd';
import { concat, filter, findIndex, get, map, nth } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FILE_SIZE_20MB, MAX_FILES_COUNT } from '../../common/constants';
import { fileUpload, getBase64 } from '../../common/utils';
import { GET_URL_BY_KEY } from '../profile/graphql/Queries';
import { GET_URLS } from './graphql/Queries';

const CommentModal = (props) => {
  const {
    showModal,
    setShowModal,
    data,
    stageItemData,
    setCommentData,
    commentData
  } = props;

  const itemHistory =
    stageItemData || nth(data?.getEqcStageHistoryWithoutAuth?.itemHistory, 0);

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [previewImage, setPreviewImage] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [itemImageList, setItemImageList] = useState([]);
  let files;

  const handleCancel = () => {
    setShowModal(false);
  };

  const [getImagesSignedUrls, { data: images }] = useLazyQuery(GET_URL_BY_KEY, {
    fetchPolicy: 'network-only'
  });

  const updateState = (urls = []) => {
    const comment = form.getFieldValue('comment');
    if (!stageItemData) {
      setCommentData((oldData) => {
        return {
          eqcStageItems: oldData?.eqcStageItems,
          eqcStage: {
            eqcStageId: data?.getEqcStageHistoryWithoutAuth?.eqcStageId,
            stageHistoryId: data?.getEqcStageHistoryWithoutAuth?.id,
            generalComment: comment,
            assets: imageList ? concat(imageList, urls) : urls
          }
        };
      });
    } else {
      setCommentData((oldData) => {
        const eqcStageItemData = {
          eqcStageItemId: stageItemData?.eqcStageItemId,
          stageHistoryId: data?.getEqcStageHistoryWithoutAuth?.id,
          generalRemark: comment,
          assets: itemImageList ? concat(itemImageList, urls) : urls
        };
        const newStageItems = [...oldData?.eqcStageItems];
        const index = findIndex(
          newStageItems,
          ({ eqcStageItemId }) =>
            eqcStageItemId === stageItemData?.eqcStageItemId
        );
        if (index === -1) {
          newStageItems.push(eqcStageItemData);
        } else {
          newStageItems.splice(index, 1, eqcStageItemData);
        }
        return {
          eqcStage: oldData?.eqcStage,
          eqcStageItems: newStageItems
        };
      });
    }
    setDisabled(false);
    setShowModal(false);
  };
  const [fetchSignedUrl] = useLazyQuery(GET_URLS, {
    fetchPolicy: 'network-only',
    onCompleted: async (response) => {
      const filteredFileList = filter(fileList, 'name');
      const promises = map(
        response?.getSignedPutUrls?.signedUrls,
        (imageUrl, index) =>
          fileUpload(imageUrl, filteredFileList[index]?.originFileObj).catch(
            () => {
              return message.error('Image upload failed!');
            }
          )
      );
      await Promise.all(promises);
      const assets = map(response?.getSignedPutUrls?.keys, (key, index) => ({
        type: stageItemData ? 'GENERAL_REMARK' : 'GENERAL_COMMENT',
        key: key,
        assetType: 'IMAGE',
        size: fileList?.[index]?.size
      }));
      updateState(assets);
    }
  });

  const handleOnChange = (info) => {
    const newFileList = filter(info.fileList, (file) => {
      if (file?.size > FILE_SIZE_20MB) {
        return false;
      }
      return file;
    });
    setFileList(newFileList);
  };
  const handleUploadImage = async () => {
    setDisabled(true);
    const fileSend = (anotherFileList) => {
      return map(anotherFileList, (file) => ({
        fileName: `approvalImages/${file?.name}`,
        contentType: file.type
      }));
    };
    const filteredFileList = filter(fileList, 'name');
    if (filteredFileList?.length) {
      try {
        fetchSignedUrl({
          variables: {
            data: fileSend(filteredFileList)
          }
        });
      } catch (error) {
        setDisabled(false);
      }
    } else {
      updateState();
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      const preview = await getBase64(file?.originFileObj);
      files = file.url || preview;
    }
    setPreviewImage((oldData) => [...oldData, files]);
  };

  const defaultList = (imageArr) => {
    return map(imageArr, (image, index) => ({
      uid: index,
      url: image
    }));
  };

  useEffect(() => {
    if (images?.getImagesSignedUrls?.length > 0) {
      setFileList(defaultList(images?.getImagesSignedUrls));
    }
  }, [images]);

  useEffect(() => {
    if (!form.getFieldValue('comment')) {
      if (!stageItemData) {
        const generalComment = get(commentData, `eqcStage.generalComment`);
        const generalCommentImage = map(commentData?.eqcStage?.assets, `key`);
        if (generalCommentImage?.length > 0) {
          getImagesSignedUrls({
            variables: {
              data: { keys: generalCommentImage }
            }
          });
        }
        setFileList(
          defaultList(generalCommentImage || itemHistory?.generalCommentImages)
        );
        setImageList(
          commentData?.eqcStage?.assets || itemHistory?.generalCommentImages
        );
        form.setFieldsValue({
          comment: generalComment || itemHistory?.generalComment
        });
      } else {
        const index = findIndex(
          commentData?.eqcStageItems,
          ({ eqcStageItemId }) =>
            eqcStageItemId === stageItemData?.eqcStageItemId
        );
        const generalRemark = get(
          commentData,
          `eqcStageItems[${index}].generalRemark`
        );
        const generalRemarkImages = map(
          commentData?.eqcStageItems[index]?.assets,
          `key`
        );
        if (generalRemarkImages?.length > 0) {
          getImagesSignedUrls({
            variables: {
              data: { keys: generalRemarkImages }
            }
          });
        }
        setFileList(
          defaultList(generalRemarkImages || itemHistory?.generalRemarkImages)
        );
        setItemImageList(
          commentData?.eqcStageItems[index]?.assets ||
            itemHistory?.generalRemarkImages
        );
        form.setFieldsValue({
          comment: generalRemark || itemHistory?.generalRemark
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentData, itemHistory?.generalComment]);

  const onFinish = async () => {
    await handleUploadImage();
  };

  const handleRemove = (info) => {
    if (stageItemData) {
      const index = findIndex(
        commentData?.eqcStageItems,
        ({ eqcStageItemId }) => eqcStageItemId === stageItemData?.eqcStageItemId
      );

      commentData?.eqcStageItems?.[index]?.assets?.splice(info.uid, 1);
      setItemImageList(commentData?.eqcStageItems?.[index]?.assets);
    } else {
      commentData?.eqcStage?.assets?.splice(info.uid, 1);
      setImageList(commentData?.eqcStage?.assets);
    }
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="comment"
          label="Comment"
          rules={[
            {
              max: 1000,
              message: 'Comment cannot be more than 1000 characters'
            }
          ]}
        >
          <TextArea />
        </Form.Item>
        <div className="mb-10 text-secondary">Images</div>
        <Upload
          maxCount={MAX_FILES_COUNT}
          accept=".png,.jpeg,.jpg"
          multiple
          listType="picture-card"
          fileList={fileList}
          onChange={(info) => {
            handleOnChange(info);
          }}
          beforeUpload={() => false}
          onPreview={(e) => {
            handlePreview(e);
          }}
          onRemove={(e) => handleRemove(e)}
          showUploadList={{ showPreviewIcon: false }}
        >
          <div
            className={`height-percent-100 width-percent-100 d-flex align-center justify-center ${
              fileList?.length === MAX_FILES_COUNT
                ? 'cursor-disabled'
                : 'pointer'
            }`}
            onClick={(e) => {
              if (fileList?.length === MAX_FILES_COUNT) {
                e.stopPropagation();
              }
            }}
          >
            <Button
              type="primary"
              shape="round"
              disabled={fileList?.length === MAX_FILES_COUNT}
            >
              Add
            </Button>
          </div>
        </Upload>
        <div>
          {React.Children.map(previewImage, (photo) => (
            <Image src={photo} />
          ))}
        </div>
        <div className="mt-10 mb-10 text-danger">
          * Maximum file size is 20MB
        </div>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            className="save-button"
            htmlType="submit"
            disabled={disabled}
            type="primary"
          >
            {disabled ? 'Uploading...' : 'Save'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CommentModal;
