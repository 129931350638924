import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Row, Switch } from 'antd';
import { omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../../../AppContext';
import {
  GA_EVENT,
  GA_LABEL,
  WARNINGS
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../../../common/utils';
import CommonDropdown from '../../../../../../../../components/CommonDropdown';
import {
  CREATE_PROJECT_EQC_STAGE,
  UPDATE_PROJECT_EQC_STAGE
} from '../../../../../../graphql/Mutation';
import {
  GET_EXTERNAL_USERS_LIST,
  GET_INTERNAL_USERS_LIST
} from '../../../../../../graphql/Queries';

const { required } = formValidatorRules;

const AddEditStageModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    stageData,
    setStageData,
    eqcTypeId,
    refetchStageDataWithInitialValues,
    isDisabled,
    eqcTypeData
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const { projectId } = useParams();
  const [showApproval, setShowApproval] = useState(stageData?.needApproval);
  const [isSelectAllInternal, setIsSelectAllInternal] = useState(false);
  const [isSelectAllExternal, setIsSelectAllExternal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [isApproverChanged, setIsApproverChanged] = useState(false);

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setStageData();
  };
  const [createStage, { loading: createLoading }] = useMutation(
    CREATE_PROJECT_EQC_STAGE,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_PROJECT_EQC_TYPE_STAGE, {
          label: GA_LABEL.ADD_PROJECT_EQC_TYPE_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setStageData();
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      }
    }
  );

  const [updateStage, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT_EQC_STAGE,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_PROJECT_EQC_TYPE_STAGE, {
          label: GA_LABEL.EDIT_PROJECT_EQC_TYPE_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_stage_id: stageData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setStageData();
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      }
    }
  );

  const onSubmitFinish = async (formValues) => {
    let newFormValues = {
      name: formValues?.name,
      projectEqcTypeId: Number(eqcTypeId),
      witness: formValues?.witness || false,
      drawing: formValues?.drawing || false,
      needApproval: formValues?.needApproval || false,
      internalApproverIds: isSelectAllInternal
        ? []
        : formValues?.internalApproverIds,
      externalApproverIds: isSelectAllExternal
        ? []
        : formValues?.externalApproverIds,
      selectedAllInternalApprovers: isSelectAllInternal,
      selectedAllExternalApprovers: isSelectAllExternal
    };
    if (isDisabled) {
      newFormValues = omit(newFormValues, ['name', 'witness', 'drawing']);
    }
    const variables = isUpdate
      ? { data: newFormValues, id: stageData.id }
      : newFormValues;
    try {
      if (isUpdate) {
        await updateStage({
          variables: { ...variables }
        });
        return;
      }
      await createStage({
        variables: { data: { ...variables } }
      });
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    if (stageData) {
      setShowApproval(stageData?.needApproval);
      const externalApproverIds = [];
      const internalApproverIds = [];
      stageData?.projectEqcTypeStageApprovals?.forEach((element) => {
        if (element?.internalApproverId) {
          internalApproverIds.push(element.internalApproverId);
        } else {
          externalApproverIds.push(element.externalApproverId);
        }
      });
      form.setFieldsValue({
        ...stageData,
        externalApproverIds,
        internalApproverIds
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageData]);

  const handleInternalDeselect = () => {
    setIsSelectAllInternal(false);
    form.setFieldsValue({ internalApproverIds: [] });
  };
  useEffect(() => {
    if (isSelectAllInternal) {
      form.setFieldsValue({ internalApproverIds: ['all'] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAllInternal]);
  const handleExternalDeselect = () => {
    setIsSelectAllExternal(false);
    form.setFieldsValue({ externalApproverIds: [] });
  };
  useEffect(() => {
    if (isSelectAllExternal) {
      form.setFieldsValue({ externalApproverIds: ['all'] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAllExternal]);

  const handleFieldsChange = () => {
    if (eqcTypeData?.isPublished) {
      const formData = form.getFieldsValue(true);
      if (formData?.needApproval && isApproverChanged) {
        setWarning(WARNINGS.APPROVER_CHANGED);
      } else if (stageData?.needApproval && !formData.needApproval) {
        setWarning(WARNINGS.APPROVER_REMOVED);
      } else {
        setWarning('');
      }
    }
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      className="dialog"
      footer={null}
      closable={false}
      destroyOnClose
    >
      <h2>{isUpdate ? 'Edit Stage' : 'Add Stage'}</h2>
      <Form
        form={form}
        initialValues={stageData}
        layout="vertical"
        onFinish={onSubmitFinish}
        onFieldsChange={handleFieldsChange}
      >
        <Form.Item
          rules={[
            required,
            {
              max: 250,
              message: 'Stage name cannot be more than 250 characters'
            }
          ]}
          name="name"
          label="Name"
        >
          <Input
            allowClear
            placeholder="Enter Stage Name"
            disabled={isDisabled}
          />
        </Form.Item>
        <Row>
          <Col span={8}>
            <Form.Item
              name="witness"
              label="Witness Required"
              valuePropName="checked"
            >
              <Switch disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="drawing"
              label="Drawing Required"
              valuePropName="checked"
            >
              <Switch disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="needApproval"
              label="Approval Required"
              valuePropName="checked"
            >
              <Switch onChange={setShowApproval} />
            </Form.Item>
          </Col>
        </Row>
        {showApproval && (
          <>
            <Form.Item
              name="internalApproverIds"
              dependencies={['externalApproverIds']}
              rules={[
                ({ getFieldValue }) => ({
                  required: !getFieldValue('externalApproverIds')?.length,
                  message: 'Please select approver',
                  type: 'array'
                })
              ]}
              label={
                <div className="d-flex justify-between width-percent-100">
                  <span>Internal Approvers</span>
                  {isSelectAllInternal && (
                    <span
                      className="text-primary pointer"
                      role="button"
                      tabIndex="0"
                      onClick={handleInternalDeselect}
                    >
                      Deselect All
                    </span>
                  )}
                </div>
              }
              normalize={(value) => {
                if (value.includes('all')) {
                  setIsSelectAllInternal(true);
                  return ['all'];
                }
                return value;
              }}
            >
              <CommonDropdown
                mode="multiple"
                showSearch
                optionFilterProp="children"
                placeholder="Select Internal Approvers"
                query={GET_INTERNAL_USERS_LIST}
                variables={{
                  filter: { projectId, projectEqcTypeStageId: stageData?.id }
                }}
                responsePath="internalApproverDropdownList.data"
                valuePath="id"
                labelPath="user.name"
                optionKey="internal-approver"
                fetchPolicy="network-only"
                disabled={isSelectAllInternal}
                isSelectedAll={isSelectAllInternal}
                hasSelectAll
                onChange={() => setIsApproverChanged(true)}
              />
            </Form.Item>
            <Form.Item
              name="externalApproverIds"
              dependencies={['internalApproverIds']}
              rules={[
                ({ getFieldValue }) => ({
                  required: !getFieldValue('internalApproverIds')?.length,
                  message: 'Please select approver',
                  type: 'array'
                })
              ]}
              label={
                <div className="d-flex justify-between width-percent-100">
                  <span>External Approvers</span>
                  {isSelectAllExternal && (
                    <span
                      className="text-primary pointer"
                      role="button"
                      tabIndex="0"
                      onClick={handleExternalDeselect}
                    >
                      Deselect All
                    </span>
                  )}
                </div>
              }
              normalize={(value) => {
                if (value.includes('all')) {
                  setIsSelectAllExternal(true);
                  return ['all'];
                }
                return value;
              }}
            >
              <CommonDropdown
                mode="multiple"
                showSearch
                optionFilterProp="children"
                placeholder="Select External Approvers"
                query={GET_EXTERNAL_USERS_LIST}
                variables={{
                  filter: { projectId, projectEqcTypeStageId: stageData?.id }
                }}
                responsePath="externalApproverDropdownList.data"
                valuePath="id"
                labelPath={['name', 'agency.name']}
                optionKey="external-approver"
                fetchPolicy="network-only"
                disabled={isSelectAllExternal}
                isSelectedAll={isSelectAllExternal}
                hasSelectAll
                onChange={() => setIsApproverChanged(true)}
              />
            </Form.Item>
          </>
        )}
        {warning && <div className="text-danger">{warning}</div>}
        <div className="form-buttons mt-15">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={createLoading || updateLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditStageModal;
