import { useLazyQuery } from '@apollo/client';
import { Button, Col, Dropdown, Menu, Row, Tag, Tooltip } from 'antd';
import { find, forEach, keys, map, nth, slice } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  Checkmark,
  CommentBlackIcon,
  EditButton,
  KebabMenu
} from '../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULTDATEFORMAT,
  DEFAULTDATETIMEFORMAT,
  DEFAULT_PAGE_SIZE,
  EQC_STATUS,
  EQC_STATUS_CLASSNAME,
  EQC_STATUS_LABEL,
  PROJECT_EQC_TYPE_STATUS,
  ROUTES,
  STAGE_STATUS,
  STAGE_STATUS_LABEL,
  STAGE_STATUS_TEXT_CLASS,
  TAB_KEYS
} from '../../../../../../common/constants';
import { titleCase } from '../../../../../../common/utils';
import CanPerform from '../../../../../../components/CanPerform';
import CollapsibleFilterWrapper from '../../../../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../../../../components/CommonCard';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import CommonSelect from '../../../../../../components/CommonSelect';
import CommonTable from '../../../../../../components/CommonTable';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../../../../components/SearchComponent';
import { GET_USERS_DROPDOWN_LIST } from '../../../../../users/graphql/Queries';
import {
  GET_PROJECT_EQCTYPES_LIST_MIN,
  GET_PROJECT_EQC_LIST
} from '../../../../graphql/Queries';
import EditEqcModal from './EditEqcModal';
import MarkQCPassModal from './MarkQCPassModal';

const { Option } = CommonSelect;

const Eqc = ({ uniqueCode }) => {
  const { projectId } = useParams();
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const eqcFilter = history?.location?.state?.eqcFilter;
  const eqcPagination = history?.location?.state?.eqcPagination;
  const eqcTypeRecord = history?.location?.state?.eqcTypeRecord;
  const userRecord = history?.location?.state?.userRecord;
  const initialProjectEqcFilter = {
    skip: 0,
    limit: 10,
    projectId: Number(projectId),
    sortBy: { field: 'doneAt', order: 'DESC' }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [paginationProp, setPaginationProp] = useState(
    eqcPagination || initialPaginationValue
  );
  const [projectEqcFilter, setProjectEqcFilter] = useState(
    eqcFilter || initialProjectEqcFilter
  );
  const [showModal, setShowModal] = useState();
  const [showMarkQCPassModal, setShowMarkQCPassModal] = useState();
  const [sortedInfo, setSortedInfo] = useState({});
  const [eqcStatus, setEqcStatus] = useState(eqcFilter?.eqcStatus);
  const [eqcStageStatus, setEqcStageStatus] = useState(
    eqcFilter?.eqcStageStatus
  );
  const [userId, setUserId] = useState(eqcFilter?.userId);
  const [projectEqcTypeId, setProjectEqcTypeId] = useState(
    eqcFilter?.projectEqcTypeId
  );
  const [selectedEqcTypeRecord, setSelectedEqcTypeRecord] = useState(
    eqcTypeRecord
  );
  const [selectedUserRecord, setSelectedUserRecord] = useState(userRecord);
  const [eqcData, setEqcData] = useState([]);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [eqcListData, setEqcListData] = useState();

  const [fetchEqcData, { loading }] = useLazyQuery(GET_PROJECT_EQC_LIST, {
    variables: {
      filter: projectEqcFilter
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.eqcList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.eqcList?.total
      };
      if (scrollFlag) {
        const datalist = [...eqcListData, ...data];
        setEqcListData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setEqcListData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() {}
  });
  useEffect(() => {
    if (eqcTypeRecord) {
      setSelectedEqcTypeRecord(eqcTypeRecord);
    }
  }, [eqcTypeRecord]);
  useEffect(() => {
    const newFilter = {
      ...projectEqcFilter,
      eqcStageStatus,
      projectEqcTypeId,
      eqcStatus,
      userId
    };
    setProjectEqcFilter(newFilter);
    fetchEqcData({ variables: { filter: { ...newFilter } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcStageStatus, projectEqcTypeId, eqcStatus, userId]);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectEqcFilter({
        ...projectEqcFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchEqcData({
        variables: {
          filter: {
            ...projectEqcFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setProjectEqcFilter({
        ...projectEqcFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'doneAt', order: 'DESC' }
      });

      fetchEqcData({
        variables: {
          filter: {
            ...projectEqcFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'doneAt', order: 'DESC' }
          }
        }
      });
    }
  };
  const getEQCStatus = (eqcStageHistories) => {
    const stageHistory = nth(eqcStageHistories, 0);
    return stageHistory ? stageHistory.status : '-';
  };
  const getStageNameAndStatus = (eqcStageHistories) => {
    if (!eqcStageHistories || !eqcStageHistories.length) {
      return '-';
    }
    const { name, status } = nth(eqcStageHistories, 0);
    return (
      <div>
        <span>{name || '-'}</span>
        <span
          className={`text-no-break d-inline-flex ${STAGE_STATUS_TEXT_CLASS[status]}`}
        >
          ({titleCase(STAGE_STATUS[status])})
        </span>
      </div>
    );
  };
  const getApproverName = (values, record) => {
    const fetchName = [];
    const stageHistory = nth(record?.eqcStageHistories, 0);
    const projectStageID = stageHistory?.eqcStageId?.toString();
    const stage = find(record?.eqcStages, ['id', projectStageID]);
    const {
      status,
      eqcStageApprovals,
      externalApproverName,
      internalApproverName
    } = stage;
    if (status === STAGE_STATUS.APPROVAL_PENDING) {
      forEach(eqcStageApprovals, (approver) => {
        const {
          externalApproverName: extApprName,
          internalApproverName: intApprName
        } = approver;
        fetchName.push(intApprName || extApprName);
      });
    } else if (internalApproverName || externalApproverName) {
      fetchName.push(internalApproverName || externalApproverName);
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }
    return '-';
  };
  const handleEdit = (e, record) => {
    e?.stopPropagation?.();
    setEqcData({ uniqueCode, id: record?.id, eqcName: record?.eqcName });
    setShowModal(true);
  };
  const handleMarkQCPass = (e, record) => {
    e?.stopPropagation?.();
    setShowMarkQCPassModal(true);
    setEqcData(record);
  };

  const getActionButtons = (record) => {
    const showPassBtn =
      getEQCStatus(record?.eqcStageHistories) === EQC_STATUS.APPROVED;
    const conditionForEdit =
      CanPerform({
        action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC,
        type: ALLOWED_ACTION_TYPE.PROJECT
      }) && record?.status !== EQC_STATUS.PASSED;
    const conditionForPassButton =
      CanPerform({
        action: ALLOWED_ACTION_KEYS.MARK_QC_PASS,
        type: ALLOWED_ACTION_TYPE.PROJECT
      }) && showPassBtn;
    const showDropdownMenu = conditionForEdit || conditionForPassButton;

    return isDesktopViewport ? (
      <div className="d-flex" onClick={(e) => e?.stopPropagation?.()}>
        <CanPerform
          action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC}
          type={ALLOWED_ACTION_TYPE.PROJECT}
        >
          <Tooltip
            title={
              record?.status === EQC_STATUS.PASSED
                ? 'Completed eqc name cannot be changed!'
                : 'Edit'
            }
          >
            <Button
              shape="round"
              disabled={record?.status === EQC_STATUS.PASSED}
              icon={<EditButton />}
              onClick={(e) => handleEdit(e, record)}
            />
          </Tooltip>
        </CanPerform>
        {showPassBtn && (
          <CanPerform
            action={ALLOWED_ACTION_KEYS.MARK_QC_PASS}
            type={ALLOWED_ACTION_TYPE.PROJECT}
          >
            <Tooltip title={showPassBtn ? 'Pass' : 'Not Required!'}>
              <Button
                shape="round"
                disabled={!showPassBtn}
                icon={<Checkmark />}
                onClick={(e) => handleMarkQCPass(e, record)}
              />
            </Tooltip>
          </CanPerform>
        )}
      </div>
    ) : (
      showDropdownMenu && (
        <Dropdown
          overlay={
            <Menu>
              {CanPerform({
                action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_EQC,
                type: ALLOWED_ACTION_TYPE.PROJECT
              }) && (
                <>
                  <Menu.Item key="edit" onClick={(e) => handleEdit(e, record)}>
                    Edit
                  </Menu.Item>
                  {conditionForPassButton && <Menu.Divider />}
                </>
              )}
              {CanPerform({
                action: ALLOWED_ACTION_KEYS.MARK_QC_PASS,
                type: ALLOWED_ACTION_TYPE.PROJECT
              }) &&
                showPassBtn && (
                  <Menu.Item
                    key="mark-qc-pass"
                    onClick={(e) => handleMarkQCPass(e, record)}
                  >
                    Pass
                  </Menu.Item>
                )}
            </Menu>
          }
          trigger={['click']}
        >
          <KebabMenu />
        </Dropdown>
      )
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return (
          <div className="d-flex align-center">
            {projectEqcFilter?.skip + index + 1}
            {record?.isAudited && <CommentBlackIcon className="ml-10" />}
          </div>
        );
      }
    },
    {
      title: 'NAME',
      key: 'eqcName',
      dataIndex: 'eqcName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'eqcName' && sortedInfo?.order,
      render: (text) => {
        return text || '-';
      }
    },
    {
      title: 'TYPE',
      dataIndex: 'projectEqcTypeName',
      key: 'projectEqcTypeName',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'projectEqcTypeName' && sortedInfo?.order,
      render: (text) => {
        return text || '-';
      }
    },
    {
      title: 'STAGE',
      key: 'eqcStageHistories',
      dataIndex: 'eqcStageHistories',
      render: (text) => {
        return getStageNameAndStatus(text);
      }
    },
    {
      title: 'EQC STATUS',
      key: 'status',
      dataIndex: 'status',
      render: (value) => (
        <Tag className={EQC_STATUS_CLASSNAME[value]}>
          {EQC_STATUS_LABEL[value]}
        </Tag>
      )
    },
    {
      title: 'APPROVER',
      key: 'approver',
      dataIndex: 'approver',
      render: (values, record) => getApproverName(values, record)
    },
    {
      title: 'UPDATED BY',
      key: 'modifier',
      dataIndex: 'modifier',
      render: (values) => values?.name || '-'
    },
    {
      title: 'DONE AT',
      key: 'doneAt',
      dataIndex: 'doneAt',
      sortOrder: sortedInfo?.columnKey === 'doneAt' && sortedInfo?.order,
      sorter: true,
      render: (updatedAt, record) => {
        return record?.doneAt
          ? moment(`${record?.doneAt}`).format(DEFAULTDATEFORMAT)
          : '-';
      }
    },
    {
      key: 'actions',
      align: 'right',
      render: (record) => getActionButtons(record)
    }
  ];

  const onSearchChange = async (value) => {
    setProjectEqcFilter({
      ...projectEqcFilter,
      skip: 0,
      search: value
    });
    setPaginationProp(initialPaginationValue);
    fetchEqcData({
      variables: { filter: { ...projectEqcFilter, skip: 0, search: value } }
    });
  };
  const handleRefetch = () => {
    fetchEqcData({
      variables: {
        filter: {
          ...projectEqcFilter,
          skip: eqcListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'doneAt', order: 'DESC' }
        }
      }
    });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      history.push(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.EQC}/${record.id}`,
        {
          navFilter,
          navPagination,
          eqcFilter: projectEqcFilter,
          eqcPagination: paginationProp,
          eqcTypeRecord: selectedEqcTypeRecord,
          userRecord: selectedUserRecord
        }
      );
    }
  };
  return (
    <>
      <div className="project-tab-details">
        <div
          className={`project-tab-details-header ${
            isDesktopViewport ? 'd-flex justify-between align-center' : ''
          }`}
        >
          <h2 className="mr-10">Eqc Details</h2>
          <CollapsibleFilterWrapper
            searchProps={{
              className: 'search-component',
              getData: onSearchChange,
              defaultValue: eqcFilter?.search
            }}
          >
            <Row
              className="d-flex justify-end filter-search"
              wrap
              gutter={isDesktopViewport ? [10, 10] : [0, 10]}
            >
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  placeholder="EQC Type"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  value={projectEqcTypeId}
                  onChange={(v, record) => {
                    setSelectedEqcTypeRecord(record);
                    setProjectEqcTypeId(v);
                  }}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  query={GET_PROJECT_EQCTYPES_LIST_MIN}
                  variables={{
                    filter: {
                      projectId: Number(projectId),
                      status: [
                        PROJECT_EQC_TYPE_STATUS?.PUBLISHED,
                        PROJECT_EQC_TYPE_STATUS?.ARCHIVED
                      ]
                    }
                  }}
                  responsePath="projectEqcTypeList.data"
                  valuePath="id"
                  labelPath="name"
                  optionKey="eqc-type"
                  customOptions={selectedEqcTypeRecord}
                  dropdownMatchSelectWidth={false}
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  allowClear
                  placeholder="User"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  onChange={(v, record) => {
                    setUserId(v);
                    setSelectedUserRecord(record);
                  }}
                  value={userId}
                  query={GET_USERS_DROPDOWN_LIST}
                  fetchPolicy="network-only"
                  responsePath="userDropdownList.data"
                  valuePath="id"
                  labelPath="name"
                  optionKey="user"
                  showSearch
                  optionFilterProp="children"
                  customOptions={selectedUserRecord}
                  dropdownMatchSelectWidth={false}
                  placement="bottomRight"
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonSelect
                  placeholder="Stage Status"
                  className={`instruction-selector ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  value={eqcStageStatus}
                  onChange={(v) => setEqcStageStatus(v)}
                  allowClear
                >
                  {map(keys(STAGE_STATUS_LABEL), (status, index) => (
                    <Option key={index} value={status}>
                      {STAGE_STATUS_LABEL[status]}
                    </Option>
                  ))}
                </CommonSelect>
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonSelect
                  placeholder="EQC Status"
                  className={`instruction-selector ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  value={eqcStatus}
                  onChange={(v) => setEqcStatus(v)}
                  allowClear
                >
                  {map(keys(EQC_STATUS_LABEL), (status) => (
                    <Option key={`eqc-status-${status}`} value={status}>
                      {EQC_STATUS_LABEL[status]}
                    </Option>
                  ))}
                </CommonSelect>
              </Col>
              {isDesktopViewport && (
                <Col>
                  <SearchComponent
                    className="search-component width-200"
                    getData={onSearchChange}
                    defaultValue={eqcFilter?.search}
                  />
                </Col>
              )}
            </Row>
          </CollapsibleFilterWrapper>
        </div>
        {isDesktopViewport ? (
          <div className="table pointer">
            <CommonTable
              columns={columns}
              data={eqcListData || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey={(obj) => obj?.id}
              loading={loading}
              onRow={(record) => {
                return {
                  onClick: () => handleRowClick(record)
                };
              }}
            />
          </div>
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={eqcListData?.length}
            skeletonRows={columns?.length - 4}
          >
            {map(eqcListData, (eqc, index) => {
              return (
                <CommonCard key={eqc?.id} onClick={() => handleRowClick(eqc)}>
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">
                      <div className="d-flex align-center">{index + 1}.</div>
                    </div>
                    <div>
                      <div className="card-header fw-medium">
                        <span className="text-break">{eqc?.eqcName}</span>
                        <span>
                          {eqc?.isAudited && (
                            <CommentBlackIcon className="ml-10" />
                          )}
                        </span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Type:</span>
                          {eqc?.projectEqcTypeName}
                        </div>
                        <div className="mb-15 d-flex">
                          <span className="fw-medium mr-5">Stage:</span>
                          {getStageNameAndStatus(eqc?.eqcStageHistories)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">EQC Status:</span>
                          <Tag className={EQC_STATUS_CLASSNAME[eqc?.status]}>
                            {EQC_STATUS_LABEL[eqc?.status]}
                          </Tag>
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Approver:</span>
                          {getApproverName(eqc?.approver, eqc)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Updated By:</span>
                          {eqc?.creator?.name}
                        </div>
                        <div>
                          <span className="fw-medium mr-5">Done At:</span>
                          {eqc?.updatedAt
                            ? moment(`${eqc?.updatedAt}`).format(
                                DEFAULTDATETIMEFORMAT
                              )
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <span
                      className="d-flex position-absolute user-action-btn"
                      onClick={(e) => {
                        e?.stopPropagation?.();
                      }}
                    >
                      {getActionButtons(eqc)}
                    </span>
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
      <EditEqcModal
        showModal={showModal}
        setShowModal={setShowModal}
        eqcData={eqcData}
        setEqcData={setEqcData}
        refetchEqcData={() =>
          fetchEqcData({
            variables: { filter: projectEqcFilter }
          })
        }
      />
      {showMarkQCPassModal && (
        <MarkQCPassModal
          showModal={showMarkQCPassModal}
          setShowModal={setShowMarkQCPassModal}
          eqcData={eqcData}
          setEqcData={setEqcData}
          refetchEqcData={() =>
            fetchEqcData({
              variables: {
                filter: {
                  ...projectEqcFilter
                }
              }
            })
          }
        />
      )}
    </>
  );
};

export default Eqc;
