import { Input } from 'antd';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { SearchIcon } from '../assets/svg';

let searchDebounce = null;

const SearchComponent = (props) => {
  const { name = '', getData, className, defaultValue, ...rest } = props;
  const [query, setQuery] = useState(defaultValue || '');
  const handleChange = ({ target: { value } }) => {
    setQuery(value);
    if (getData) {
      if (searchDebounce) {
        searchDebounce.cancel();
        searchDebounce = null;
      }
      searchDebounce = debounce(getData, 500);
      searchDebounce(value);
    }
  };

  return (
    <Input
      className={`search-component ${className || ''}`}
      allowClear
      placeholder={`Search ${name}`}
      value={query}
      name={name}
      onChange={handleChange}
      onPressEnter={handleChange}
      prefix={<SearchIcon />}
      {...rest}
    />
  );
};

export default SearchComponent;
