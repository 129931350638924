import { Card } from 'antd';
import React from 'react';
import StageListTable from './StageListTable';

const StageList = ({ setStageId, stageId, eqcTypeData, setModalVisible }) => {
  return (
    <Card className="stage-list-card">
      <div className="header d-flex align-center justify-between mb-10">
        <h2>EQC Type Stage</h2>
        <div className="eqc-stage-header-buttons d-flex">
          <div id="eqc-details-btn" className="d-flex" />
          <div id="add-btn" />
        </div>
      </div>
      <StageListTable
        setStageId={setStageId}
        stageId={stageId}
        eqcTypeData={eqcTypeData}
        setModalVisible={setModalVisible}
      />
    </Card>
  );
};

export default StageList;
