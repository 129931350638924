import { Card } from 'antd';
import React from 'react';
import StageItemTable from './StageItemTable';

const StageItemList = ({ stageId, eqcTypeData }) => {
  return (
    <Card className="stage-item-card">
      <div className="header d-flex align-center justify-between mb-10">
        <h2>Stage Items</h2>
        <div className="eqc-stage-header-buttons d-flex">
          <div id="stageitem-add-btn" />
        </div>
      </div>
      <StageItemTable stageId={stageId} eqcTypeData={eqcTypeData} />
    </Card>
  );
};

export default StageItemList;
