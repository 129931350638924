import { Button, Card, Descriptions, Image, Space, Tag } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { MessageIcon } from '../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  GA_EVENT,
  GA_LABEL,
  STAGE_STATUS,
  STAGE_STATUS_KEYS
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { timeTaken, titleCase } from '../../common/utils';
import CommentModal from './CommentModal';
import CommentSection from './CommentSection';

const StageSummary = ({ data, refetch, commentData, setCommentData }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showModal, setShowModal] = useState();
  const stageStatus = (status) => {
    switch (status) {
      case STAGE_STATUS_KEYS.PASS:
        return <Tag className="active-tag">{titleCase(STAGE_STATUS.PASS)}</Tag>;
      case STAGE_STATUS_KEYS.APPROVED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.APPROVED)}</Tag>
        );
      case STAGE_STATUS_KEYS.APPROVAL_PENDING:
        return (
          <Tag className="yellow-tag">
            {titleCase(STAGE_STATUS.APPROVAL_PENDING)}
          </Tag>
        );
      case STAGE_STATUS_KEYS.REDO:
        return <Tag color="red">{titleCase(STAGE_STATUS.REDO)}</Tag>;

      default:
        return <Tag color="red">{titleCase(STAGE_STATUS.FAIL)}</Tag>;
    }
  };
  const approverName =
    data?.getEqcStageHistoryWithoutAuth?.reviewedBy?.agencyApprover?.name ||
    data?.getEqcStageHistoryWithoutAuth?.reviewedBy?.projectUserApprover?.users
      ?.name;

  const getPhotos = (photos, imageId) => {
    return photos?.length ? (
      <Image.PreviewGroup
        preview={{
          visible: imageId === previewImageKey && isVisible,
          onVisibleChange: (visible) => {
            setIsVisible(visible);
            if (visible) {
              Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                label: GA_LABEL.OPENED_IMAGE_VIEWER,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href
              });
            }
          }
        }}
      >
        <Space className="image-preview-wrapper">
          {React.Children.map(photos, (photo) => (
            <Image
              src={photo}
              alt="logo"
              height={isDesktopViewport ? '64px' : '32px'}
              width={isDesktopViewport ? '64px' : '32px'}
              onClick={() => setPreviewImageKey(imageId)}
            />
          ))}
          {photos?.length - 1 > 0 && (
            <div
              className="pointer extra-count"
              onClick={() => {
                setIsVisible(true);
                setPreviewImageKey(imageId);
              }}
            >{`+ ${photos?.length - 1}`}</div>
          )}
        </Space>
      </Image.PreviewGroup>
    ) : (
      'N/A'
    );
  };
  return (
    <>
      {showModal && (
        <CommentModal
          showModal={showModal}
          setShowModal={setShowModal}
          data={data}
          refetch={refetch}
          setCommentData={setCommentData}
          commentData={commentData}
        />
      )}
      <Card>
        <div className="d-flex align-center justify-between mb-10">
          <h2>General</h2>
        </div>
        <div className="d-flex flex-vertical ">
          <Descriptions layout="vertical" column={isDesktopViewport ? 5 : 2}>
            <Descriptions.Item label="Updated By" className="common-item">
              {data?.getEqcStageHistoryWithoutAuth?.action?.name}
            </Descriptions.Item>

            <Descriptions.Item label="Completed On" className="common-item">
              <Space direction="vertical" size={12}>
                <div>
                  {data?.getEqcStageHistoryWithoutAuth?.eqcStageCompletedAt
                    ? moment(
                        data?.getEqcStageHistoryWithoutAuth?.eqcStageCompletedAt
                      ).format(DATETIMEWITHDIVIDE)
                    : '-'}
                </div>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="Synced On" className="common-item">
              <Space direction="vertical" size={12}>
                <div>
                  {data?.getEqcStageHistoryWithoutAuth?.eqc?.eqcStages
                    ?.qcStageSynceStartedAt
                    ? moment(
                        data?.getEqcStageHistoryWithoutAuth?.eqc?.eqcStages
                          ?.qcStageSynceStartedAt
                      ).format(DATETIMEWITHDIVIDE)
                    : '-'}
                </div>
              </Space>
            </Descriptions.Item>

            <Descriptions.Item label="Stage Status" className="common-item">
              {stageStatus(data?.getEqcStageHistoryWithoutAuth?.status)}
            </Descriptions.Item>
            <Descriptions.Item label="Witness" className="common-item">
              {data?.getEqcStageHistoryWithoutAuth?.witnessesId
                ?.map((witnessesId) => witnessesId)
                .join(', ')}
            </Descriptions.Item>
            <Descriptions.Item label="Time Elapsed" className="common-item">
              {data?.getEqcStageHistoryWithoutAuth?.eqcStageStartedAt &&
              data?.getEqcStageHistoryWithoutAuth?.eqcStageCompletedAt
                ? timeTaken(
                    data?.getEqcStageHistoryWithoutAuth?.eqcStageStartedAt,
                    data?.getEqcStageHistoryWithoutAuth?.eqcStageCompletedAt
                  )
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Location" className="common-item">
              {data?.getEqcStageHistoryWithoutAuth?.latitude &&
              data?.getEqcStageHistoryWithoutAuth?.longitude ? (
                <a
                  href={`https://maps.google.com/?q=${data?.getEqcStageHistoryWithoutAuth?.latitude},${data?.getEqcStageHistoryWithoutAuth?.longitude}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    Event(GA_EVENT.VIEW_LOCATION, {
                      label: GA_LABEL.VIEW_LOCATION,
                      // eslint-disable-next-line no-undef
                      pathname: window?.location?.href
                    });
                  }}
                >
                  View Location
                </a>
              ) : (
                '-'
              )}
            </Descriptions.Item>
          </Descriptions>
          <div
            className={
              isDesktopViewport
                ? 'd-flex justify-between align-end'
                : 'd-flex flex-vertical'
            }
          >
            <Descriptions column={isDesktopViewport ? 5 : 2} layout="vertical">
              <Descriptions.Item
                label="Drawing Title Photos"
                className="common-item"
              >
                {data?.getEqcStageHistoryWithoutAuth?.drawingTitleImg
                  ?.length === 0 ||
                data?.getEqcStageHistoryWithoutAuth?.drawingTitleImg ===
                  null ? (
                  'N/A'
                ) : (
                  <>
                    {getPhotos(
                      data?.getEqcStageHistoryWithoutAuth?.drawingTitleImg,
                      'drawingTitleImg'
                    )}
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Drawing Photos">
                {data?.getEqcStageHistoryWithoutAuth?.drawingImg?.length ===
                  0 ||
                data?.getEqcStageHistoryWithoutAuth?.drawingImg === null ? (
                  'N/A'
                ) : (
                  <>
                    {getPhotos(
                      data?.getEqcStageHistoryWithoutAuth?.drawingImg,
                      'drawingImg'
                    )}
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label="Witness Photos"
                className={clsx(!isDesktopViewport && 'common-item')}
              >
                {data?.getEqcStageHistoryWithoutAuth?.witnessesImg?.length ===
                  0 ||
                data?.getEqcStageHistoryWithoutAuth?.witnessesImg === null ? (
                  'N/A'
                ) : (
                  <>
                    {getPhotos(
                      data?.getEqcStageHistoryWithoutAuth?.witnessesImg,
                      'witnessesImg'
                    )}
                  </>
                )}
              </Descriptions.Item>
              {approverName && (
                <Descriptions.Item label="Reviewed By">
                  {approverName}
                </Descriptions.Item>
              )}
            </Descriptions>
            {data &&
              data?.getEqcStageHistoryWithoutAuth?.status ===
                STAGE_STATUS.APPROVAL_PENDING && (
                <div>
                  <Button
                    type="primary"
                    shape="round"
                    icon={<MessageIcon />}
                    className={`${
                      !isDesktopViewport
                        ? 'width-percent-100 justify-center mb-24'
                        : 'mb-40'
                    } ${
                      (commentData?.eqcStage?.generalComment ||
                        commentData?.eqcStage?.assets?.length > 0) &&
                      'general-comment'
                    }`}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    General Comment
                  </Button>
                </div>
              )}
          </div>
        </div>
        <CommentSection data={data} />
      </Card>
    </>
  );
};

export default StageSummary;
