import { useMutation } from '@apollo/client';
import { Button, Form, Input, Spin } from 'antd';
import React, { useEffect } from 'react';
import { Password } from '../../assets/svg/login';
import { GA_EVENT, GA_LABEL, ROUTES } from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import { RESET_PASSWORD, TOKEN_VALIDATION } from './graphql/Mutations';

const { required, password: passwordRules } = formValidatorRules;

const ResetPassword = (props) => {
  const { history, history: { location: { search } = {} } = {} } = props;

  const getQuery = () => {
    return new URLSearchParams(search);
  };
  const id = getQuery().get('uid');
  const resetToken = getQuery().get('token');

  const [tokenValid, { loading }] = useMutation(TOKEN_VALIDATION, {
    onError() {}
  });
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted() {
      Event(GA_EVENT.RESET_PASSWORD, {
        label: GA_LABEL.RESET_PASSWORD,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        user_id: id
      });
      history.replace(ROUTES.LOGIN);
    },
    onError() {}
  });

  useEffect(() => {
    tokenValid({ variables: { data: { token: resetToken, uId: Number(id) } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenValid]);

  const onFinish = ({ password }) => {
    resetPassword({
      variables: {
        data: { password: password, uId: Number(id), token: resetToken }
      },
      context: {
        headers: {
          'x-token': resetToken
        }
      }
    });
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <h2>Reset Password</h2>
          <p>Enter a new password for your account</p>
        </div>
        <Spin spinning={false}>
          <Form
            name="change-password"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item
              name="password"
              label="New Password"
              rules={[required, passwordRules]}
            >
              <Input.Password
                addonBefore={<Password />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              name="retype-password"
              label="Confirm Password"
              dependencies={['password']}
              rules={[
                required,
                passwordRules,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value !== getFieldValue('password')) {
                      return Promise.reject(
                        new Error('Passwords do not match')
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input.Password
                addonBefore={<Password />}
                placeholder="Retype password"
              />
            </Form.Item>
            <div className="form-buttons d-flex">
              <Form.Item>
                <Button
                  className="cancel-button mr-10"
                  onClick={() => {
                    history.push(ROUTES.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  shape="round"
                  className="save-button"
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ResetPassword;
