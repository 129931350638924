import { useMutation } from '@apollo/client';
import { Button, Col, Form, Modal, Row, Switch } from 'antd';
import { keys } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import {
  ACCESS_TYPE,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  PERMISSIONS,
  PROJECT_EQC_TYPE_STATUS,
  PROJECT_USER_ROLES,
  USER_TAB_ROLES
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import { formValidatorRules, titleCase } from '../../../../../../common/utils';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import CommonSelect from '../../../../../../components/CommonSelect';
import HasAccess from '../../../../../../components/HasAccess';
import {
  CREATE_PROJECT_USER,
  UPDATE_PROJECT_USER
} from '../../../../graphql/Mutation';
import {
  GET_PROJECT_USER_DROPDOWN_LIST,
  GET_PROJECT_USER_EQC_TYPES_LIST
} from '../../../../graphql/Queries';

const { Option } = CommonSelect;

const AddUserModal = (props) => {
  const {
    showModal,
    setShowModal,
    setProjectUserData,
    refetchUserData,
    projectUserData,
    isUpdate
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { projectId } = useParams();
  const { required } = formValidatorRules;
  let fetchRole;
  const [form] = Form.useForm();
  const [eqcTypeData, setEqcTypeData] = useState([]);
  const [isExternalAuditor, setIsExternalAuditor] = useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [projectAdmin, setProjectAdmin] = useState(false);
  const [auditor, setAuditor] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const handleCancel = () => {
    setProjectUserData();
    setShowModal(false);
  };

  const [createProjectUser, { loading: createLoading }] = useMutation(
    CREATE_PROJECT_USER,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_PROJECT_USER, {
          label: GA_LABEL.ADD_PROJECT_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setProjectUserData();
        form.resetFields();
        setShowModal(false);
        refetchUserData();
      }
    }
  );

  const [updateProjectUser, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT_USER,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_PROJECT_USER, {
          label: GA_LABEL.EDIT_PROJECT_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_user_id: projectUserData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setProjectUserData();
        form.resetFields();
        setShowModal(false);
        refetchUserData();
      }
    }
  );

  const onFinish = async (formValues) => {
    const values = {
      location: formValues?.location,
      fingerprint: formValues?.fingerprint
    };

    const permissions = [];
    if (values.location) {
      if (isExternalAuditor || auditor) {
        permissions.push();
      } else {
        permissions.push(PERMISSIONS.LOCATION);
      }
    }
    if (values.fingerprint) {
      if (isExternalAuditor || auditor) {
        permissions.push();
      } else {
        permissions.push(PERMISSIONS.FINGERPRINT);
      }
    }

    const newFormValues = {
      projectId: projectId,
      userId: formValues?.projectUserId,
      roles: formValues?.roles,
      webAccess: formValues?.webAccess,
      permissions: permissions,
      selectedAllEqcTypes: isSelectAll,
      projectEqcTypeIds: isSelectAll ? [] : formValues?.projectEqcTypeIds
    };
    if (hasInspectionAccess) {
      newFormValues.projectEqcTypeIds = newFormValues?.projectEqcTypeIds || [];
    }
    const variables = isUpdate
      ? {
          data: {
            ...newFormValues,
            userId: projectUserData?.user?.id
          },
          id: projectUserData?.id
        }
      : newFormValues;
    try {
      if (isUpdate) {
        await updateProjectUser({
          variables: {
            ...variables
          }
        });

        return;
      }
      await createProjectUser({
        variables: {
          data: {
            ...variables
          }
        }
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (isUpdate) {
      if (
        projectUserData?.user?.roles?.[0] === USER_TAB_ROLES.EXTERNAL_AUDITOR
      ) {
        setIsExternalAuditor(true);
      }
      if (projectUserData?.user?.roles?.[0] === USER_TAB_ROLES.SYSTEM_ADMIN) {
        setIsSystemAdmin(true);
      }
      if (projectUserData?.user?.roles?.[0] === USER_TAB_ROLES.USER) {
        if (projectUserData?.roles === USER_TAB_ROLES.AUDITOR) {
          setAuditor(true);
        }
        if (projectUserData?.roles === USER_TAB_ROLES.PROJECT_ADMIN) {
          setProjectAdmin(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkRoles = (e, user) => {
    if (e && e === user?.id) {
      setProjectAdmin(false);
      setAuditor(false);
      fetchRole = user?.roles;
      if (fetchRole?.[0] === USER_TAB_ROLES.EXTERNAL_AUDITOR) {
        setIsExternalAuditor(true);
        form.setFieldsValue({
          roles: USER_TAB_ROLES.EXTERNAL_AUDITOR,
          webAccess: true
        });
      } else {
        setIsExternalAuditor(false);
        setIsSelectAll(false);
        form.setFieldsValue({
          roles: undefined
        });
      }
      if (fetchRole?.[0] === USER_TAB_ROLES.SYSTEM_ADMIN) {
        setIsSystemAdmin(true);
        form.setFieldsValue({
          roles: USER_TAB_ROLES.PROJECT_ADMIN,
          webAccess: true
        });
        if (hasInspectionAccess) {
          form.setFieldsValue({
            projectEqcTypeIds: eqcTypeData?.length ? ['all'] : []
          });
          setIsSelectAll(true);
        }
      } else {
        setIsSystemAdmin(false);
        if (hasInspectionAccess) {
          form.setFieldsValue({
            projectEqcTypeIds: undefined
          });
          setIsSelectAll(false);
        }
      }
    }
  };

  const handleRoleSelect = (roleSelected) => {
    if (roleSelected === USER_TAB_ROLES.PROJECT_ADMIN) {
      setProjectAdmin(true);
      if (hasInspectionAccess) {
        form.setFieldsValue({
          webAccess: true,
          projectEqcTypeIds: eqcTypeData?.length ? ['all'] : []
        });
        setIsSelectAll(true);
      }
    } else {
      setProjectAdmin(false);
      if (hasInspectionAccess) {
        form.setFieldsValue({
          projectEqcTypeIds: undefined
        });
        setIsSelectAll(false);
      }
    }
    if (roleSelected === USER_TAB_ROLES.AUDITOR) {
      form.setFieldsValue({
        fingerprint: false,
        location: false,
        webAccess: true
      });
      setAuditor(true);
    } else {
      setAuditor(false);
    }
  };
  const handleDeselect = () => {
    setIsSelectAll(false);
    form.setFieldsValue({ projectEqcTypeIds: [] });
  };
  useEffect(() => {
    if (isSelectAll) {
      form.setFieldsValue({
        projectEqcTypeIds: eqcTypeData?.length ? ['all'] : []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAll]);

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      footer={null}
      closable={false}
      className="project-user-modal"
    >
      <h2 className="mb-15">{isUpdate ? 'Edit User' : 'Add User'}</h2>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={
          isUpdate
            ? {
                ...projectUserData,
                projectUserId: projectUserData?.user?.name,
                projectEqcTypeIds:
                  hasInspectionAccess &&
                  projectUserData?.projectUserEqcTypes?.map(
                    (eqctype) => eqctype?.id
                  ),
                location: !!projectUserData?.permissions?.includes(
                  PERMISSIONS.LOCATION
                ),
                fingerprint: !!projectUserData?.permissions?.includes(
                  PERMISSIONS.FINGERPRINT
                )
              }
            : { location: true, fingerprint: true, webAccess: true }
        }
      >
        <Form.Item label="User" name="projectUserId" rules={[required]}>
          <CommonDropdown
            placeholder="Select User"
            disabled={isUpdate}
            showSearch
            optionFilterProp="children"
            onChange={(id, user) => {
              checkRoles(id, user?.[0]);
            }}
            allowClear
            query={GET_PROJECT_USER_DROPDOWN_LIST}
            variables={{
              filter: { projectId: Number(projectId) }
            }}
            fetchPolicy="network-only"
            responsePath="projectUserDropdownList.data"
            valuePath="id"
            labelPath="name"
            optionKey="user"
          />
        </Form.Item>
        <Form.Item label="Role" name="roles" rules={[required]}>
          <CommonSelect
            placeholder="Select Role"
            disabled={isExternalAuditor || isSystemAdmin}
            onChange={(e) => {
              handleRoleSelect(e);
            }}
            showSearch
            optionFilterProp="children"
          >
            {keys(PROJECT_USER_ROLES).map((role) => (
              <Option key={PROJECT_USER_ROLES[role]} value={role}>
                {titleCase(PROJECT_USER_ROLES[role])}
              </Option>
            ))}
            {isExternalAuditor && (
              <Option key="EXTERNAL AUDITOR" value="EXTERNAL_AUDITOR">
                External auditor
              </Option>
            )}
            {isSystemAdmin && (
              <Option key="PROJECT ADMIN" value="PROJECT_ADMIN">
                Project Admin
              </Option>
            )}
          </CommonSelect>
        </Form.Item>
        <h4>Permissions</h4>
        <Row gutter={50}>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item name="location" label="Location" valuePropName="checked">
              <Switch disabled={isExternalAuditor || auditor} />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item
              name="fingerprint"
              label="Authentication"
              valuePropName="checked"
            >
              <Switch disabled={isExternalAuditor || auditor} />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 8 : 24}>
            <Form.Item
              name="webAccess"
              label="Web Access"
              valuePropName="checked"
            >
              <Switch
                disabled={
                  isExternalAuditor || isSystemAdmin || projectAdmin || auditor
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {hasInspectionAccess && (
          <Form.Item
            label={
              <div className="d-flex justify-between width-percent-100">
                <div>EQC Type</div>
                {!(
                  isExternalAuditor ||
                  isSystemAdmin ||
                  projectAdmin ||
                  auditor
                ) &&
                  isSelectAll && (
                    <div
                      className="text-primary pointer"
                      role="button"
                      tabIndex="0"
                      onClick={handleDeselect}
                    >
                      Deselect All
                    </div>
                  )}
              </div>
            }
            name="projectEqcTypeIds"
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectAll(true);
                return ['all'];
              }
              return value;
            }}
          >
            <CommonDropdown
              placeholder="Select EQC Type"
              mode="multiple"
              disabled={
                isExternalAuditor ||
                isSystemAdmin ||
                projectAdmin ||
                auditor ||
                isSelectAll
              }
              showSearch
              optionFilterProp="children"
              allowClear
              query={GET_PROJECT_USER_EQC_TYPES_LIST}
              variables={{
                filter: {
                  projectId: Number(projectId),
                  status: PROJECT_EQC_TYPE_STATUS?.PUBLISHED,
                  projectUserId: projectUserData?.id
                }
              }}
              fetchPolicy="network-only"
              responsePath="projectUserEqcTypeDropdownList.data"
              valuePath="id"
              labelPath="name"
              optionKey="eqc-type"
              hasSelectAll
              isSelectedAll={isSelectAll}
              callback={(res) =>
                setEqcTypeData((oldData = []) => [
                  ...oldData,
                  ...res?.projectUserEqcTypeDropdownList?.data
                ])
              }
            />
          </Form.Item>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={createLoading || updateLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
