import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import { get, keys, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../../AppContext';
import {
  GA_EVENT,
  GA_LABEL,
  PROJECT_EQC_TYPE_STATUS,
  UOMS,
  WARNINGS
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../../common/utils';
import CommonDropdown from '../../../../../../../components/CommonDropdown';
import CommonSelect from '../../../../../../../components/CommonSelect';
import { PUBLISH_EQC_TYPE } from '../../../../../graphql/Mutation';
import {
  GET_PROJECT_AGENCY_FOR_EQC_TYPES,
  GET_PROJECT_USERS_FOR_EQC_TYPES
} from '../../../../../graphql/Queries';

const { Option } = CommonSelect;

const PublishEqcTypeModal = (props) => {
  const {
    showModal,
    setShowModal,
    eqcTypeData,
    setEqcTypeData,
    refetchEqcTypeData
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { required } = formValidatorRules;
  const [isSelectAllAgency, setIsSelectAllAgency] = useState(false);
  const [isSelectAllUser, setIsSelectAllUser] = useState(false);
  const [isUserRequired, setIsUserRequired] = useState(true);

  const handleCancel = () => {
    setShowModal(false);
    setEqcTypeData();
    form.resetFields();
  };
  useEffect(() => {
    if (eqcTypeData) {
      const {
        name,
        uom,
        iso,
        projectAgencyEqcTypes,
        projectUserEqcTypes
      } = eqcTypeData;
      form.setFieldsValue({
        name,
        uom,
        iso,
        projectAgencyIds: map(
          projectAgencyEqcTypes,
          (agency) => agency?.projectAgency?.id
        ),
        projectUserIds: map(
          projectUserEqcTypes,
          (user) => user?.projectUser?.id
        )
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcTypeData]);

  const [publishProjectEqcType, { loading }] = useMutation(PUBLISH_EQC_TYPE, {
    onCompleted() {
      Event(GA_EVENT.PUBLISH_PROJECT_EQC_TYPE, {
        label: GA_LABEL.PUBLISH_PROJECT_EQC_TYPE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_eqc_type_id: eqcTypeData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setEqcTypeData();
      form.resetFields();
      setShowModal(false);
      refetchEqcTypeData();
    },
    onError() {}
  });

  const onFormSubmit = async (formValues) => {
    try {
      const data = {
        ...formValues,
        projectId: Number(projectId),
        status: PROJECT_EQC_TYPE_STATUS?.PUBLISHED,
        selectedAllProjectUsers: isSelectAllUser,
        selectedAllProjectAgencies: isSelectAllAgency
      };
      if (isSelectAllAgency) {
        data.projectAgencyIds = [];
      }
      if (isSelectAllUser) {
        data.projectUserIds = [];
      }
      await publishProjectEqcType({
        variables: {
          id: eqcTypeData?.id,
          data
        }
      });
    } catch (error) {
      return error;
    }
  };
  const handleAgencyDeselect = () => {
    setIsSelectAllAgency(false);
    form.setFieldsValue({ projectAgencyIds: [] });
  };
  const handleUserDeselect = () => {
    setIsSelectAllUser(false);
    form.setFieldsValue({ projectUserIds: [] });
  };
  return (
    <>
      <Modal
        maskClosable={false}
        centered
        form={form}
        visible={showModal}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        destroyOnClose
      >
        <h2 className="mb-15">Publish EQC Type</h2>
        <Form layout="vertical" form={form} onFinish={onFormSubmit}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              required,
              {
                max: 250,
                message: 'Name cannot be more than 250 characters'
              }
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item name="uom" label="UOM" rules={[required]}>
            <CommonSelect>
              {map(keys(UOMS), (item) => (
                <Option key={item} value={item}>
                  {UOMS[item]}
                </Option>
              ))}
            </CommonSelect>
          </Form.Item>
          <Form.Item name="iso" label="Reference Number">
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name="projectUserIds"
            className={isUserRequired ? '' : 'mb-5'}
            label={
              <div className="d-flex justify-between width-percent-100">
                <div>User</div>
                {isSelectAllUser && (
                  <div
                    className="text-primary pointer"
                    role="button"
                    tabIndex="0"
                    onClick={handleUserDeselect}
                  >
                    Deselect All
                  </div>
                )}
              </div>
            }
            rules={isUserRequired && [required]}
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectAllUser(true);
                return ['all'];
              }
              return value;
            }}
          >
            <CommonDropdown
              className="mr-3"
              placeholder="Select users"
              allowClear
              disabled={isSelectAllUser}
              mode="multiple"
              query={GET_PROJECT_USERS_FOR_EQC_TYPES}
              responsePath="projectEqcTypeUserDropdownList.data"
              valuePath="id"
              labelPath="user.name"
              optionKey="user"
              variables={{ filter: { projectId } }}
              fetchPolicy="network-only"
              hasSelectAll
              showSearch
              optionFilterProp="children"
              callback={(response) => {
                setIsUserRequired(
                  !get(
                    response,
                    'projectEqcTypeUserDropdownList.projectAdminContains'
                  )
                );
              }}
            />
          </Form.Item>
          {!isUserRequired && (
            <div className="text-warning mb-15">
              {WARNINGS.PROJECT_ADMIN_AUTO_ASSIGNED}
            </div>
          )}
          <Form.Item
            label={
              <div className="d-flex justify-between width-percent-100">
                <div>Agency</div>
                {isSelectAllAgency && (
                  <div
                    className="text-primary pointer"
                    role="button"
                    tabIndex="0"
                    onClick={handleAgencyDeselect}
                  >
                    Deselect All
                  </div>
                )}
              </div>
            }
            name="projectAgencyIds"
            rules={[required]}
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectAllAgency(true);
                return ['all'];
              }
              return value;
            }}
          >
            <CommonDropdown
              className="mr-3"
              placeholder="Select agencies"
              allowClear
              disabled={isSelectAllAgency}
              mode="multiple"
              query={GET_PROJECT_AGENCY_FOR_EQC_TYPES}
              responsePath="projectEqcTypeAgencyDropdownList.data"
              valuePath="id"
              labelPath="agency.name"
              optionKey="agency"
              variables={{ filter: { projectId } }}
              fetchPolicy="network-only"
              hasSelectAll
              showSearch
              optionFilterProp="children"
            />
          </Form.Item>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
              loading={loading}
            >
              Publish
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default PublishEqcTypeModal;
