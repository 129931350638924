import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dropdown, Menu, Switch, Tag, Tooltip } from 'antd';
import { includes, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import {
  AddButton,
  EditButton,
  Export,
  Import,
  KebabMenu
} from '../../../assets/svg';
import {
  BREAKPOINTS,
  CSV_SAMPLE_FILES,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  USER_ROLES
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { titleCase } from '../../../common/utils';
import CommonCard from '../../../components/CommonCard';
import CommonImportModal from '../../../components/CommonImportModal';
import CommonTable from '../../../components/CommonTable';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import AddUserModal from '../AddUserModal';
import DeleteModal from '../DeleteModal';
import { IMPORT_USER_CSV, UPDATE_STATUS } from '../graphql/Mutations';
import { GET_USERS } from '../graphql/Queries';

const UserList = () => {
  const [showImportModal, setShowImportModal] = useState(false);
  const { getToken, getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const token = getToken();
  const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/export-csv/users?access_token=Bearer ${token}`;
  const sampleFileUrl = CSV_SAMPLE_FILES.USER_CSV;
  const list = ['Name', 'Email', 'Phone'];
  const initialUserFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showStatusModal, setStatusModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState();
  const [userListData, setUserListData] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [mutationId, setMutationId] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [title, setTitle] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [userFilter, setUserFilter] = useState(initialUserFilter);

  const [fetchUserData, { loading }] = useLazyQuery(GET_USERS, {
    variables: { filter: userFilter },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.userList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.userList?.total
      };
      if (scrollFlag) {
        const datalist = [...userListData, ...data];
        setUserListData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setUserListData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [updateStatus, { loading: mutationLoading }] = useMutation(
    UPDATE_STATUS,
    {
      onCompleted: () => {
        Event(GA_EVENT.CHANGE_GLOBAL_USER_STATUS, {
          label: GA_LABEL.CHANGE_GLOBAL_USER_STATUS,
          // eslint-disable-next-line no-undef,
          pathname: window?.location?.href,
          updated_user_id: mutationId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        fetchUserData({ variables: { filter: userFilter } });
        setStatusModal(false);
      },
      onError() {}
    }
  );

  const [
    updateUserImport,
    { loading: importLoading }
  ] = useMutation(IMPORT_USER_CSV, { onError() {} });

  useEffect(() => {
    fetchUserData({ variables: { filter: userFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    setSortedInfo({});
    setUserFilter(initialUserFilter);
    setPaginationProp(initialPaginationValue);
    fetchUserData({ variables: { filter: { ...initialUserFilter } } });
  };

  const handleAddEditUser = () => {
    setShowModal(true);
  };

  const handleImport = () => {
    setShowImportModal(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setUserFilter({
        ...userFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' }
          }
        }
      });
    }
  };

  const ActiveStatus = ({ record }) => {
    if (record?.isActive === true) {
      return <Tag className="active-tag">Active</Tag>;
    }
    return <Tag className="inactive-tag">Inactive</Tag>;
  };

  const handleActiveStatus = () => {
    setStatusModal(true);
  };

  const handleChange = (record, value) => {
    setMutationId(record?.id);
    setIsDisabled(value);
    handleActiveStatus();
    setTitle(value ? 'Activate' : 'Deactivate');
  };

  const getActionButtons = (record) => {
    const handleEdit = (e) => {
      e?.stopPropagation?.();
      setUserData(record);
      handleAddEditUser();
    };
    return isDesktopViewport ? (
      <div className="d-flex align-center">
        <Tooltip title="Edit">
          <Button
            shape="round"
            icon={<EditButton />}
            className="edit-button pointer"
            onClick={handleEdit}
            disabled={includes(record?.roles, USER_ROLES.SYSTEM_ADMIN)}
          />
        </Tooltip>
        <Tooltip title={record?.isActive ? 'Deactivate' : 'Activate'}>
          <Switch
            checked={record?.isActive}
            onChange={(e) => handleChange(record, e?.target?.value)}
            disabled={includes(record?.roles, USER_ROLES.SYSTEM_ADMIN)}
          />
        </Tooltip>
      </div>
    ) : (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit" onClick={handleEdit}>
              Edit
            </Menu.Item>
            <Menu.Divider />
            {record?.isActive === true ? (
              <li
                className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child text-danger"
                onClick={() => handleChange(record, true)}
                key="deactivate"
              >
                Deactivate
              </li>
            ) : (
              <li
                className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child text-success"
                onClick={() => handleChange(record, false)}
                key="activate"
              >
                Activate
              </li>
            )}
          </Menu>
        }
        trigger={['click']}
      >
        <KebabMenu />
      </Dropdown>
    );
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{userFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order
    },
    {
      title: 'ACTIVE',
      key: 'isActive',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      render: (activeStatus, record) => {
        return <ActiveStatus record={record} />;
      }
    },
    {
      title: 'DEFAULT ROLE',
      dataIndex: 'roles',
      key: 'roles',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'roles' && sortedInfo?.order,
      render: (role) => titleCase(role) || '-'
    },
    {
      title: 'MOBILE NUMBER',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order
    },
    {
      title: 'ACTIONS',
      render: (action, record) => getActionButtons(record)
    }
  ];

  const onSearchChange = async (value) => {
    setUserFilter({ ...userFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchUserData({
      variables: { filter: { ...userFilter, skip: 0, search: value } }
    });
  };
  const handleRefetch = () => {
    fetchUserData({
      variables: {
        filter: {
          ...userFilter,
          skip: userListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  return (
    <>
      {showModal && (
        <AddUserModal
          showModal={showModal}
          setShowModal={setShowModal}
          setUserData={setUserData}
          userData={userData}
          isUpdate={!!userData}
          refetchUserDataWithInitialValues={refetchUserDetails}
        />
      )}
      {showStatusModal && (
        <DeleteModal
          showModal={showStatusModal}
          setShowModal={setStatusModal}
          title={title}
          updateUserStatus={updateStatus}
          mutationId={mutationId}
          setMutationId={setMutationId}
          isDisabled={isDisabled}
          loading={mutationLoading}
          name="User"
          subtitle="User"
        />
      )}
      {showImportModal && (
        <CommonImportModal
          showImportModal={showImportModal}
          setShowImportModal={setShowImportModal}
          title="Users"
          sampleFileUrl={sampleFileUrl}
          list={list}
          updateUserImport={updateUserImport}
          importLoading={importLoading}
          refetchData={fetchUserData}
          filePrefix="userCsv"
        />
      )}
      <Portal portalId="search-component">
        <SearchComponent
          id="search-container-id"
          name="Users"
          getData={onSearchChange}
        />
      </Portal>
      <Portal portalId="user-import-export-buttons">
        <Portal portalId="add-button">
          <Button
            shape="round"
            id="add-btn"
            icon={!isDesktopViewport && <AddButton />}
            onClick={handleAddEditUser}
            className="mr-5"
          >
            {isDesktopViewport && 'Add'}
          </Button>
        </Portal>
        <Button shape="round" icon={<Import />} onClick={handleImport}>
          {isDesktopViewport && 'Import'}
        </Button>
        <Button
          shape="round"
          type="link"
          target="_blank"
          icon={<Export />}
          href={url}
          className="export-button"
          onClick={() =>
            Event(GA_EVENT.EXPORT_GLOBAL_USER, {
              label: GA_LABEL.EXPORT_GLOBAL_USER,
              // eslint-disable-next-line no-undef
              pathname: window?.location?.href,
              user_id: currentUser?.id,
              user_name: currentUser?.name,
              tenant_id: currentUser?.tenantUser?.tenant?.id,
              tenant_name: currentUser?.tenantUser?.tenant?.organizationName
            })
          }
        >
          {isDesktopViewport && 'Export'}
        </Button>
      </Portal>
      {isDesktopViewport ? (
        <CommonTable
          loadingData={loading}
          columns={columns}
          data={userListData || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(obj) => obj?.id}
        />
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          dataLength={userListData?.length}
          skeletonRows={columns?.length - 2}
        >
          {map(userListData, (user, index) => {
            return (
              <CommonCard key={user?.id}>
                <div className="common-card d-flex">
                  <div className="mr-5 fw-medium">{index + 1}.</div>
                  <div>
                    <div className="card-header fw-medium">
                      <span>{user?.name}</span>
                    </div>
                    <div className="card-content text-secondary">
                      <br />
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Email:</span>
                        {user?.email}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Status:</span>
                        <ActiveStatus record={user} />
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Default Role:</span>
                        {titleCase(user?.roles) || '-'}
                      </div>
                      <div className="d-flex align-center">
                        <span className="fw-medium mr-5">Mobile Number:</span>
                        {user?.phoneNo}
                      </div>
                    </div>
                  </div>
                  <span className="d-flex position-absolute user-action-btn">
                    {getActionButtons(user)}
                  </span>
                </div>
              </CommonCard>
            );
          })}
        </InfiniteScrollHandler>
      )}
    </>
  );
};

export default UserList;
