import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_REPORT_IMAGES = gql`
  query reportImages($urlString: String) {
    reportImages(urlString: $urlString) {
      reportName
      imageType
      images
    }
  }
`;
